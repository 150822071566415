import { DownloadIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Input,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import moment from 'moment'
import { DatePicker } from 'antd'
import React, { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'

import Signature from '../../../Components/Signature'

export const medicalRecordReview = [
  '1. Record includes appropriate lab work',
  '2. Record includes consent form',
  '3. Record includes post-op instruction',
  '4. Notation: specimen removed and sent to pathology',
  '5. If Specimen removed, notation agrees with lab book',
  '6. Patient discharged home',
  '7. Patient transferred to hospital',
]

export const clinical = [
  '1. Pre-op phone call completed or at least attempted twice and documented',
  '2. Allergies and/or abnormal drug reactions documented',
  '3. Pre-op nursing assessment w/medications complete and signed',
  '4. Pre-op diagnostic studies available and addressed',
  '5. Physician alerted to abnormal lab values',
  '6. H&P available, signed and dated',
  '7. Operative Report complete and signed',
  '8. Patient discharged by physician visit, order or criteria',
  '9. PACU record complete and signed',
  '10. Post-op instruction sheet signed by responsible party',
  '11. Post-op call documented, if unable to reach, document attempts and follow-up letter',
  '12. Patient response to all treatment documented',
]

export const allAreas = [
  '1. All entries complete with signatures and dates',
  '2. Verbal orders are authenticated',
  '3. All entries are legible',
]

function FormNurse({ onSubmit, data, loading, facility }) {
  const params = useParams()
  const { handleSubmit, register, errors, control, setValue, watch, trigger } =
    useForm()

  const initData = useCallback(() => {
    setValue('form', data)
    setValue('form.dos', moment(data?.dos))
    setValue('form.period', moment(data?.period))
  }, [data, setValue])

  useEffect(() => {
    if (data) initData()
  }, [data, initData])

  return (
    <>
      <Box>
        <Stack alignItems="center" spacing="1rem">
          <Image
            src={facility === 'PCSO' ? '/paincare-web.png' : '/LogoOSSC.jpeg'}
            width="325px"
          />
          <Text align="center" fontSize="xs">
            {facility === 'PCSO' ? (
              <>
                2480 Liberty Street NE, Suite 180, Salem, OR 97301 <br />
                Phone: (503) 371-1010 Fax: (503) 371-0805
              </>
            ) : (
              <>
                Oregon Specialists Surgery Center 2785 River Road S, Salem, OR
                97302
              </>
            )}
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            NURSE PEER REVIEW WORKSHEET
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            CHECK EACH BOX AS: Adequate + Inadequate -- Not Applicable n.a.
          </Text>
          <Text> PEER REVIEW: 15 Randomly selected charts each quarter.</Text>
        </Stack>
      </Box>
      <Box mt="4rem">
        <Flex w="100%" justifyContent="end">
          <Button
            leftIcon={<DownloadIcon />}
            colorScheme="blue"
            as={Link}
            to={`/peer-review/pdf/${params?.id}`}
          >
            EXPORT PDF
          </Button>
        </Flex>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} id="time-away-request-form">
        <Box>
          <Center>
            <FormControl
              id="period"
              isRequired
              isInvalid={errors?.monthYear}
              w={'20%'}
              mb={8}
            >
              <FormLabel>Review Period</FormLabel>
              <Controller
                render={props => (
                  <DatePicker
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    value={props?.value}
                    picker="quarter"
                    onChange={props.onChange}
                    format={'Y-[Q]Q'}
                  />
                )}
                name="form.period"
                control={control}
                rules={{ required: 'Period required.' }}
                defaultValue={moment()}
              />
              <FormErrorMessage>
                {errors?.date && errors?.date?.message}
              </FormErrorMessage>
            </FormControl>
          </Center>
          <HStack>
            <FormControl id="surgeon" isRequired isInvalid={errors?.surgeon}>
              <FormLabel>Surgeon</FormLabel>
              <Input
                name="form.surgeon"
                placeholder="Surgeon"
                ref={register({
                  required: 'Surgeon required.',
                })}
                defaultValue={data?.surgeon ?? ''}
              />
            </FormControl>
            <FormControl
              id="anesthesia"
              isRequired
              isInvalid={errors?.anesthesia}
            >
              <FormLabel>Anesthesia Provider</FormLabel>
              <Input
                name="form.anesthesia"
                placeholder="Anesthesia Provider"
                ref={register({
                  required: 'Anesthesia Provider required.',
                })}
                defaultValue={data?.anesthesia ?? ''}
              />
            </FormControl>

            <FormControl id="mr" isRequired isInvalid={errors?.mr}>
              <FormLabel>MR#</FormLabel>
              <Input
                name="form.mr"
                placeholder="MR#"
                ref={register({
                  required: 'MR# required.',
                })}
                defaultValue={data?.mr ?? ''}
              />
            </FormControl>
            <FormControl isRequired isInvalid={errors?.dos}>
              <FormLabel>DOS</FormLabel>
              <Controller
                render={props => (
                  <DatePicker
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    value={props?.value}
                    onChange={props?.onChange}
                  />
                )}
                name="form.dos"
                control={control}
                defaultValue={moment()}
                rules={{ required: 'DOS is required.' }}
              />
            </FormControl>
          </HStack>
        </Box>

        <Text fontSize="xl" fontWeight="bold" my={6}>
          MEDICAL RECORD REVIEW
        </Text>
        <Box>
          {medicalRecordReview.map((item, index) => (
            <Flex key={index} justifyContent="space-between" my={4}>
              <Text fontSize={18}>{item}</Text>
              <Flex gap={8}>
                <FormControl
                  isInvalid={errors?.answers && errors?.answers[index]}
                >
                  <Controller
                    render={props => (
                      <RadioGroup onChange={props.onChange} value={props.value}>
                        <Stack direction="row">
                          <Radio value="yes">Yes</Radio>
                          <Radio value="no">No</Radio>
                          <Radio value="n/a">N/A</Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                    name={`form.medicalAnswers[${index}]`}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={''}
                  />
                </FormControl>
              </Flex>
            </Flex>
          ))}
        </Box>

        <Text fontSize="xl" fontWeight="bold" my={6}>
          CLINICAL
        </Text>
        <Box>
          {clinical.map((item, index) => (
            <Flex key={index} justifyContent="space-between" my={4}>
              <Text fontSize={18}>{item}</Text>
              <Flex gap={8}>
                <FormControl
                  isInvalid={errors?.answers && errors?.answers[index]}
                >
                  <Controller
                    render={props => (
                      <RadioGroup onChange={props.onChange} value={props.value}>
                        <Stack direction="row">
                          <Radio value="yes">Yes</Radio>
                          <Radio value="no">No</Radio>
                          <Radio value="n/a">N/A</Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                    name={`form.clinicalAnswers[${index}]`}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={''}
                  />
                </FormControl>
              </Flex>
            </Flex>
          ))}
        </Box>

        <Text fontSize="xl" fontWeight="bold" my={6}>
          ALL AREAS
        </Text>
        <Box>
          {allAreas.map((item, index) => (
            <Flex key={index} justifyContent="space-between" my={4}>
              <Text fontSize={18}>{item}</Text>
              <Flex gap={8}>
                <FormControl
                  isInvalid={errors?.answers && errors?.answers[index]}
                >
                  <Controller
                    render={props => (
                      <RadioGroup onChange={props.onChange} value={props.value}>
                        <Stack direction="row">
                          <Radio value="yes">Yes</Radio>
                          <Radio value="no">No</Radio>
                          <Radio value="n/a">N/A</Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                    name={`form.allAreasAnswers[${index}]`}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={''}
                  />
                </FormControl>
              </Flex>
            </Flex>
          ))}
        </Box>
        <Box mt={12}>
          <FormControl>
            <Text mb="8px">Comment: </Text>
            <Controller
              render={props => (
                <Textarea
                  value={props.value}
                  placeholder="Comment"
                  onChange={props.onChange}
                />
              )}
              name="form.comment"
              control={control}
              defaultValue=""
            />
          </FormControl>
        </Box>

        <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
          <Box>
            <Controller
              name="form.signatureCollector"
              control={control}
              defaultValue={data?.signatureCollector || ''}
              render={({ field }) => {
                return (
                  <FormControl
                    w="30vw"
                    isInvalid={errors['signatureCollector']}
                  >
                    <Signature
                      label="Chart Reviewer Signature"
                      field={field}
                      setValue={setValue}
                      watch={watch}
                      data={data}
                      trigger={trigger}
                    />
                    <FormErrorMessage>
                      {errors['signatureCollector'] &&
                        errors['signatureCollector'].message}
                    </FormErrorMessage>
                  </FormControl>
                )
              }}
            />
          </Box>
          <Box display={'flex'} alignItems={'end'}>
            <FormControl isRequired>
              <FormLabel>Date</FormLabel>
              <Controller
                render={props => (
                  <Input
                    value={props.value}
                    onChange={props.onChange}
                    isReadOnly
                    textAlign={'center'}
                  />
                )}
                name="form.date"
                control={control}
                defaultValue={moment().format('YYYY-MM-DD')}
                rules={{ required: 'Date is required.' }}
              />
            </FormControl>
          </Box>
        </SimpleGrid>

        <Center mt="3rem" pb="2rem">
          <Button type="submit" isLoading={loading}>
            Submit
          </Button>
        </Center>
      </form>
    </>
  )
}

export default FormNurse
