import {
  Center,
  Text,
  Box,
  Tr,
  Td,
  Tbody,
  Table,
  FormControl,
  RadioGroup,
  Radio,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CardContent } from '../../../../../../Components/Card/card'
const _ = require('underscore')

function RecruitPatientsThirdForm({ formView }) {
  const {
    control,
    setValue,
    // formState: { errors },
  } = useForm()
  useEffect(() => {
    if (formView) {
      const data = formView
      _.keys(data).map(e => {
        return setValue(e, data[e])
      })
    }
  }, [formView, setValue])

  const questionName = [
    { title: 'Bowel incontinence:', name: 'bowelIncontinence' },
    { title: 'Bladder incontinence:', name: 'bladderIncontinence' },
    { title: 'Nausea:', name: 'nausea' },
    { title: 'Vomiting:', name: 'vomiting' },
    { title: 'Fevers:', name: 'fevers' },
    { title: 'Chills:', name: 'chills' },
    { title: 'Sinus congestion:', name: 'sinusCongestion' },
    { title: 'Chest pain:', name: 'chestPain' },
    { title: 'Weight loss:', name: 'weightLoss' },
    { title: 'Rash:', name: 'rash' },
    { title: 'Diabetes:', name: 'diabetes' },
    { title: 'Hearing loss:', name: 'hearingLoss' },
    { title: 'Chance of being pregnant:', name: 'chancePregnant:' },
    { title: 'Trouble swallowing:', name: 'troubleSwallowing' },
    { title: 'Abnormal bruising or bleeding', name: 'abnormalBruising' },
    { title: 'Hepatitis C positive:', name: 'hepatitis' },
    { title: 'HIV positive:', name: 'hiv' },
    { title: 'Change in sexual function:', name: 'changeSexual' },
    { title: 'Muscle pain and spasm:', name: 'painSpasm' },
    { title: 'Weakness:', name: 'weakness' },
    { title: 'Headache:', name: 'headache' },
    { title: 'Tingling/numbness:', name: 'tingling' },
    { title: 'Constipation:', name: 'constipation' },
    { title: 'Change in vision:', name: 'changeVision:' },
    { title: 'Shortness of breath:', name: 'shortnessBreath:' },
    { title: 'Depression:', name: 'depression' },
    { title: 'Suicide attempts:', name: 'suicideAttempts' },
    { title: 'Suicidal thoughts:', name: 'suicidalThoughts' },
    { title: 'Attention deficit disorder:', name: 'attentionDeficit' },
    { title: 'Obsessive compulsive disorder:', name: 'obsessiveCompulsive' },
    { title: 'Bipolar:', name: 'bipolar' },
    { title: 'Schizophrenia:', name: 'schizophrenia' },
    { title: 'Mental health changes:', name: 'mentalHealthChanges' },
    { title: 'Undergoing counseling:', name: 'undergoingCounseling:' },
    { title: 'High stress level:', name: 'highStress' },
    {
      title: 'Psychiatric hospitalization:',
      name: 'psychiatricHospitalization',
    },
    { title: 'Homicidal ideation:', name: 'homicidalIdeation' },
  ]

  return (
    <CardContent>
      <Box>
        <Text pb="20px" fontWeight="bold">
          New Patient Intake Form, Page 3 of 3
        </Text>
        <Text pb="10px" fontSize="14px" fontWeight="bold" textAlign="center">
          Please check Yes or No for each question
        </Text>
        <Box overflow="auto" h="100%">
          <Table variant="unstyled">
            <Tbody p="0px">
              {questionName.map((e, i) => (
                <Tr key={i}>
                  <Td p="10px">
                    <Text w="max-content" textAlign="start">
                      {e.title}
                    </Text>
                  </Td>
                  <Controller
                    name={e.name}
                    control={control}
                    render={props => (
                      <FormControl>
                        <CheckBoxNewPatient
                          onChange={props.onChange}
                          onBlur={props.onBlur}
                          defaultValue={formView?.[e.name]}
                        />
                      </FormControl>
                    )}
                  />
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Center pt="2rem">{`9/16`}</Center>
    </CardContent>
  )
}
export { RecruitPatientsThirdForm }

function CheckBoxNewPatient({ onChange, defaultValue }) {
  return (
    <RadioGroup onChange={onChange} defaultValue={defaultValue}>
      <Td px="5px">
        <Radio isDisabled value="yes">
          Yes
        </Radio>
      </Td>
      <Td px="5px">
        <Radio isDisabled value="no">
          No
        </Radio>
      </Td>
    </RadioGroup>
  )
}
