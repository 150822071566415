import { ChevronRightIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Fade,
  Flex,
  VStack,
} from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import React, { useLayoutEffect, useState } from 'react'
import { PatientWelcomePacket } from './Components'
import { PatientDemographics } from './Components'
import { NewPatientAdmission } from './Components'
import { SecondAcceptingPatients } from './Components'
import { ThirAcceptingPatients } from './Components'
import { Soap } from './Components'
import { NoticePrivacyPractices } from './Components'
import { SecondNoticePrivacyPractices } from './Components'
import { SeriousThreatsHealthSafety } from './Components'
import { System } from './Components'
import { PainCareSpecialists } from './Components'
import { AssignmentBenefits } from './Components'
import { SecondAssignmentBenefits } from './Components'
import { Webnoticethirtee } from './Components'
import { SignatureCancellation } from './Components'
import { SecondSignatureCancellation } from './Components'
import { MedicalRecordsReleaseForm } from './Components'
// import { SignaturePermission } from './Components'
import { FaCloudDownloadAlt } from 'react-icons/fa'

export const totalNonWebEnabledPage = 17

function FormNonWebEnableForm({ formView }) {
  const [scrollY, setScrollY] = useState(0)
  const params = useParams()
  useLayoutEffect(() => {
    function updatePosition() {
      setScrollY(window.pageYOffset)
    }
    window.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  return (
    <>
      <Container maxW="100%" mt="5">
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
          bg="gray.100"
          p="1.5"
          borderRadius="0.3em"
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/dashboard">
              Dashbaord
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/fillable">
              Fillable
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink>
              {formView.namePatient || formView.namepatientForm3}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex w="100%" justifyContent="flex-end" pt="1rem">
          <Button
            onClick={() => {
              window.open(`/fillable/view/${params?.id}/pdf/NonWebEnableForm`)
            }}
            leftIcon={<FaCloudDownloadAlt />}
            size="sm"
          >
            PDF
          </Button>
        </Flex>
        <VStack
          py="4rem"
          spacing={{ base: '2rem', sm: '3rem', md: '4rem' }}
          fontFamily="Inter"
        >
          <PatientWelcomePacket formView={formView} />
          <PatientDemographics formView={formView} />
          <NewPatientAdmission formView={formView} />
          <SecondAcceptingPatients formView={formView} />
          <ThirAcceptingPatients formView={formView} />
          <Soap formView={formView} />
          <NoticePrivacyPractices formView={formView} />
          <SecondNoticePrivacyPractices formView={formView} />
          <SeriousThreatsHealthSafety formView={formView} />
          <System formView={formView} />
          <PainCareSpecialists formView={formView} />
          <AssignmentBenefits formView={formView} />
          <SecondAssignmentBenefits formView={formView} />
          <Webnoticethirtee formView={formView} />
          <SignatureCancellation formView={formView} />
          <SecondSignatureCancellation formView={formView} />
          <MedicalRecordsReleaseForm formView={formView} />
          {/* <SignaturePermission formView={formView} /> */}
        </VStack>
        {scrollY > 1000 && (
          <Box
            as={Fade}
            in={scrollY > 1100 ? true : false}
            position="fixed"
            bottom="10px"
            right="10px"
          >
            <Button
              w={{ base: '3rem', sm: '3rem', md: '3.5rem' }}
              colorScheme="gray"
              color="#5fc2ff"
              _focus={{ border: '0px solid transparent' }}
              fontSize={{ base: '24px', sm: '24px', md: '30px' }}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              <ChevronUpIcon />
            </Button>
          </Box>
        )}
      </Container>
    </>
  )
}

export { FormNonWebEnableForm }
