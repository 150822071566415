import { ChevronRightIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Fade,
  Flex,
  VStack,
} from '@chakra-ui/react'
import React, { useLayoutEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Amendment,
  AssignmentBenefits,
  CancellationNOShowPolicy,
  LawEnforcement,
  NoticePrivacyPractices,
  PainCareSpecialist,
  PatientDemographics,
  RecruitPatientsSecondForm,
  RecruitPatientsThirdForm,
  RecruitingPatientsFirstForm,
  SignatureCancellation,
  Soap,
  TreatmentOptions,
} from './Components'

import {
  SignatureAuthorization,
  SignatureCancellationSecond,
  SignaturePermission,
} from './Components'
import { FaCloudDownloadAlt } from 'react-icons/fa'

function FormWebEnableForm({ formView }) {
  const [scrollY, setScrollY] = useState(0)
  const params = useParams()
  useLayoutEffect(() => {
    function updatePosition() {
      setScrollY(window.pageYOffset)
    }
    window.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => window.removeEventListener('scroll', updatePosition)
  }, [])
  return (
    <>
      <Container maxW="100%" mt="5">
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
          bg="gray.100"
          p="1.5"
          borderRadius="0.3em"
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/dashboard">
              Dashbaord
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/fillable">
              Fillable
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink>
              {formView?.namePatient || formView?.namepatientForm3}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex w="100%" justifyContent="flex-end" pt="1rem">
          <Button
            onClick={() => {
              window.open(`/fillable/view/${params?.id}/pdf/WebEnableForm`)
            }}
            leftIcon={<FaCloudDownloadAlt />}
            size="sm"
          >
            PDF
          </Button>
        </Flex>
        <VStack
          py="4rem"
          spacing={{ base: '2rem', sm: '3rem', md: '4rem' }}
          fontFamily="Inter"
        >
          <NoticePrivacyPractices formView={formView} />
          <TreatmentOptions formView={formView} />
          <LawEnforcement formView={formView} />
          <Amendment formView={formView} />
          <PainCareSpecialist formView={formView} />
          <PatientDemographics formView={formView} />
          <RecruitingPatientsFirstForm formView={formView} />
          <RecruitPatientsSecondForm formView={formView} />
          <RecruitPatientsThirdForm formView={formView} />
          <Soap formView={formView} />
          <AssignmentBenefits formView={formView} />
          <CancellationNOShowPolicy formView={formView} />
          <SignatureCancellation formView={formView} />
          <SignatureCancellationSecond formView={formView} />
          <SignatureAuthorization formView={formView} />
          <SignaturePermission formView={formView} />
        </VStack>
        {scrollY > 1000 && (
          <Box
            as={Fade}
            in={scrollY > 1100 ? true : false}
            position="fixed"
            bottom="10px"
            right="10px"
          >
            <Button
              w={{ base: '3rem', sm: '3rem', md: '3.5rem' }}
              colorScheme="gray"
              color="#5fc2ff"
              _focus={{ border: '0px solid transparent' }}
              fontSize={{ base: '24px', sm: '24px', md: '30px' }}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              <ChevronUpIcon />
            </Button>
          </Box>
        )}
      </Container>
    </>
  )
}

export { FormWebEnableForm }
