/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronRightIcon, SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Center,
  Container,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spacer,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { AiOutlineFileText } from 'react-icons/ai'
import { BsViewStacked } from 'react-icons/bs'
import { Link, useParams } from 'react-router-dom'
import { search } from 'ss-search'
import RenderFormData from '../../Components/RenderFormData'
import { db } from '../../config/firebase'
import renderDate from '../../config/renderDate'
import { FaCloudDownloadAlt } from 'react-icons/fa'

function EmployeeForm() {
  const toast = useToast()
  const [employeeForm, setEmployeeForm] = useState([])
  const [filterEmployeeForm, setFilterEmployeeForm] = useState([])
  const [selectedForm, setSelectedForm] = useState(null)
  const [status, setStatus] = useState('Pending')
  const [typeForm, setTypeForm] = useState('All')
  const [facility, setFacility] = useState('All')
  const [loading, setloading] = useState(true)
  // const [facilityFilter, setFacilityFilter] = useState('')
  // const [formFilter, setFormFilter] = useState('')
  const [keyWord, setKeyWord] = useState('')
  const params = useParams()

  useEffect(() => {
    let unsubscribeEmployeeForm
    if (params.id) {
      unsubscribeEmployeeForm = onSnapshot(
        doc(db, 'EmployeeForm', params.id),
        snapShot => {
          if (snapShot.exists) {
            let data = snapShot.data()
            setSelectedForm({ ...data, id: snapShot.id })
          }
        }
      )
    } else {
      setloading(true)
      let querys = []
      setKeyWord('')
      if (typeForm !== 'All') {
        querys.push(where('type', '==', typeForm))
      }
      if (status !== 'All') {
        querys.push(where('status', '==', status))
      }
      if (facility !== 'All') {
        querys.push(where('facility', '==', facility))
      }

      const queryEmployeeForm = query(
        collection(db, 'EmployeeForm'),
        ...querys,
        orderBy('createdAt', 'desc')
      )

      unsubscribeEmployeeForm = onSnapshot(queryEmployeeForm, snapShot => {
        let data = []

        snapShot.forEach(value => {
          let formData = value.data()
          data.push({ ...formData, id: value.id })
        })
        setEmployeeForm(data)
        setFilterEmployeeForm(data)
        setSelectedForm(null)
        setloading(false)
      })
    }

    return () => {
      console.log('unmount')
      unsubscribeEmployeeForm()
    }
  }, [params, status, typeForm, facility])

  function changeStatus(formId, status) {
    updateDoc(doc(db, 'EmployeeForm', formId), {
      status: status,
      notification: false,
    })
      .then(() => {})
      .catch(e => {
        toast({
          title: 'Change status error',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  function onSearch() {
    const searchField = ['facility', 'name', 'type']
    const key = keyWord

    const result = search(employeeForm, searchField, key)
    setFilterEmployeeForm(result)
  }

  useEffect(() => {
    if (keyWord !== '') {
      onSearch()
    }
  }, [keyWord])

  return (
    <Container maxW="100%" mt="5" mb="10">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/employee-form">
            Employee Form
          </BreadcrumbLink>
        </BreadcrumbItem>
        {params.id && selectedForm ? (
          <BreadcrumbItem>
            <BreadcrumbLink>
              {selectedForm.name} ({selectedForm.type})
            </BreadcrumbLink>
          </BreadcrumbItem>
        ) : (
          params.id && (
            <BreadcrumbItem>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xs"
              />
            </BreadcrumbItem>
          )
        )}
      </Breadcrumb>

      {params.id && selectedForm ? (
        <Box mt="5">
          <Box textAlign="right" mt="5">
            {selectedForm.type === 'CME Request' && selectedForm.attachFile && (
              <Button
                mr="3"
                size="sm"
                variant="outline"
                onClick={() => {
                  window.open(selectedForm.attachFile)
                }}
                leftIcon={<AiOutlineFileText />}
              >
                Download File
              </Button>
            )}

            <HStack spacing={'2rem'} justifyContent={'end'}>
              <Button
                display={
                  [
                    'Radiation Safety Test',
                    'Employee Quiz',
                    'Collectors Competency Quiz',
                    'TB High Risk',
                    'Declination of Influenza Vaccination'
                  ].includes(selectedForm?.type)
                    ? 'block'
                    : 'none'
                }
                onClick={() => {
                  window.open(
                    `/employee-form/${params.id}/pdf/${selectedForm?.type
                      ?.replace(/\s+/g, '-')
                      .toLowerCase()}`,
                    'blank'
                  )
                }}
                leftIcon={<FaCloudDownloadAlt />}
                size="sm"
              >
                PDF
              </Button>
              <ButtonGroup size="sm" isAttached>
                <Button
                  colorScheme="green"
                  variant={
                    selectedForm.status === 'Approved' ? 'solid' : 'outline'
                  }
                  mr="-px"
                  onClick={() => changeStatus(selectedForm.id, 'Approved')}
                >
                  Approved
                </Button>
                <Button
                  colorScheme="blue"
                  variant={
                    selectedForm.status === 'Pending' ? 'solid' : 'outline'
                  }
                  mr="-px"
                  onClick={() => changeStatus(selectedForm.id, 'Pending')}
                >
                  Pending
                </Button>
                <Button
                  colorScheme="red"
                  variant={
                    selectedForm.status === 'Rejected' ? 'solid' : 'outline'
                  }
                  mr="-px"
                  onClick={() => changeStatus(selectedForm.id, 'Rejected')}
                >
                  Rejected
                </Button>
              </ButtonGroup>
            </HStack>
          </Box>
          {RenderFormData({ data: selectedForm }, toast)}
        </Box>
      ) : (
        <>
          <Flex mt="2">
            <Box pt="2" fontSize="18" fontWeight="bold">
              Employee Form
            </Box>
            <Spacer />
          </Flex>
          <Flex
            flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
            mt="2"
            w="100%"
            justifyContent="space-between"
          >
            <InputGroup
              w={['100%', '100%', '225px', '225px', '225px', '225px']}
            >
              <InputLeftElement>
                <SearchIcon />
              </InputLeftElement>
              <Input
                w={['100%', '100%', '225px', '225px', '225px', '225px']}
                placeholder={'Employee name'}
                value={keyWord}
                onChange={e => setKeyWord(e.target.value)}
              />
            </InputGroup>
            <Flex
              flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
              w={['100%', '100%', '450px', '450px', '500px', '500px']}
            >
              <Select
                mt={[2, 2, 0, 0, 0, 0]}
                mx={[0, 0, 1, 1, 1, 1]}
                w={'100%'}
                name="typeForm"
                onChange={e => setTypeForm(e.target.value)}
                defaultValue={typeForm}
              >
                <option value={'All'}>All</option>
                <option value={'Time Away Request'}>Time Away Request</option>
                <option value={'Time Clock Adjustment'}>
                  Time Clock Adjustment
                </option>
                <option value={'Absentee Report'}>Absentee Report</option>
                <option value={'CME Request'}>CME Request</option>
                <option value={'PTO Request Form'}>PTO Request Form</option>
                <option value={'Request for OFLA Leave'}>
                  Request for OFLA Leave
                </option>
                <option value={'COVID-19 Questionnairs'}>
                  COVID-19 Questionnairs
                </option>
                <option value={'OHSA Infection Control Plan'}>
                  OHSA Infection Control Plan
                </option>
                <option value={'OHA Religious Exemption Form'}>
                  OHA Religious Exemption Form
                </option>

                <option value={'Radiation Safety Test'}>
                  Radiation Safety Test
                </option>
                <option value={'Employee Quiz'}>Employee Quiz</option>
                <option value={'Collectors Competency Quiz'}>
                  Collectors Competency Quiz
                </option>
                <option value={'Annual Clinical Competencies'}>
                  Annual Clinical Competencies
                </option>
                <option value={'Paid Time Off Request'}>
                  Paid Time Off Request
                </option>
                <option value={'Covid-19 Questionnairs'}>
                  Covid-19 Questionnairs
                </option>
                <option value="TB High Risk">TB High Risk</option>
                <option value={'Declination of Influenza Vaccination'}>
                  Declination of Influenza Vaccination
                </option>
              </Select>
              <Select
                mt={[2, 2, 0, 0, 0, 0]}
                mx={[0, 0, 1, 1, 1, 1]}
                w={'100%'}
                name="facility"
                onChange={e => setFacility(e.target.value)}
                defaultValue={facility}
              >
                <option value={'All'}>All</option>
                <option value={'PCSO'}>PCSO</option>
                <option value={'OSSC'}>OSSC</option>
              </Select>
              <Select
                mt={[2, 2, 0, 0, 0, 0]}
                w={'100%'}
                name="status"
                onChange={e => setStatus(e.target.value)}
                defaultValue={status}
              >
                <option value={'All'}>All</option>
                <option value={'Pending'}>Pending</option>
                <option value={'Approved'}>Approved</option>
                <option value={'Rejected'}>Rejected</option>
              </Select>
            </Flex>
          </Flex>
          {loading === true ? (
            <Center w="100%" h="20rem">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="lg"
              />
            </Center>
          ) : (
            <Table size="sm" mt="10" overflowX="auto">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Employee</Th>
                  <Th>
                    <Flex
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Text>Form</Text>
                    </Flex>
                  </Th>
                  <Th>Facility</Th>
                  <Th>Status</Th>
                  <Th textAlign="center">Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filterEmployeeForm.map((form, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{renderDate(form.createdAt)}</Td>
                      <Td>{form.name}</Td>
                      <Td>{form.type}</Td>
                      <Td>{form.facility ? form.facility : '-'}</Td>
                      <Td>{form.status}</Td>
                      <Td textAlign="center">
                        <Button
                          as={Link}
                          to={`/employee-form/${form.id}`}
                          size="xs"
                          leftIcon={<BsViewStacked />}
                        >
                          View
                        </Button>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          )}
        </>
      )}
    </Container>
  )
}

export default EmployeeForm
