import { Image, Text, View } from '@react-pdf/renderer'
import React from 'react'

function SecondAssignmentBenefits({ styles }) {
  return (
    <View style={{ gap: '8px', width: '100%' }}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 8,
        }}
      >
        <Image
          style={{ width: '110px', objectFit: 'cover' }}
          src="/paincare-web-v2.png"
        />
        <View style={styles.boxtitle}>
          <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
        </View>
        <View style={styles.boxtitle}>
          <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
        </View>
      </View>
      <View style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
        <Text style={{ fontWeight: 'bold' }}>
          DISCLOSURE OF PHYSICIAN OWNERSHIP
        </Text>
      </View>
      <View style={{ marginTop: '-2px' }}>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          The physicians at Pain Care Specialists of Oregon, LLC, may have
          ownership in one or more of the following entities: Pain Care
          Specialists of Oregon, Oregon Specialists Surgery Center, and Oregon
          Pain Management. A list of physician ownership is available upon
          request.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          You have a right to choose the provider of your health care services.
          Therefore, you have the option to use a health care facility other
          than the one to which you were referred.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          You will not be treated diﬀerently by your physician if you choose to
          use a diﬀerent facility. If desired, your physician can provide
          information about alternative providers.
        </Text>
      </View>
      <View style={{ width: '100%', textAlign: 'center', marginTop: '8px' }}>
        <Text style={{ fontWeight: 'bold' }}>PATIENT FINANCIAL CONSENT</Text>
      </View>
      <View style={{ marginTop: '-2px' }}>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          Copayment is due on the day of service, unless payment arrangements
          have been previously arranged. Pain Care Specialists of Oregon, LLC,
          will submit insurance claims for you. However, if the service is not
          covered under your policy, you are responsible for the balance due.
          This may include additional patient responsibility charges for
          procedures performed same day as office visit. It is the policy of
          Pain Care Specialists of Oregon, LLC, to work diligently to assist
          patients with financial arrangements. Extended payment plans can be
          arranged through our billing office. These plans are based upon
          financial circumstances of each patient. We invite you to discuss any
          financial difficulties by calling our office at (503) 371-1010. If
          insurance requires that a referral is necessary it is the
          responsibility of the patient to obtain one from their primary care
          physician before an appointment with a Pain Care Specialists of
          Oregon, LLC, physician. Pain Care Specialists of Oregon, LLC, reserves
          the right to refuse service to any patient who does not have a valid
          referral in our office at the time of their appointment. Many of the
          services that our office provides require pre-authorization, and we
          ask that you be patient with our office in obtaining this
          authorization. Many insurance companies require documentation prior to
          authorizing services, and we will do our best to comply in a timely
          fashion with their requests.
        </Text>
      </View>
      <View style={{ paddingTop: 6 }}>
        <Text style={{ fontWeight: 'bold' }}>
          Consent to Release Information:
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          I (the patient/guarantor) hereby give my consent to Pain Care
          Specialists of Oregon, LLC, to release any information regarding my
          care and treatment as may be required in connection with payment. I
          authorize the release of any medical documentation to insurance
          companies and medical providers as necessary.
        </Text>
      </View>
      <View style={{ paddingTop: 6 }}>
        <Text style={{ fontWeight: 'bold' }}>Assignment of Benefits:</Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          I (the patient/guarantor) hereby authorize payment to be rendered
          directly to Pain Care Specialists of Oregon, LLC, for the benefits
          otherwise payable to me by any third party. The above authorizations
          are in eﬀect permanently or until canceled by myself in writing.
        </Text>
      </View>
      <View style={{ paddingTop: 6 }}>
        <Text style={{ fontWeight: 'bold' }}>Medicare Signature on File:</Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          I (patient/guarantor) request that payment under the medical insurance
          program Medicare to be made to Pain Care Specialists of Oregon, LLC,
          on any bills for services furnished to me by the physicians of Pain
          Care Specialists of Oregon, LLC, permanently or until this
          authorization in canceled by me (the patient/guarantor) in writing. I
          also give Pain Care Specialists of Oregon, LLC, authorization to file
          claims to Medicare on my behalf.
        </Text>
      </View>
    </View>
  )
}

export default SecondAssignmentBenefits
