import React from 'react'
import {
  Text,
  Image,
  PDFViewer,
  StyleSheet,
  Document,
  Page,
  View,
  Svg,
  Circle,
  Link,
} from '@react-pdf/renderer'
import moment from 'moment'
import { Box, Button, ButtonGroup, Heading, HStack } from '@chakra-ui/react'
import { soapprQuestion } from '../form/formSOAPPR'
export default function RenderSOAPPRPDF({ formData, changeStatus }) {
  const styles = StyleSheet.create({
    pdfView: {
      width: '100%',
      height: window.innerHeight,
      backgroundColor: '#fff',
      fontFamily: 'Open Sans',
    },
    page: {
      width: '100%',
      flexDirection: 'column',
      backgroundColor: '#fff',
      alignItems: 'center',
      paddingLeft: 40,
      paddingRight: 40,
    },
    section: {
      width: '100%',
      marginBottom: 10,
      padding: 10,
      alignItems: 'center',
    },
    sectionImg: {
      width: '100%',
      marginTop: 25,
      padding: 0,
      alignItems: 'center',
    },
    img: {
      width: '150px',
      height: 'auto',
      objectFit: 'cover',
    },
    textTitle: {
      paddingHorizontal: 80,
      fontSize: '12px',
      textAlign: 'center',
    },
    textHelper: {
      fontSize: '13px',
      marginBottom: 24,
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableCellScoreHeader: {
      margin: 'auto',
      marginLeft: 2.5,
      paddingTop: 2.5,
      textAlign: 'center',
      paddingBottom: 2.5,
      fontSize: 10,
      width: '100%',
    },
    tableColHeader: {
      width: '8%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColDate: {
      width: '60%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      height: 80,
      paddingLeft: 12,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: '#eeeeee',
    },
    tableRowScore: {
      borderStyle: 'solid',
      width: '100%',
      borderTopWidth: 1,
      marginTop: 2.5,
      backgroundColor: '#eeeeee',
    },
    tableCellHeader: {
      marginTop: 5,
      fontSize: 14,
      backgroundColor: '#eeeeee',
    },
    tableCellHeaderText: {
      width: '100%',
      height: '60px',
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      backgroundColor: '#eeeeee',
    },
    textHeader: {
      position: 'absolute',
      fontSize: 11,
      transform: 'rotate(270eg)',
      left: -20,
      bottom: 35,
      width: 80,
    },
    tableColQuestion: {
      width: '60%',
      borderStyle: 'solid',
      fontSize: 12,
      borderBottomWidth: 1,
      borderRightWidth: 1,
      padding: '8px 42px 8px 8px',
      display: 'flex',
      flexDirection: 'row',
    },
    tableColCheck: {
      width: '8%',
      borderBottomWidth: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    tableColCheckLast: {
      width: '8%',
      borderBottomWidth: 1,
      borderRightWidth: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    svg: {
      width: '15px',
      height: '10px',
    },
    boxCheck: {
      position: 'absolute',
      top: 4.5,
      left: 16.5,
    },
    boxCheckSecond: {
      position: 'absolute',
      top: 11,
      left: 16.5,
    },
    imgcheck: {
      width: '12px',
      height: '12px',
    },
    textFooter: {
      marginTop: 10,
      fontSize: '13px',
      width: '100%',
    },
    textNo: {
      margin: '0px 8px',
    },
    boxFooterOfPage: {
      width: '100%',
      marginTop: 10,
      display: 'flex',
      flexDirection: 'row',
      paddingRight: 110,
    },
    textFooterOfPage: {
      fontSize: '10px',
    },
    textCopyRight: {
      fontSize: '10px',
      paddingRight: '10px',
      marginRight: '10px',
    },
    tableColScore: {
      width: '60%',
      borderStyle: 'solid',
      fontSize: 12,
      borderBottomWidth: 1,
      borderRightWidth: 1,
      padding: '0px 12px 0px 0px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    tableColTotalScore: {
      width: '40%',
      borderBottomWidth: 1,
      borderRightWidth: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      fontSize: 12,
    },
  })

  const checkStyle = index => {
    const oneLine = [0, 4, 7, 10, 20]
    if (oneLine.includes(index)) return styles.boxCheck
    return styles.boxCheckSecond
  }

  const checked = (key, value) => {
    if (`${key}` !== value) return <></>
    return <Image style={styles.imgcheck} src="/check.png" />
  }

  return (
    <Box mt="5">
      <Box textAlign="right" mt="5">
        <HStack justifyContent="space-between" mb={8}>
          <Box>
            <Heading as="h4" size="md" textAlign="start">
              Name : {formData?.name || ''}
            </Heading>
            <Heading as="h4" size="md" textAlign="start">
              Date of Birth :{' '}
              {moment(formData?.dateOfBirth).format('MM/DD/YYYY')}
            </Heading>
          </Box>
          <HStack spacing={'2rem'}>
            <ButtonGroup size="sm" isAttached>
              <Button
                colorScheme="green"
                variant={formData?.status === 'Archived' ? 'solid' : 'outline'}
                mr="-px"
                onClick={() => changeStatus(formData?.id, 'Archived')}
              >
                Archived
              </Button>
            </ButtonGroup>
          </HStack>
        </HStack>
      </Box>
      <PDFViewer style={styles.pdfView}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.sectionImg}>
              <Image style={styles.img} src="/paincare-web.png" />
            </View>
            <View style={styles.section}>
              <Text style={styles.textTitle}>SOAPP-R</Text>
            </View>
            <View>
              <Text style={styles.textHelper}>
                The following are some questions given to patients who are on or
                being considered for medication for thier pain. Please answer
                each question as honestly as possible. There are no right or
                wrong answer
              </Text>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColDate}>
                  <Text style={styles.tableCellHeader}>
                    Name : {formData.name}
                  </Text>
                  <Text style={{ ...styles.tableCellHeader, marginTop: 12 }}>
                    Date of Birth :{' '}
                    {moment(formData?.dateOfBirth).format('MM/DD/YYYY')}
                  </Text>
                </View>
                <View style={styles.tableColHeader}>
                  <View style={styles.tableCellHeaderText}>
                    <Text style={styles.textHeader}>Never</Text>
                  </View>
                  <View style={styles.tableRowScore}>
                    <Text style={styles.tableCellScoreHeader}>0</Text>
                  </View>
                </View>
                <View style={styles.tableColHeader}>
                  <View style={styles.tableCellHeaderText}>
                    <Text style={styles.textHeader}>Seldom</Text>
                  </View>
                  <View style={styles.tableRowScore}>
                    <Text style={styles.tableCellScoreHeader}>1</Text>
                  </View>
                </View>
                <View style={styles.tableColHeader}>
                  <View style={styles.tableCellHeaderText}>
                    <Text style={styles.textHeader}>Sometimes</Text>
                  </View>
                  <View style={styles.tableRowScore}>
                    <Text style={styles.tableCellScoreHeader}>2</Text>
                  </View>
                </View>
                <View style={styles.tableColHeader}>
                  <View style={styles.tableCellHeaderText}>
                    <Text style={styles.textHeader}>Often</Text>
                  </View>
                  <View style={styles.tableRowScore}>
                    <Text style={styles.tableCellScoreHeader}>3</Text>
                  </View>
                </View>
                <View style={styles.tableColHeader}>
                  <View style={styles.tableCellHeaderText}>
                    <Text style={styles.textHeader}>Very Often</Text>
                  </View>
                  <View style={styles.tableRowScore}>
                    <Text style={styles.tableCellScoreHeader}>4</Text>
                  </View>
                </View>
              </View>
              {soapprQuestion.slice(0, 12).map((item, index) => (
                <View key={index} style={styles.tableRow}>
                  <View style={styles.tableColQuestion}>
                    <Text style={styles.textNo}>{index + 1}.</Text>
                    <Text>{item}</Text>
                  </View>
                  <View style={styles.tableColCheck}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <View style={checkStyle(index)}>
                      {checked(0, formData.answers[index])}
                    </View>
                  </View>
                  <View style={styles.tableColCheck}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <View style={checkStyle(index)}>
                      {checked(1, formData.answers[index])}
                    </View>
                  </View>
                  <View style={styles.tableColCheck}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <View style={checkStyle(index)}>
                      {checked(2, formData.answers[index])}
                    </View>
                  </View>
                  <View style={styles.tableColCheck}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <View style={checkStyle(index)}>
                      {checked(3, formData.answers[index])}
                    </View>
                  </View>
                  <View style={styles.tableColCheckLast}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <View style={checkStyle(index)}>
                      {checked(4, formData.answers[index])}
                    </View>
                  </View>
                </View>
              ))}
            </View>
            <View style={styles.boxFooterOfPage}>
              <Text style={styles.textFooterOfPage}>
                Ω {'  '}2024 inflexxion, Inc. Permission granted solely for use
                in published format by individual practitioners in clinical
                practice. No other uses or alterations are authorized or
                permitted by copyright holder. Permissions questions:{' '}
                <Link>PainEDU@inflexxion.com</Link> The SOAPP-R was developed
                with a grant from the Nation Institutes of Health and an
                educational grant form Endo Pharmaceuticals.
              </Text>
            </View>
          </Page>
          <Page size="A4" style={styles.page}>
            <View style={styles.sectionImg}>
              <Image style={styles.img} src="/paincare-web.png" />
            </View>
            <View style={{ ...styles.table, marginTop: 24 }}>
              <View style={styles.tableRow}>
                <View style={styles.tableColDate}></View>
                <View style={styles.tableColHeader}>
                  <View style={styles.tableCellHeaderText}>
                    <Text style={styles.textHeader}>Never</Text>
                  </View>
                  <View style={styles.tableRowScore}>
                    <Text style={styles.tableCellScoreHeader}>0</Text>
                  </View>
                </View>
                <View style={styles.tableColHeader}>
                  <View style={styles.tableCellHeaderText}>
                    <Text style={styles.textHeader}>Seldom</Text>
                  </View>
                  <View style={styles.tableRowScore}>
                    <Text style={styles.tableCellScoreHeader}>1</Text>
                  </View>
                </View>
                <View style={styles.tableColHeader}>
                  <View style={styles.tableCellHeaderText}>
                    <Text style={styles.textHeader}>Sometimes</Text>
                  </View>
                  <View style={styles.tableRowScore}>
                    <Text style={styles.tableCellScoreHeader}>2</Text>
                  </View>
                </View>
                <View style={styles.tableColHeader}>
                  <View style={styles.tableCellHeaderText}>
                    <Text style={styles.textHeader}>Often</Text>
                  </View>
                  <View style={styles.tableRowScore}>
                    <Text style={styles.tableCellScoreHeader}>3</Text>
                  </View>
                </View>
                <View style={styles.tableColHeader}>
                  <View style={styles.tableCellHeaderText}>
                    <Text style={styles.textHeader}>Very Often</Text>
                  </View>
                  <View style={styles.tableRowScore}>
                    <Text style={styles.tableCellScoreHeader}>4</Text>
                  </View>
                </View>
              </View>
              {soapprQuestion.slice(12).map((item, index) => (
                <View key={index} style={styles.tableRow}>
                  <View style={styles.tableColQuestion}>
                    <Text style={styles.textNo}>{index + 12}.</Text>
                    <Text>{item}</Text>
                  </View>
                  <View style={styles.tableColCheck}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <View style={checkStyle(index + 12)}>
                      {checked(0, formData.answers[index + 12])}
                    </View>
                  </View>
                  <View style={styles.tableColCheck}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <View style={checkStyle(index + 12)}>
                      {checked(1, formData.answers[index + 12])}
                    </View>
                  </View>
                  <View style={styles.tableColCheck}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <View style={checkStyle(index + 12)}>
                      {checked(2, formData.answers[index + 12])}
                    </View>
                  </View>
                  <View style={styles.tableColCheck}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <View style={checkStyle(index + 12)}>
                      {checked(3, formData.answers[index + 12])}
                    </View>
                  </View>
                  <View style={styles.tableColCheckLast}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <View style={checkStyle(index + 12)}>
                      {checked(4, formData.answers[index + 12])}
                    </View>
                  </View>
                </View>
              ))}
              <View style={styles.tableRow}>
                <View style={styles.tableColScore}>
                  <Text>Score</Text>
                </View>
                <View style={styles.tableColTotalScore}>
                  <Text>{formData?.score}</Text>
                </View>
              </View>
            </View>
            <View style={styles.textFooter}>
              <Text>
                Please include any additional infomation you wish about the
                above answer.
              </Text>
              <Text>Thank you.</Text>
            </View>
            <View style={styles.boxFooterOfPage}>
              <Text style={styles.textFooterOfPage}>
                Ω {'  '}2024 inflexxion, Inc. Permission granted solely for use
                in published format by individual practitioners in clinical
                practice. No other uses or alterations are authorized or
                permitted by copyright holder. Permissions questions:{' '}
                <Link>PainEDU@inflexxion.com</Link> The SOAPP-R was developed
                with a grant from the Nation Institutes of Health and an
                educational grant form Endo Pharmaceuticals.
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </Box>
  )
}
