import { Image, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

function MedicalRecordsReleaseForm({ fillableData, styles }) {
  return (
    <View style={{ ...styles.gapBox }}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 8,
        }}
      >
        <Image
          style={{ width: '110px', objectFit: 'cover' }}
          src="/paincare-web-v2.png"
        />
        <View style={styles.boxtitle}>
          <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
        </View>
        <View style={styles.boxtitle}>
          <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
        </View>
      </View>
      <View style={{ textAlign: 'center', paddingTop: 10, width: '100%' }}>
        <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
          MEDICAL RECORDS RELEASE FORM
        </Text>
      </View>
      <Text
        style={{ lineHeight: 1.25, textAlign: 'justify', marginTop: '-10px' }}
      >
        This authorization must be completed, dated, and signed by the patient
        or by a person authorized by law to give authorization.
      </Text>
      <View
        style={{
          width: '450px',
          gap: '4px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <View style={{ width: '100%' }}>
          <View style={{ ...styles.boxRowData, gap: '0px' }}>
            <Text>I</Text>
            <Text
              style={{
                width: '300px',
                borderBottom: '1px dotted #000',
                textAlign: 'center',
              }}
            >
              {fillableData.namePatient ? fillableData.namePatient : ''}
            </Text>

            <Text style={{ width: '120px' }}>(Name of Patient)</Text>
            <Text
              style={{
                width: '160px',
                borderBottom: '1px dotted #000',
                textAlign: 'center',
              }}
            >
              {fillableData?.DOB
                ? moment(fillableData?.DOB).format('MM/DD/YYYY')
                : ''}
            </Text>
            <Text style={{ width: '120px' }}>(DOB), authorize</Text>
          </View>
        </View>

        <View style={{ width: '100%' }}>
          <View style={{ ...styles.boxRowData, gap: '0px' }}>
            <Text
              style={{
                ...styles.paddingLeftFormData,
                width: '200px',
                textAlign: 'center',
              }}
            >
              {fillableData.authorizePage17 ? fillableData.authorizePage17 : ''}
            </Text>

            <Text>
              (Provider/Facility) that has provided me medical treatment to
            </Text>
          </View>
        </View>
        <View>
          <Text style={{ textAlign: 'justify', lineHeight: 1.25 }}>
            that has provided me medical treatment to release copies of my
            medical records (except for HIV/AIDS related records, genetic
            testing information, or Drug and Alcohol diagnosis and/or treatment
            information) to:
          </Text>
        </View>
        <View
          style={{
            paddingTop: 20,
            gap: '4px',
            textAlign: 'start',
            width: '100%',
          }}
        >
          <Text>PAIN CARE SPECIALISTS OF OREGON</Text>
          <Text>2480 LIBERTY ST NE, STE 180</Text>
          <Text>SALEM, OR 97301</Text>
        </View>
        <View style={{ flexDirection: 'row', paddingTop: 20, width: '100%' }}>
          <Text>For records within the date range of</Text>
          <Text
            style={{
              borderBottom: '1px dotted #000',
              width: '140px',
              textAlign: 'center',
            }}
          >
            {fillableData.startOneDate
              ? moment(fillableData.startOneDate.toDate()).format('MM/DD/YYYY')
              : ''}
          </Text>
          <Text>to</Text>
          <Text
            style={{
              borderBottom: '1px dotted #000',
              width: '140px',
              textAlign: 'center',
            }}
          >
            {fillableData.endOneDate
              ? moment(fillableData.endOneDate.toDate()).format('MM/DD/YYYY')
              : ''}
          </Text>
        </View>
        <View style={{ paddingTop: 20, width: '100%', textAlign: 'start' }}>
          <Text>And/Or:</Text>
        </View>
        <View
          style={{
            paddingTop: 20,
            width: '100%',
            textAlign: 'start',
            gap: '4px',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '110px' }}>Name of Recipient:</Text>
            <Text style={{ width: '100%', borderBottom: '1px dotted #000' }}>
              {` `}
              {fillableData.nameRecipient ? fillableData.nameRecipient : ''}
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '90px' }}>Mailing Address:</Text>
            <Text style={{ width: '100%', borderBottom: '1px dotted #000' }}>
              {` `}
              {fillableData.maillingAddress ? fillableData.maillingAddress : ''}
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '65px' }}>Fax Number:</Text>
            <Text style={{ width: '100%', borderBottom: '1px dotted #000' }}>
              {` `}
              {fillableData.faxPage17 ? fillableData.faxPage17 : ''}
            </Text>
          </View>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <Text>For records within the date range of</Text>
            <Text
              style={{
                borderBottom: '1px dotted #000',
                width: '140px',
                textAlign: 'center',
              }}
            >
              {fillableData.startDateRecords
                ? moment(fillableData.startDateRecords.toDate()).format(
                    'MM/DD/YYYY'
                  )
                : ''}
            </Text>
            <Text>to</Text>
            <Text
              style={{
                borderBottom: '1px dotted #000',
                width: '140px',
                textAlign: 'center',
              }}
            >
              {fillableData.endDateRecords
                ? moment(fillableData.endDateRecords.toDate()).format(
                    'MM/DD/YYYY'
                  )
                : ''}
            </Text>
          </View>
          <View
            style={{
              paddingTop: 20,
              width: '100%',
              textAlign: 'justify',
              lineHeight: 1.5,
            }}
          >
            <Text>
              The information will be used on my behalf for continuity of care.
              Please send the entire medical record (all information) to the
              above named recipient. The recipient understands this record may
              be voluminous and agrees to pay all reasonable charges associated
              with providing this record. This authorization may be revoked at
              any time. This release will be valid for 1 year from the date
              signed.
            </Text>
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: 20,
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              paddingTop: '10px',
              alignItems: 'flex-end',
              paddingBottom: '2px',
            }}
          >
            {fillableData.patientSigning.includes('data:image/') ? (
              <Image
                style={{
                  width: '120px',
                  objectFit: 'cover',
                }}
                src={fillableData.patientSigning}
              />
            ) : (
              <Text
                style={{
                  width: '125px',
                  textAlign: 'center',
                  paddingTop: 14,
                }}
              >
                {fillableData.patientSigning}
              </Text>
            )}
            <Text style={{ width: '175px', textAlign: 'center' }}>
              {fillableData?.createdAt
                ? moment(fillableData?.createdAt).format('MM/DD/YYYY')
                : ''}
            </Text>
          </View>
          <View
            style={{
              borderTop: '1px dotted #000',
              width: '100%',
            }}
          ></View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              paddingTop: '2px',
            }}
          >
            <Text>Signature of Patient</Text>
            <Text style={{ width: '175px', textAlign: 'center' }}>Date</Text>
          </View>
        </View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '60px',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              paddingBottom: '2px',
            }}
          >
            {fillableData.signaturePatientLaw.includes('data:image/') ? (
              <Image
                style={{
                  width: '120px',
                  objectFit: 'cover',
                }}
                src={fillableData.signaturePatientLaw}
              />
            ) : (
              <Text
                style={{
                  width: '125px',
                  textAlign: 'center',
                  paddingTop: 14,
                }}
              >
                {fillableData.signaturePatientLaw}
              </Text>
            )}
            <Text style={{ width: '175px', textAlign: 'center' }}>
              {fillableData?.createdAt
                ? moment(fillableData?.createdAt).format('MM/DD/YYYY')
                : ''}
            </Text>
          </View>
          <View
            style={{
              borderTop: '1px dotted #000',
              width: '100%',
            }}
          ></View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              paddingTop: '2px',
            }}
          >
            <Text>Signature of person authorized by law</Text>
            <Text style={{ width: '175px', textAlign: 'center' }}>Date</Text>
          </View>
        </View>
      </View>
    </View>
  )
}

export default MedicalRecordsReleaseForm
