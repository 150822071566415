/* eslint-disable react-hooks/exhaustive-deps */
import { DownloadIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Input,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import moment from 'moment'
import { DatePicker } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import React, { useCallback, useEffect } from 'react'

import Signature from '../../../Components/Signature'

export const crnaansQuestion = [
  'Anesthesia record IS (a) present, (b) complete, (c) reflects appropriate monitoring, and (d) includes assessment pre- and post-operatively',
  'Required signatures are present and complete',
  'Procedure completed without significant intra or post-op anesthesia related complications noted',
  'If present, complications were managed appropriately',
  'Patient evaluated by an anesthesia provider prior to discharge',
  'Duration of PACU stay was appropriate and not prolonged',
]
function FormCRNAANSAngela5({ data, facility, onSubmit, loading }) {
  const params = useParams()
  const { handleSubmit, register, errors, control, setValue, watch, trigger } =
    useForm()

  const initData = useCallback(() => {
    setValue('form', data)
    setValue('form.dos', moment(data?.dos))
    setValue('form.period', moment(data?.period))
  }, [data])

  useEffect(() => {
    if (data) initData()
  }, [data, initData])

  return (
    <>
      <Box>
        <Stack alignItems="center" spacing="1rem">
          <Image
            src={facility === 'PCSO' ? '/paincare-web.png' : '/LogoOSSC.jpeg'}
            width="325px"
          />
          <Text align="center" fontSize="xs">
            {facility === 'PCSO' ? (
              <>
                2480 Liberty Street NE, Suite 180, Salem, OR 97301 <br />
                Phone: (503) 371-1010 Fax: (503) 371-0805
              </>
            ) : (
              <>
                Oregon Specialists Surgery Center 2785 River Road S, Salem, OR
                97302
              </>
            )}
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            MEDICAL RECORD/PEER REVIEW WORKSHEET
          </Text>
        </Stack>
      </Box>
      <Box mt="4rem">
        <Flex w="100%" justifyContent="end">
          <Button
            leftIcon={<DownloadIcon />}
            colorScheme="blue"
            as={Link}
            to={`/peer-review/pdf/${params?.id}`}
          >
            EXPORT PDF
          </Button>
        </Flex>

        <form onSubmit={handleSubmit(onSubmit)} id="time-away-request-form">
          <Box>
            <HStack>
              <FormControl
                id="anesthesia"
                isRequired
                isInvalid={errors?.form?.anesthesia}
              >
                <FormLabel>Anesthesia Provider</FormLabel>
                <Input
                  name="form.anesthesia"
                  placeholder="Anesthesia Provider"
                  ref={register({
                    required: 'Anesthesia Provider required.',
                  })}
                  defaultValue={''}
                />
              </FormControl>
              <FormControl
                id="period"
                isRequired
                defaultValue={moment()}
                isInvalid={errors?.form?.period}
              >
                <FormLabel>Period</FormLabel>

                <Controller
                  render={props => (
                    <DatePicker
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      value={props?.value}
                      picker="quarter"
                      onChange={props.onChange}
                      format={'Y-[Q]Q'}
                    />
                  )}
                  name="form.period"
                  control={control}
                  rules={{ required: 'Period required.' }}
                  defaultValue={moment()}
                />
                <FormErrorMessage>
                  {errors?.form?.date && errors?.form?.date?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl id="mr" isRequired isInvalid={errors?.mr}>
                <FormLabel>MR#</FormLabel>
                <Input
                  name="form.mr"
                  placeholder="MR#"
                  ref={register({
                    required: 'MR# required.',
                  })}
                  defaultValue={data?.mr ?? ''}
                />
              </FormControl>
              <FormControl isRequired isInvalid={errors?.form?.dos}>
                <FormLabel>DOS</FormLabel>
                <Controller
                  render={props => (
                    <DatePicker
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      value={props?.value}
                      onChange={props?.onChange}
                    />
                  )}
                  name="form.dos"
                  control={control}
                  rules={{ required: 'DOS is required.' }}
                  defaultValue={moment()}
                />
              </FormControl>
            </HStack>
          </Box>
          <Text fontSize="xl" fontWeight="bold" my={6}>
            ANESTHESIA PROVIDER PEER/UTILIZATION REVIEW CRITERIA
          </Text>
          <Box>
            {crnaansQuestion.map((question, index) => (
              <Flex
                key={index}
                justifyContent="space-between"
                my={4}
                pl={index === 3 ? 8 : 0}
              >
                <Text fontSize={18}>
                  {index !== 3 ? `${index + 1}.` : 'a.'} {question}
                </Text>
                <Flex gap={8}>
                  <FormControl
                    isInvalid={
                      errors?.form?.answers && errors?.form?.answers[index]
                    }
                  >
                    <Controller
                      render={props => (
                        <RadioGroup
                          onChange={props.onChange}
                          value={props.value}
                        >
                          <Stack direction="row">
                            <Radio value="yes">Yes</Radio>
                            <Radio value="no">No</Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                      name={`form.answers[${index}]`}
                      control={control}
                      rules={{ required: true }}
                      defaultValue={''}
                    />
                  </FormControl>
                </Flex>
              </Flex>
            ))}
          </Box>
          <Box mt={12}>
            <FormControl>
              <Text mb="8px">Comment: </Text>
              <Controller
                render={props => (
                  <Textarea
                    value={props.value}
                    placeholder="Comment"
                    onChange={props.onChange}
                  />
                )}
                name="form.comment"
                control={control}
                defaultValue=""
              />
            </FormControl>
          </Box>

          <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
            <Box>
              <Controller
                name="form.signatureCollector"
                control={control}
                defaultValue={data?.signatureCollector || ''}
                render={({ field }) => {
                  return (
                    <FormControl
                      w="30vw"
                      isInvalid={errors?.form?.signatureCollector}
                    >
                      <Signature
                        label="Peer Reviewer Signature"
                        field={field}
                        setValue={setValue}
                        watch={watch}
                        data={data}
                        trigger={trigger}
                      />
                      <FormErrorMessage>
                        {errors?.form?.signatureCollector &&
                          errors.form.signatureCollector.message}
                      </FormErrorMessage>
                    </FormControl>
                  )
                }}
              />
            </Box>
            <Box display={'flex'} alignItems={'end'}>
              <FormControl isRequired>
                <FormLabel>Date</FormLabel>
                <Controller
                  render={props => (
                    <Input
                      value={props.value}
                      onChange={props.onChange}
                      isReadOnly
                      textAlign={'center'}
                    />
                  )}
                  name="form.date"
                  control={control}
                  defaultValue={moment().format('YYYY-MM-DD')}
                  rules={{ required: 'Date is required.' }}
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl id="reviewer">
                <FormLabel>Peer Reviewer Printed Name:</FormLabel>
                <Input
                  name="form.reviewer"
                  placeholder="Peer Reviewer Printed Name"
                  ref={register()}
                  defaultValue={data?.reviewer ?? ''}
                />
              </FormControl>
            </Box>
          </SimpleGrid>

          <Center mt="3rem" pb="2rem">
            <Button type="submit" isLoading={loading}>
              Submit
            </Button>
          </Center>
        </form>
      </Box>
    </>
  )
}

export default FormCRNAANSAngela5
