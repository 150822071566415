import React from 'react'
import { Center, Text } from '@chakra-ui/react'
import { FaRegCircleCheck } from 'react-icons/fa6'

export default function SubmitSuccess() {
  return (
    <Center h="100vh" flexDirection="column">
      <FaRegCircleCheck color='green' fontSize={200} />
      <Text fontSize='4xl'>Thank you!</Text>
      <Text fontSize='2xl' textAlign='center'>Your form has been submitted successfully.</Text>
    </Center>
  )
}
