import { Center, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { CardContent } from '../../../../../../Components/Card/card'

function TreatmentOptions({ formView }) {
  return (
    <CardContent>
      <Text>
        <span style={{ fontWeight: 'bold' }}>{`Treatment Options.`}</span>
        {` Our practice may use and disclose your PHI to inform you of potential treatment options or alternatives.`}
      </Text>
      <Text pt="15px">
        <span
          style={{ fontWeight: 'bold' }}
        >{`Release of Information to Family Friends.`}</span>
        {` Our practice may release your PHI to a friend or family member who is involved in your care or who assists in taking care of you. For example, a guardian may ask that neighbor take their parent or child to the physician's office for treatment. This neighbor may have access to this patient's medical information. We may also release information to friends or family members involved in your payment for health services we provide.`}
      </Text>
      <Text pt="15px">
        <span
          style={{ fontWeight: 'bold' }}
        >{`Disclosures Required by Law.`}</span>
        {` Our practice will use and disclose your PHI when we are required to do so by federal, state or local law.`}
      </Text>
      <Text pt="15px">
        <span
          style={{ fontWeight: 'bold' }}
        >{`USE AND DISCLOSURE OF YOUR PHI IN CERTAIN SPECIAL CIRCUMSTANCES WITHOUT APPROVAL`}</span>
        {` The following describe unique scenarios in which we may use or disclose your PHI without consent or approval.`}
      </Text>
      <Text pt="15px">
        <span style={{ fontWeight: 'bold' }}>{`Public Health Risks.`}</span>
        {` Our practices may disclose your PHI to public health authorities that are authorized by law to collect information for the purpose of:`}
      </Text>
      <VStack pt="15px" pl="15px" alignItems="start">
        <Text>{`• Reporting child abuse or neglect`}</Text>
        <Text>{`• Maintaining vital records, such as births and deaths`}</Text>
        <Text>{`• Preventing or controlling disease, injury or disability`}</Text>
        <Text>{`• Notifying a person regarding potential exposure to a communicable disease`}</Text>
        <Text>{`• Reporting reactions to drugs or problems with products or devices`}</Text>
        <Text>{`• Notifying individuals if a product or device may be using has been recalled`}</Text>
        <Text>{`• Notifying appropriate government agency (ies) and authority (ies) regarding the potential abuse or neglect of an adult patient (including domestic violence); however, we will only disclose thisinformation if the patient agrees or we are required by law to disclose this information.`}</Text>
        <Text>{`• Notifying your employer under limited circumstances related primarily to workplace injury or illnessor medical surveillance.`}</Text>
      </VStack>
      <Text pt="15px">
        <span
          style={{ fontWeight: 'bold' }}
        >{`Health Oversight activities.`}</span>
        {` Our practice may disclose your PHI to a health oversight agency for activities authorized by law. Oversight activities can include for example, investigations, inspections, audits, surveys, licensure and disciplinary actions; civil, administrative and criminal procedures or actions; or other activities necessary for the government to monitor government programs, compliance with civil rights laws and health care system in general. We may use your information to report diseases to the health department.`}
      </Text>
      <Text pt="15px">
        <span
          style={{ fontWeight: 'bold' }}
        >{`Lawsuits and Similar Proceedings.`}</span>
        {` Our practice may disclose your PHI in response to a court or administrative order if you are involved in a lawsuit or similar proceeding. We also may disclose your PHI in response to a discovery request, subpoena, or other lawful process by another party involved in the dispute, but not only if we have made an effort to inform you of the request or to obtain an order protecting the infor mation the party has requested.`}
      </Text>
      <Center pt="2rem">{`2/16`}</Center>
    </CardContent>
  )
}

export { TreatmentOptions }
