import {
  Center,
  Text,
  Box,
  InputGroup,
  InputLeftAddon,
  Input,
  HStack,
  VStack,
  Textarea,
  FormControl,
  Checkbox,
  Grid,
  CheckboxGroup,
  Table,
  Tbody,
  Th,
  Td,
  Tr,
} from '@chakra-ui/react'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CardContent } from '../../../../../../Components/Card/card'

function RecruitPatientsSecondForm({ formView }) {
  const {
    control,
    // formState: { errors },
    setValue,
    clearErrors,
    // watch,
  } = useForm({
    defaultValues: {
      medications: [{ name: '', dose: '', frequency: '' }],
      hospitalizations: [{ date: '', reason: '' }],
    },
  })
  // const {
  // append: appendHospital,
  // fields: fieldsHospital,
  // remove: removeHospital,
  // append: appendHospital,
  // fields: fieldsHospital,
  // remove: removeHospital,
  // replace,
  // } = useFieldArray({
  //   control,
  //   name: 'hospitalizations',
  // })

  const question = [
    {
      title: 'Heart Disease',
      name: 'heartDisease',
    },
    {
      title: 'Lung Disease',
      name: 'lungDisease',
    },
    {
      title: 'Arthritis',
      name: 'arthritis',
    },
    {
      title: 'Cancer',
      name: 'cancer',
    },
    {
      title: 'Stroke',
      name: 'stroke',
    },
  ]
  return (
    <CardContent>
      <Box>
        <Text fontWeight="bold" color="#2D3748">
          New Patient Intake Form , Page 2 of 3{' '}
        </Text>
        <Text fontWeight="bold" fontSize="14px" pt="10px" pl="10px">
          Current Medications
        </Text>
      </Box>
      <VStack spacing="4">
        <Box w="100%">
          {formView?.medications.map((item, index) => (
            <HStack w="100%">
              <Box
                w="100%"
                key={item?.id}
                bgImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23B7DDE8FF' stroke-width='3' stroke-dasharray='19%2c 16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`}
                p="1em"
                borderRadius="16px"
                mt="15px"
              >
                <VStack>
                  <Controller
                    name={`medications.${index}.name`}
                    control={control}
                    defaultValue=""
                    render={props => (
                      <InputGroup>
                        <InputLeftAddon
                          children="Name"
                          bg="#B7DDE9"
                          color="#FFFFFF"
                        />
                        <Input
                          _readOnly={{
                            border: '1px solid #ccc',
                          }}
                          variant="no-effects"
                          readOnly
                          bg="#F9F9F9"
                          border="1px solid black"
                          defaultValue={formView?.medications[index]?.name}
                        />
                      </InputGroup>
                    )}
                  />
                  <Controller
                    name={`medications.${index}.dose`}
                    control={control}
                    defaultValue=""
                    render={props => (
                      <InputGroup>
                        <InputLeftAddon
                          children="Dose"
                          bg="#B7DDE9"
                          color="#FFFFFF"
                        />
                        <Input
                          _readOnly={{
                            border: '1px solid #ccc',
                          }}
                          variant="no-effects"
                          readOnly
                          bg="#F9F9F9"
                          border="1px solid black"
                          defaultValue={formView?.medications[index]?.dose}
                        />
                      </InputGroup>
                    )}
                  />
                  <Controller
                    name={`medications.${index}.frequency`}
                    control={control}
                    defaultValue=""
                    render={props => (
                      <InputGroup>
                        <InputLeftAddon
                          children="Frequency"
                          bg="#B7DDE9"
                          color="#FFFFFF"
                        />
                        <Input
                          _readOnly={{
                            border: '1px solid #ccc',
                          }}
                          variant="no-effects"
                          readOnly
                          bg="#F9F9F9"
                          border="1px solid black"
                          defaultValue={formView?.medications[index]?.frequency}
                        />
                      </InputGroup>
                    )}
                  />
                </VStack>
              </Box>
            </HStack>
          ))}
        </Box>
      </VStack>
      <Text fontWeight="bold" fontSize="14px" pt="25px" pl="10px">
        Current Medical Problems
      </Text>
      <Box pt="10px">
        <Controller
          name="currentMedicalProblems"
          control={control}
          defaultValue=""
          render={props => (
            <FormControl>
              <Textarea
                _readOnly={{
                  border: '1px solid #ccc',
                }}
                variant="no-effects"
                readOnly
                bg="#F9F9F9"
                border="1px solid black"
                defaultValue={formView?.currentMedicalProblems}
              />
            </FormControl>
          )}
        />
      </Box>

      <Box>
        <Text fontSize="13px" pt="30px" pl="10px" pb="10px">
          <span style={{ py: '1', fontWeight: 'bold' }}>Allergies:</span>
          {`(circle all that apply)`}
        </Text>
        <Controller
          name="medicationsIntake"
          control={control}
          render={props => (
            <FormControl>
              <CheckboxGroup
                onChange={e => {
                  setValue('medicationsIntake', e)
                  clearErrors('medicationsIntake')
                }}
                defaultValue={formView?.medicationsIntake}
                onBlur={props.onBlur}
                w="100%"
                spacing="0px"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(2,1fr)',
                    md: 'repeat(2,1fr)',
                    lg: 'repeat(3, 1fr)',
                    xl: 'repeat(4,1fr)',
                  }}
                  gap={4}
                >
                  <Checkbox isDisabled pr="0.5rem" value="noneIntake">
                    None
                  </Checkbox>

                  <Checkbox isDisabled pr="0.5rem" value="tapeIntake">
                    Tape
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="iodineIntake">
                    Iodine
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="latexIntake">
                    Latex
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="contrastIntake">
                    Contrast Dye
                  </Checkbox>

                  <HStack>
                    <Checkbox isDisabled value="otherIntake"></Checkbox>
                    <Controller
                      name="inputOtherIntake"
                      control={control}
                      defaultValue=""
                      render={props => (
                        <FormControl>
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            readOnly
                            bg="#F9F9F9"
                            border="1px solid black"
                            placeholder="Other"
                            defaultValue={formView?.inputOtherIntake}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                          />
                        </FormControl>
                      )}
                    />
                  </HStack>
                </Grid>
              </CheckboxGroup>
            </FormControl>
          )}
        />
      </Box>
      <Text fontWeight="bold" fontSize="14px" pt="25px" pl="10px">
        {`Medication Allergies (list)`}
      </Text>
      <Box pt="10px">
        <Controller
          name="medicationAllergies"
          control={control}
          defaultValue=""
          render={props => (
            <FormControl>
              <Textarea
                _readOnly={{
                  border: '1px solid #ccc',
                }}
                variant="no-effects"
                readOnly
                bg="#F9F9F9"
                border="1px solid black"
                defaultValue={formView?.medicationAllergies}
              />
            </FormControl>
          )}
        />
      </Box>
      <Text fontSize="14px" pt="25px" pl="10px">
        <span style={{ py: '1', fontWeight: 'bold' }}>Surgical History </span>
        {`(Circle all that apply)`}
      </Text>
      <Box pt="10px">
        <Controller
          name="surgicalHistory"
          control={control}
          render={props => (
            <FormControl>
              <CheckboxGroup
                onChange={e => {
                  setValue('surgicalHistory', e)
                  clearErrors('surgicalHistory')
                }}
                defaultValue={formView?.surgicalHistory}
                w="100%"
                spacing="0px"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(2,1fr)',
                    lg: 'repeat(2, 1fr)',
                    xl: 'repeat(3,1fr)',
                  }}
                  gap={4}
                >
                  <Checkbox isDisabled pr="0.5rem" value="cardiacSurgical">
                    Caridac/heart
                  </Checkbox>

                  <Checkbox isDisabled pr="0.5rem" value="cervicalSurgical">
                    Cervical/Neck
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="lumbarSurgical">
                    Lumbar/Back
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="hipSurgical">
                    Hip Left/Right
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="kneeSurgical">
                    Knee Left/Right
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="shoulderSurgical">
                    Shoulder Left/Right
                  </Checkbox>

                  <HStack>
                    <Checkbox isDisabled value="otherSurgical"></Checkbox>
                    <Controller
                      name="inputOtherSurgical"
                      control={control}
                      defaultValue=""
                      render={props => (
                        <FormControl>
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            readOnly
                            bg="#F9F9F9"
                            border="1px solid black"
                            placeholder="Other"
                            defaultValue={formView?.inputOtherSurgical}
                          />
                        </FormControl>
                      )}
                    />
                  </HStack>
                </Grid>
              </CheckboxGroup>
            </FormControl>
          )}
        />
      </Box>
      <Text fontWeight="bold" fontSize="14px" pt="25px" pl="10px">
        Past Hospitalizations
      </Text>
      <Box>
        <VStack spacing="4">
          <Box w="100%">
            {formView?.hospitalizations.map((item, index) => (
              <HStack w="100%">
                <Box
                  w="100%"
                  key={item?.id}
                  bgImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23B7DDE8FF' stroke-width='3' stroke-dasharray='19%2c 16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`}
                  p="1em"
                  borderRadius="16px"
                  mt="15px"
                >
                  <VStack>
                    <Controller
                      name={`hospitalizations.${index}.date`}
                      control={control}
                      defaultValue=""
                      render={props => (
                        <InputGroup>
                          <InputLeftAddon
                            children="Date"
                            bg="#B7DDE9"
                            color="#FFFFFF"
                          />
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            readOnly
                            bg="#F9F9F9"
                            defaultValue={
                              formView?.hospitalizations[index]?.date
                            }
                          />
                        </InputGroup>
                      )}
                    />
                    <Controller
                      name={`hospitalizations.${index}.reason`}
                      control={control}
                      defaultValue=""
                      render={props => (
                        <InputGroup>
                          <InputLeftAddon
                            children="reason"
                            bg="#B7DDE9"
                            color="#FFFFFF"
                          />
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            readOnly
                            bg="#F9F9F9"
                            defaultValue={
                              formView?.hospitalizations[index]?.reason
                            }
                          />
                        </InputGroup>
                      )}
                    />
                  </VStack>
                </Box>
              </HStack>
            ))}
          </Box>
        </VStack>
      </Box>
      <Text fontSize="14px" pt="40px" pl="10px">
        <span style={{ py: '1', fontWeight: 'bold' }}>Family History: </span>
        {`(Please place an X where applicable)`}
      </Text>
      <Box overflow="auto" h="100%">
        <Table variant="unstyled">
          <Tbody p="0px">
            <Th p="0px">
              <Td w={{ base: '400px', lg: '0px' }} p="0px">
                {' '}
              </Td>
              <Td fontSize="14px" pl="185px">
                Father
              </Td>
              <Td fontSize="14px" px="2rem">
                Mother
              </Td>
              <Td fontSize="14px">Grandparent</Td>
              <Td fontSize="14px" px="1.7rem">
                Sibling
              </Td>
              <Td fontSize={"14px"}>None</Td>
            </Th>
            {question.map((e, i) => (
              <Tr key={i}>
                <Tbody>
                  <Td w="0" p="0px" py="1rem">
                    <Text w="180px" textAlign="start">
                      {e.title}
                    </Text>
                  </Td>
                  <Td p="0px">
                    <Controller
                      name={e.name}
                      control={control}
                      render={props => (
                        <FormControl>
                          <CheckBoxFamilyHistory
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            defaultValue={formView?.[e.name]}
                          />
                        </FormControl>
                      )}
                    />
                  </Td>
                </Tbody>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Center pt="2rem">{`8/16`}</Center>
    </CardContent>
  )
}
export { RecruitPatientsSecondForm }

function CheckBoxFamilyHistory({ onChange, defaultValue }) {
  return (
    <CheckboxGroup onChange={onChange} defaultValue={defaultValue}>
      <Td pl={{ base: '25px' }}>
        <Checkbox isDisabled value="father" />
      </Td>
      <Td pl={{ base: '95px' }}>
        <Checkbox isDisabled value="mother" />
      </Td>
      <Td pl={{ base: '130px' }}>
        <Checkbox isDisabled value="grandparent" />
      </Td>
      <Td pl={{ base: '125px' }}>
        <Checkbox isDisabled value="sibling" />
      </Td>
      <Td pl={{ base: '75px' }}>
        <Checkbox isDisabled value="none" />
      </Td>

    </CheckboxGroup>
  )
}
