import React from 'react'
import { Center, Box, Text } from '@chakra-ui/react'
import { CardContent } from '../../../../../../Components/Card/card'
import { totalNonWebEnabledPage } from '../..'

function PatientWelcomePacket({ formView }) {
  return (
    <CardContent>
      <Box>
        {' '}
        <Center fontWeight="bold" fontSize={{ base: '18px', sm: '20px' }}>
          {`PATIENT WELCOME PACKET`}
        </Center>
        <Text pt="40px" fontWeight="bold" textAlign="center">
          {`Pain Care Specialists of Oregon's Mission:`}
        </Text>
        <Text pt="20px">{`Our mission is to provide the best possible pain management in a
   caring environment. We offer the most advanced interventional
   pain management treatments to ideally help those in chronic
   pain avoid narcotics. We are committed to excellence through
   continuing education, researched techniques, and state-of-the-
   art equipment. We will value each individual and family. Our goal
   is to be recognized as the premier interventional pain management
   clinic by consistently providing excellent medical service based
   upon timely, knowledgeable, compassionate care from the
   moment you come through the door until you leave. We strive to
   have patients choose Pain Care Specialists of Oregon for optimal
   medical attention for themselves, their family and the community.`}</Text>
      </Box>
      <Center pt="2rem">{`1/${totalNonWebEnabledPage}`}</Center>
    </CardContent>
  )
}

export { PatientWelcomePacket }
