import { Image, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

function SignatureCancellationSecond({ fillableData, styles }) {
  return (
    <View style={{ gap: '10px', width: '100%' }}>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          I have read the above statement and understand the importance of this
          lab test process for the protection of Pain Care Specialists of
          Oregon, LLC employees and physicians.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          I do agree to have the above tests done, and I understand the test
          results will be acquired by Pain Care Specialists of Oregon, LLC.
        </Text>
      </View>
      <View>
        <Text style={{ fontWeight: 'bold' }}>
          By signing this form, you agree that you have read and understand the
          above policies.
        </Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: 10,
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            paddingTop: '10px',
            alignItems: 'flex-end',
            paddingBottom: '2px',
          }}
        >
          <Text
            style={{
              textAlign: 'center',
              width: '120px',
            }}
          >
            {fillableData.patientSigning ? fillableData.patientSigning : ''}
          </Text>
          <Text style={{ width: '175px', textAlign: 'center' }}>
            {fillableData?.createdAt
              ? moment(fillableData?.createdAt).format('MM/DD/YYYY')
              : ''}
          </Text>
        </View>
        <View
          style={{
            borderTop: '1px dotted #000',
            width: '100%',
          }}
        ></View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            paddingTop: '2px',
          }}
        >
          <Text>Patient of Legal Representative Name</Text>
          <Text style={{ width: '175px', textAlign: 'center' }}>Date</Text>
        </View>
      </View>

      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '60px',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            paddingBottom: '2px',
          }}
        >
          {fillableData.signaturePatientLaw && (
            <Image
              style={{
                width: '120px',
              }}
              src={fillableData.signaturePatientLaw}
            />
          )}
          <Text style={{ width: '175px', textAlign: 'center' }}>
            {fillableData?.createdAt
              ? moment(fillableData?.createdAt).format('MM/DD/YYYY')
              : ''}
          </Text>
        </View>
        <View
          style={{
            borderTop: '1px dotted #000',
            width: '100%',
          }}
        ></View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            paddingTop: '2px',
          }}
        >
          <Text>Signature of Legal Representative</Text>
          <Text style={{ width: '175px', textAlign: 'center' }}>Date</Text>
        </View>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          paddingTop: '10px',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            paddingBottom: '2px',
          }}
        >
          <Text style={{ width: '120px', textAlign: 'center' }}>
            {fillableData?.dobPatient
              ? moment(fillableData?.dobPatient).format('MM/DD/YYYY')
              : ''}
          </Text>
        </View>
        <View
          style={{
            borderTop: '1px dotted #000',
            width: '100%',
          }}
        ></View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            paddingTop: '2px',
          }}
        >
          <Text style={{ textAlign: 'start' }}>Patient's Date of Birth</Text>
        </View>
      </View>
    </View>
  )
}

export default SignatureCancellationSecond
