import { Image, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

function RecruitingPatientsFirstForm({ fillableData, styles }) {
  return (
    <View style={{ ...styles.gapBox }}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 8,
        }}
      >
        <Image
          style={{ width: '110px', objectFit: 'cover' }}
          src="/paincare-web-v2.png"
        />
        <View style={styles.boxtitle}>
          <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
        </View>
        <View style={styles.boxtitle}>
          <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
        </View>
      </View>
      <View
        style={{
          width: '450px',
          paddingTop: '6px',
          gap: '3px',
        }}
      >
        <View style={{ width: '100%' }}>
          <View style={styles.boxRowData}>
            <View
              style={{
                ...styles.boxRowData,
                width: '100%',
                gap: '0px',
              }}
            >
              <Text style={{ width: '42px' }}>Name :</Text>
              <Text style={{ ...styles.paddingLeftFormData }}>
                {fillableData.namePatient ? fillableData.namePatient : ''}
              </Text>
            </View>
            <View
              style={{
                ...styles.boxRowData,
                width: '100%',
                gap: '0px',
              }}
            >
              <Text style={{ width: '35px' }}>DOB :</Text>
              <Text style={{ ...styles.paddingLeftFormData }}>
                {fillableData?.dobPatient
                  ? moment(fillableData?.dobPatient).format('MM/DD/YYYY')
                  : ''}
              </Text>
            </View>
          </View>
        </View>
        <Text style={{ fontWeight: 'bold' }}>
          New Patient Intake Form, Page 1 of 3
        </Text>
        <View
          style={{
            backgroundColor: '#b0acac',
            width: '100%',
            height: '4px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>The pain is located mainly at the: (check one)</Text>
          <View
            style={{
              paddingTop: 4,
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '6px',
            }}
          >
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.painLocated === 'neckLocated' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Neck</Text>
            </View>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.painLocated === 'midbackLocated' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Mid Back</Text>
            </View>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.painLocated === 'lowbackLocated' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Low Back</Text>
            </View>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.painLocated === 'shoulderLocated' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Shoulder</Text>
            </View>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.painLocated === 'hipLocated' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Hip</Text>
            </View>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.painLocated === 'kneeLocated' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Knee</Text>
            </View>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.painLocated === 'otherPainlocated' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text>Other</Text>
                {` `}
                <Text
                  style={{
                    borderBottom: '1px dotted #000',
                  }}
                >
                  {fillableData.otherLocated ? fillableData.otherLocated : ''}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>
            <Text style={{ fontWeight: 'bold' }}>The pain feels like:</Text>
            {` `}
            <Text>(check all that apply)</Text>
          </Text>
          <View
            style={{
              paddingTop: 6,
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '6px',
            }}
          >
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painFeels.includes('sharpFeels') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Sharp/stabbing</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painFeels.includes('dullFeels') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Dull/achy</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painFeels.includes('spasmsFeels') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Like muscle spasms</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painFeels.includes('burningFeels') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Burning</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painFeels.includes('areasNumbnessFeels') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Like numbness/tingling</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painFeels.includes('otherFeels') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Other</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text>
            <Text style={{ fontWeight: 'bold' }}>
              Does the pain radiate or travel?
            </Text>
            {` `}
            <Text>(check one)</Text>
          </Text>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.trueFalseChoiceRow, gap: 6 }}>
              <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.painRadiate === 'travelyes' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Yes</Text>
              </View>
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 4 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.painRadiate === 'travelno' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text>No (if yes, where?)</Text>
                  <Text
                    style={{
                      borderBottom: '1px dotted #000',
                      width: '100px',
                      paddingLeft: 2,
                    }}
                  >
                    {fillableData.otherTravel ? fillableData.otherTravel : ''}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text>
            <Text style={{ fontWeight: 'bold' }}>
              Do you have areas of numbness?
            </Text>
            {` `}
            <Text>(check one)</Text>
          </Text>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.trueFalseChoiceRow, gap: 6 }}>
              <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.areasNumbness === 'numbnessyes' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Yes</Text>
              </View>
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 4 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.areasNumbness === 'numbnessno' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text>No (if yes, where?)</Text>
                  <Text
                    style={{
                      borderBottom: '1px dotted #000',
                      width: '100px',
                      paddingLeft: 2,
                    }}
                  >
                    {fillableData.otherNumbness
                      ? fillableData.otherNumbness
                      : ''}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text>
            <Text style={{ fontWeight: 'bold' }}>
              Do you have areas of weakness
            </Text>
            {` `}
            <Text>(check one)</Text>
          </Text>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.trueFalseChoiceRow, gap: 6 }}>
              <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.areasWeakness === 'weaknessyes' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Yes</Text>
              </View>
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 4 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.areasWeakness === 'weaknesssno' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text>No (if yes, where?)</Text>
                  <Text
                    style={{
                      borderBottom: '1px dotted #000',
                      width: '100px',
                      paddingLeft: 2,
                    }}
                  >
                    {fillableData.otherWeakness
                      ? fillableData.otherWeakness
                      : ''}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>
            <Text style={{ fontWeight: 'bold' }}>The pain started:</Text>
            {` `}
            <Text>(check one)</Text>
          </Text>
          <View
            style={{
              paddingTop: 6,
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '6px',
            }}
          >
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painStarted === 'less6' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Less than 6 months ago</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painStarted === 'more6m' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>More than 6 months ago</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painStarted === 'more1y' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>More than 1 year ago</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painStarted === 'more5y' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>More than 5 years ago</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painStarted === 'more10y' && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>More than 10 years ago</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text>
            <Text style={{ fontWeight: 'bold' }}>
              Is this pain related to an accident?
            </Text>
            {` `}
            <Text>(check one)</Text>
          </Text>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.trueFalseChoiceRow, gap: 6 }}>
              <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.relatedAccident === 'accidentyes' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Yes</Text>
              </View>
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 4 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.relatedAccident === 'accidentno' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>No</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text>DOI:</Text>
                <Text
                  style={{
                    borderBottom: '1px dotted #000',
                    width: '110px',
                    paddingLeft: 2,
                  }}
                >
                  {fillableData.otherAccident ? fillableData.otherAccident : ''}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text>
            <Text style={{ fontWeight: 'bold' }}>
              Is this pain related to a work injury?
            </Text>
            {` `}
            <Text>(check one)</Text>
          </Text>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.trueFalseChoiceRow, gap: 6 }}>
              <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.relatedWork === 'workyes' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Yes</Text>
              </View>
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 4 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.relatedWork === 'workno' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>No</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text>DOI:</Text>
                <Text
                  style={{
                    borderBottom: '1px dotted #000',
                    width: '110px',
                    paddingLeft: 2,
                  }}
                >
                  {fillableData.otherWork ? fillableData.otherWork : ''}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text>
            <Text style={{ fontWeight: 'bold' }}>
              Is this pain related to a surgery?
            </Text>
            {` `}
            <Text>(check one)</Text>
          </Text>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.trueFalseChoiceRow, gap: 6 }}>
              <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.relatedSurgery === 'surgeryyes' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Yes</Text>
              </View>
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 4 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.relatedSurgery === 'surgeryno' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>No</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text>NOTE:</Text>
                <Text
                  style={{
                    borderBottom: '1px dotted #000',
                    width: '100px',
                    paddingLeft: 2,
                  }}
                >
                  {fillableData.otherSurgery ? fillableData.otherSurgery : ''}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <View
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '6px',
            }}
          >
            <Text>
              <Text style={{ fontWeight: 'bold' }}>
                The pain increases with:
              </Text>
              {` `}
              <Text>(check all that apply)</Text>
            </Text>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.painIncreases.includes('insit') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Sitting</Text>
            </View>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.painIncreases.includes('inwalk') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Walking</Text>
            </View>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.painIncreases.includes('instand') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Standing</Text>
            </View>

            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painIncreases.includes('inback') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Bending backward</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painIncreases.includes('infor') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Bending forward</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painIncreases.includes('inup') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Looking up</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painIncreases.includes('indown') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Looking down</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <View
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '6px',
            }}
          >
            <Text>
              <Text style={{ fontWeight: 'bold' }}>
                The pain decreases with:
              </Text>
              {` `}
              <Text>(check all that apply)</Text>
            </Text>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.painDecreases.includes('decrest') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Rest</Text>
            </View>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.painDecreases.includes('decmedic') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Medication</Text>
            </View>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.painDecreases.includes('decpro') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Procedures</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.painDecreases.includes('decexer') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Exercise/physical therapy</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <View
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '6px',
            }}
          >
            <Text>
              <Text style={{ fontWeight: 'bold' }}>Medications:</Text>
              {` `}
              <Text>(check one)</Text>
            </Text>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicaTions.includes('medhelp') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Do help relieve my pain</Text>
            </View>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicaTions.includes('mednot') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Do not help relieve my pain</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicaTions.includes('medeffect') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Cause side effects</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>
            <Text style={{ fontWeight: 'bold' }}>Medications tried:</Text>
            {` `}
            <Text>(check all that apply)</Text>
          </Text>
          <View
            style={{
              paddingTop: 8,
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'start',
              gap: '6px',
            }}
          >
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tIbuprofen') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Ibuprofen</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tTylenol') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Tylenol</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tNortriptyline') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Nortriptyline</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tAmitriptyline') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Amitriptyline</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tGabapentin ') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Gabapentin</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tLyrica') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Lyrica</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tFlexeril') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Flexeril</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tRobaxin') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Robaxin</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tZanaflex ') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Zanaflex</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tSkelaxin') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Skelaxin</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tHydrocodone') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Hydrocodone</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tOxycodone') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Oxycodone</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tOxycontin') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Oxycontin</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tMorphine') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Morphine</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tMS') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>MS Contin</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tMethadone') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Methadone</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tDilaudid') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Dilaudid</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tSuboxone') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Suboxone</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tNucynta') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Nucynta</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tFentanyl') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Fentanyl</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '75px',
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsTried.includes('tOpana') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Opana</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                width: '100%',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 4,
                }}
              >
                <Text>Other</Text>
                {` `}
                <Text
                  style={{
                    borderBottom: '1px dotted #000',
                    width: '100%',
                  }}
                >
                  {fillableData.otherMedications
                    ? fillableData.otherMedications
                    : ''}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <View
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '6px',
            }}
          >
            <Text>
              <Text style={{ fontWeight: 'bold' }}>
                Are you currently taking an anticoagulant?
              </Text>
              {` `}
              <Text>(check one)</Text>
            </Text>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.currentlyAnticoagulant.includes('antiyes') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Yes</Text>
            </View>
            <View style={{ ...styles.trueFalseChoicewidth, gap: 4 }}>
              <View style={styles.stylesCheckImage}>
                {fillableData?.currentlyAnticoagulant.includes('antino') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>No</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <View
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '6px',
              marginTop: '4px',
            }}
          >
            <Text style={{ marginTop: '-3px' }}>
              <Text style={{ fontWeight: 'bold' }}>
                Indicates options therapies tried:
              </Text>
              {` `}
              <Text>(Check all that apply)</Text>
            </Text>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.alternateFirst.includes('altNone') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>None</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.alternateFirst.includes('altPhysical') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Physical therapy</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.alternateFirst.includes('altMassage') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Massage</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.alternateFirst.includes('altAcupuncture') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Acupuncture</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.alternateFirst.includes('altChiropractic') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Chiropractic treatments</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.alternateFirst.includes('altTens') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>TENS unit</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.alternateFirst.includes('altPain') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Pain psychology/therapy</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <View
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '6px',
              marginTop: '4px',
            }}
          >
            <Text style={{ marginTop: '-3px' }}>
              <Text style={{ fontWeight: 'bold' }}>
                Procedures previously tried related to your current or past
                pain:
              </Text>
              {` `}
              <Text>(Check all that apply)</Text>
            </Text>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.alternateSecond.includes('altNone2') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>None</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.alternateSecond.includes('altEpidural') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Epidural steroid injection</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.alternateSecond.includes('altFacet') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Facet joint injection</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.alternateSecond.includes('altRadio') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Radiofrequency ablation</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.alternateSecond.includes('altTrigger') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Trigger point injections</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.alternateSecond.includes('altJoint') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Joint injection</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.alternateSecond.includes('altSpinal') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Spinal cord stimulator</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.alternateSecond.includes('altBotox') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Botox injection</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '2px solid #000',
            width: '100%',
            padding: '1px',
            marginTop: '6px',
          }}
        >
          <View
            style={{
              border: '1px solid #000',
              width: '100%',
              padding: '4px',
            }}
          >
            <Text style={{ fontWeight: 'bold' }}>Office Use Only:</Text>
            <View
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                paddingTop: 2,
                justifyContent: 'start',
              }}
            >
              <Text style={{ marginTop: '2px' }}>Heart Rate:</Text>
              {` `}
              <Text
                style={{
                  borderBottom: '1px dotted #000',
                  width: '60px',
                  marginTop: '2px',
                  textAlign: 'center',
                }}
              >
                {fillableData.officeHeart ? fillableData.officeHeart : ''}
              </Text>
              <Text style={{ marginTop: '2px' }}>Blood Pressure:</Text>
              {` `}
              <Text
                style={{
                  borderBottom: '1px dotted #000',
                  width: '60px',
                  marginTop: '2px',
                  textAlign: 'center',
                }}
              >
                {fillableData.officeBlood ? fillableData.officeBlood : ''}
              </Text>
              <Text style={{ marginTop: '2px' }}>Temp (F):</Text>
              {` `}
              <Text
                style={{
                  borderBottom: '1px dotted #000',
                  width: '60px',
                  marginTop: '2px',
                  textAlign: 'center',
                }}
              >
                {fillableData.officeTemp ? fillableData.officeTemp : ''}
              </Text>
              <Text style={{ marginTop: '2px' }}>Pulse:</Text>
              {` `}
              <Text
                style={{
                  borderBottom: '1px dotted #000',
                  width: '60px',
                  marginTop: '2px',
                  textAlign: 'center',
                }}
              >
                {fillableData.officePulse ? fillableData.officePulse : ''}
              </Text>
              <Text style={{ marginTop: '2px' }}>Ox (%):</Text>
              {` `}
              <Text
                style={{
                  borderBottom: '1px dotted #000',
                  width: '60px',
                  marginTop: '2px',
                  textAlign: 'center',
                }}
              >
                {fillableData.officeOx ? fillableData.officeOx : ''}
              </Text>
              <Text style={{ marginTop: '2px' }}>Resp:</Text>
              {` `}
              <Text
                style={{
                  borderBottom: '1px dotted #000',
                  width: '60px',
                  marginTop: '2px',
                  textAlign: 'center',
                }}
              >
                {fillableData.officeResp ? fillableData.officeResp : ''}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default RecruitingPatientsFirstForm
