import { Image, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

function SoapNonWebTwo({ fillableData, styles, stylesSoap }) {
  return (
    <View style={{ ...styles.gapBox }}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 8,
        }}
      >
        <Image
          style={{ width: '110px', objectFit: 'cover' }}
          src="/paincare-web-v2.png"
        />
        <View style={styles.boxtitle}>
          <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
        </View>
        <View style={styles.boxtitle}>
          <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
        </View>
      </View>
      <View
        style={{
          width: '450px',
          gap: '4px',
        }}
      >
        <Text></Text>
        <View
          style={{
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            width: '450px',
            paddingTop: '14px',
          }}
        >
          <View style={{ ...styles.table, width: '450px' }}>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  padding: 30,
                  paddingTop: 8,
                  paddingBottom: 8,
                  width: '100%',
                  gap: 6,
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold', width: '40px' }}>
                    Name:
                  </Text>
                  {` `}
                  <Text style={{ ...styles.paddingLeftFormData }}>
                    {fillableData.namePatient ? fillableData.namePatient : ''}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row', paddingTop: 6 }}>
                  <Text style={{ fontWeight: 'bold', width: '80px' }}>
                    Date of Birth:
                  </Text>
                  {` `}
                  <Text style={{ ...styles.paddingLeftFormData }}>
                    {fillableData?.DOB
                      ? moment(fillableData?.DOB).format('MM/DD/YYYY')
                      : ''}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  height: '62px',
                  backgroundColor: '#dddddd',
                }}
              >
                <Text style={stylesSoap.subBoxTitle}>Never</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text style={stylesSoap.subBoxTitle}>Seldom</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text style={stylesSoap.subBoxTitle}>Sometimes</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text style={stylesSoap.subBoxTitle}>Often</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text style={stylesSoap.subBoxTitle}>Very Often</Text>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  gap: 6,
                }}
              >
                <Text></Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text>0</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text>1</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text>2</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text>3</Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  backgroundColor: '#dddddd',
                }}
              >
                <Text>4</Text>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>13.</Text>
                <Text>
                  How often have any of your close friends had a problem with
                  alcohol or drugs?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howClose === 'Zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howClose === 'One' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howClose === 'Two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howClose === 'Three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howClose === 'Four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>14.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have others told you that you had a bad temper?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howTold === 'Zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howTold === 'One' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howTold === 'Two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howTold === 'Three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howTold === 'Four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>15.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have you felt consumed by the need to get pain
                  medication?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howConsumed === 'Zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howConsumed === 'One' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howConsumed === 'Two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howConsumed === 'Three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howConsumed === 'Four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>16.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have you run out of pain medication early?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howEarly === 'Zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howEarly === 'One' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howEarly === 'Two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howEarly === 'Three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howEarly === 'Four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>17.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have others kept you from getting what you deserve?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howKept === 'Zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howKept === 'One' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howKept === 'Two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howKept === 'Three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howKept === 'Four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>18.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often, in your lifetime, have you had legal problems or
                  been arrested?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howLifetime === 'Zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howLifetime === 'One' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howLifetime === 'Two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howLifetime === 'Three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howLifetime === 'Four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>19.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have you attended an AA or NA meeting?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howAttended === 'Zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howAttended === 'One' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howAttended === 'Two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howAttended === 'Three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howAttended === 'Four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>20.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have you been in an argument that was so out of
                  control that someone got hurt?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howArgument === 'Zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howArgument === 'One' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howArgument === 'Two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howArgument === 'Three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howArgument === 'Four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>21.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have you been sexually abused?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howSexually === 'Zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howSexually === 'One' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howSexually === 'Two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howSexually === 'Three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howSexually === 'Four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>22.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have others suggested that you have a drug or
                  alcohol problem?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howSuggested === 'Zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howSuggested === 'One' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howSuggested === 'Two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howSuggested === 'Three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howSuggested === 'Four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '20px' }}>23.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have you had to borrow pain medications from your
                  family or friends?
                </Text>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howBorrow === 'Zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howBorrow === 'One' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howBorrow === 'Two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howBorrow === 'Three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...stylesSoap.boxBorder, paddingTop: 8 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howBorrow === 'Four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                margin: 'auto',
                flexDirection: 'row',
                width: '450px',
              }}
            >
              <View
                style={{
                  ...stylesSoap.boxBorderTitle,
                  width: '100%',
                  flexDirection: 'row',
                  borderBottom: 1,
                }}
              >
                <Text style={{ width: '20px' }}>24.</Text>
                <Text style={{ paddingRight: 18 }}>
                  How often have you been treated for an alcohol or drug
                  problem?
                </Text>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  paddingTop: 8,
                  borderBottom: 1,
                }}
              >
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howAlcohol === 'Zero' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  paddingTop: 8,
                  borderBottom: 1,
                }}
              >
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howAlcohol === 'One' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  paddingTop: 8,
                  borderBottom: 1,
                }}
              >
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howAlcohol === 'Two' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  paddingTop: 8,
                  borderBottom: 1,
                }}
              >
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howAlcohol === 'Three' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
              <View
                style={{
                  ...stylesSoap.boxBorder,
                  paddingTop: 8,
                  borderBottom: 1,
                }}
              >
                <View style={styles.stylesCheckImage}>
                  {fillableData?.howAlcohol === 'Four' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
              </View>
            </View>
          </View>
        </View>
        <Text style={{ paddingTop: 8 }}>
          Please include any additional information you wish about the above
          answers.
        </Text>
        <Text style={{ paddingTop: 2 }}>Thank you.</Text>
        <Text style={{ paddingTop: 12, lineHeight: 1.5, textAlign: 'justify' }}>
          ©2014 Inflexxion, Inc. Permission granted solely for use in published
          format by individual practitioners in clinical practice. No other uses
          or alterations are authorized or permitted by copyright holder.
          Permissions questions:{' '}
          <Text style={{ borderBottom: 1 }}>PainEDU@inflexxion.com.</Text> The
          SOAPP®-R was developed with a grant from the National Institutes of
          Health and an educational grant from Endo Pharmaceuticals.
        </Text>
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '450px',
            paddingTop: 24,
          }}
        >
          <Image
            style={{ width: '170px', objectFit: 'cover' }}
            src="/edu-logo.png"
          />
        </View>
      </View>
    </View>
  )
}

export default SoapNonWebTwo
