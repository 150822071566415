import { Text, View } from '@react-pdf/renderer'
import React from 'react'

function SignatureCancellation() {
  return (
    <View style={{ gap: '10px', width: '100%' }}>
      <View style={{ width: '100%', textAlign: 'start' }}>
        <Text style={{ fontWeight: 'bold' }}>
          CANCELLATION AND NO SHOW POLICY FOR PROCEDURES
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text>
            Please carefully consider your procedure date before scheduling.
            When rescheduling has to occur, the scheduling process adds to the
            administrative expenses for the clinic.
          </Text>
          {` `}
          <Text>
            If you fail to check in for your procedure, or if you cancel the
            procedure with less than 24 hours' notice, you will be subjected to
            a $200.00 fee, and this fee is not billable to insurance. You may be
            dismissed from the clinic if you cancel or "no show" your
            appointment less than 24 hours of your scheduled time more than
            twice per year.
          </Text>
        </Text>
      </View>
      <View style={{ paddingTop: 10 }}>
        <Text style={{ fontWeight: 'bold' }}>
          PAIN CARE SPECICALISTS OF OREGON URINE DRUG TESTING POLICY
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          Opioid pain medications (also known as opiate or narcotic pain
          medications) are a potentially valuable source of relief for those
          suffering from chronic pain. They are also dangerous substances that
          carry the risk of bowel obstruction, liver failure, kidney failure,
          cardiac arrhythmia, respiratory depression and death. These
          medications are some of the most potentially addictive substances. Use
          of these medications carries risk of dependence, tolerance,
          withdrawals, increased pain with chronic use, depression and anxiety.
          The use of these substances should not be taken lightly.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          Pain Care Specialists of Oregon (PCOS) providers will use a
          combination of factors to determine if a patient is a good candidate
          for opioid therapy. These factors include the patient's medical,
          surgical and family history. The patient will be given assessments
          such as the Opioid Risk Tool and the Oswestry Disability Index to make
          sure that the opioid therapy is appropriately treating the patient's
          pain.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          Mixing opioid pain medications with other controlled substances can be
          extremely dangerous. Substances such as benzodiazepines, medical
          marijuana, alcohol and Soma/carisoprodol can increase the risk of
          complications or death in an opioid patient by up to 100%. PCSO may
          limit or discontinue opioid therapy in patient's taking
          benzodiazepines or Soma/carisoprodol.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          Patients who are treated with opioid pain medications at PCSO will
          sign an opioid agreement. PCSO will provide no more than a 28-day
          supply of any opioid medication, although exceptions may be made at
          the discretion of the prescribing provider given very specific
          circumstances and discussed with colleagues. All patients are required
          to be physically present at the clinic to obtain a script. All new
          patients taking an opioid or benzodiazepine will be required to
          provide a urine drug test sample. This sample will undergo
          confirmation testing. If patients are unable to provide a urine
          sample, we may collect a saliva or blood sample instead. Blood or
          saliva samples may be sent to a specialty lab where additional charges
          may occur. Patient drug testing will be based on the PCSO Urine Drug
          Testing Algorithm and proprietary Patient Randomizing Software. Urine
          testing may occur at clinic visit and/or walk-in testing. Patients who
          are informed that a urine sample is required will have3 days to
          provide the urine sample. Some patients at high doses and high risk
          may be required to have two (2) urine drug tests within a 30-day
          period. Patients may be required to provide random urine drug test
          samples, cognitive testing, pharmacy reports and pill counts at any
          time. These guidelines promote safe and appropriate patient medication
          use. You will be responsible for all bills associated with these
          required tests per your insurance benefits.
        </Text>
      </View>
      <View>
        <Text style={{ fontWeight: 'bold' }}>
          INFORMED CONSENT FORM PATIENT FOR POST EXPOSURE LABORATORY TESTS
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          Pain Care Specialists of Oregon, LLC is requesting your permission to
          have your blood drawn and tested for Hepatitis B Surface Antigen,
          Hepatitis C Surface Antigen, and Human Immunodeficiency Virus in case
          an employee or physician is accidently exposed to your blood or
          contaminated body fluids. In order to establish if our employee or
          physician has been exposed to one of these infectious diseases, the
          Occupational Safety and Health Administration (OSHA) requires
          permission from you to perform these tests.
        </Text>
      </View>
      <View>
        <Text>You will not be financially obligated for these tests.</Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          The records of the test results will be kept confidential and are not
          disclosed without your written consent to any person within or outside
          the Center except as required by law. A copy will be provided to you
          at your request.
        </Text>
      </View>
    </View>
  )
}

export default SignatureCancellation
