import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
    Flex,
    Box,
    Container,
    Spacer,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    Center,
    ButtonGroup,
    Select,
    Stack,
    Text,
    Textarea,
    FormControl,
    FormErrorMessage,
    useToast
} from '@chakra-ui/react'

import { ChevronRightIcon } from '@chakra-ui/icons'
import { BsViewStacked } from 'react-icons/bs'
import { BiAddToQueue } from 'react-icons/bi'
import { doc, updateDoc, onSnapshot, query, where, orderBy, collection } from 'firebase/firestore'
import { db } from '../../config/firebase'
import renderDate from '../../config/renderDate'


function Appointment({ user }) {

    const [sechedule, setSchedule] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [viewData, setViewData] = useState(null)
    const [status, setStatus] = useState("Pending")
    const params = useParams()
    const toast = useToast()
    const { handleSubmit, reset, register, errors } = useForm()


    useEffect(() => {
        if (params.id) {
            onSnapshot(doc(db, "Appointment", params.id), (snapShot) => {
                if (snapShot.exists) {
                    let data = snapShot.data()
                    setViewData({ ...data, id: snapShot.id })
                }
            })
        } else {
            if (status !== "All") {
                const queryAppointment = query(collection(db, "Appointment"), where("status", "==", status), orderBy("createdAt", "desc"))
                onSnapshot(queryAppointment, (snapShot) => {
                    let data = []
                    snapShot.forEach((doc) => {
                        let queryData = doc.data()
                        data.push({ ...queryData, id: doc.id })
                    })
                    setSchedule(data)
                    setLoading(false)
                })
            } else {
                const queryAppointment = query(collection(db, "Appointment"), orderBy("createdAt", "desc"))
                onSnapshot(queryAppointment, (snapShot) => {
                    let data = []
                    snapShot.forEach((doc) => {
                        let queryData = doc.data()
                        data.push({ ...queryData, id: doc.id })
                    })
                    setSchedule(data)
                    setLoading(false)
                })
            }

        }

        return () => {
            console.log('unmount')
            setViewData(null)
        }
    }, [params, status])

    function changeStatus(appointmentId, status) {
        setViewData({ ...viewData, status: status })
        const prevMemo = viewData.memo || []
        const updateAppointment = doc(db, "Appointment", appointmentId)
        updateDoc(updateAppointment, { status: status, notification: false, memo: [...prevMemo, { createdAt: new Date(), message: `Change status to ${status}`, createdBy: user.fullName }] })
            .catch((e) => {
                toast({
                    title: "Change status error",
                    description: e.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                })
            })
    }

    function submitMemo({ memo }) {
        const updateAppointment = doc(db, "Appointment", params.id)
        const prevMemo = viewData.memo || []
        updateDoc(updateAppointment, { memo: [...prevMemo, { createdAt: new Date(), message: memo, createdBy: user?.fullName || 'N/A' }] })
            .then(() => {
                toast({
                    title: "Add Memo",
                    description: "Add memo successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
            })
            .catch((e) => {
                toast({
                    title: 'Add Memo Error',
                    description: e.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                })
            })
        reset()
    }

    return (
        <Container maxW="100%" mt="5">
            <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="gray.500" />}
                bg="gray.100" p="1.5"
                borderRadius="0.3em"
            >
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/dashboard">Dashbaord</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/appointment">Appointment</BreadcrumbLink>
                </BreadcrumbItem>
                {
                    params.id && viewData ?
                        <BreadcrumbItem>
                            <BreadcrumbLink>{viewData.firstName} {viewData.lastName}</BreadcrumbLink>
                        </BreadcrumbItem>
                        :
                        params.id &&
                        <BreadcrumbItem>
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xs"
                            />
                        </BreadcrumbItem>
                }
            </Breadcrumb>
            {
                params.id ?
                    <>
                        {viewData ?
                            <Box mt="5" mb="20">
                                <Box textAlign="right" mt="5" mb="5">
                                    <ButtonGroup size="sm" isAttached>
                                        <Button colorScheme="green" variant={viewData.status === "Acknowledged" ? 'solid' : 'outline'} mr="-px" onClick={() => changeStatus(viewData.id, "Acknowledged")}>Acknowledged</Button>
                                        <Button colorScheme="blue" variant={viewData.status === "Pending" ? 'solid' : 'outline'} mr="-px" onClick={() => changeStatus(viewData.id, "Pending")}>Pending</Button>
                                        <Button colorScheme="red" variant={viewData.status === "Archived" ? 'solid' : 'outline'} mr="-px" onClick={() => changeStatus(viewData.id, "Archived")}>Archived</Button>
                                    </ButtonGroup>
                                </Box>
                                <Stack direction={["column", "column", "column", "row"]} spacing="10">
                                    <Box width="50%">
                                        <Center mb="5"><Text fontSize="2xl">Appointment</Text></Center>
                                        <Table>
                                            <Tbody>
                                                <Tr>
                                                    <Td textAlign="right">Request on :</Td><Td>{renderDate(viewData.createdAt)}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td textAlign="right">Patient Type :</Td><Td>{viewData.patientType}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td textAlign="right">Full name :</Td><Td>{viewData.firstName} {viewData.lastName}</Td>
                                                </Tr>
                                                {
                                                    viewData.patientType === "New Patient" &&
                                                    <Tr>
                                                        <Td textAlign="right">Date of Birth :</Td><Td>{viewData.dob}</Td>
                                                    </Tr>
                                                }
                                                <Tr>
                                                    <Td textAlign="right">Telephone :</Td><Td>{viewData.telephone}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td textAlign="right">Email :</Td><Td>{viewData.email}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td textAlign="right">Chief Pain Complaint :</Td><Td>{viewData.chiefPainComplaint}</Td>
                                                </Tr>
                                                {
                                                    viewData.patientType === "New Patient" &&
                                                    <>
                                                        <Tr>
                                                            <Td textAlign="right">Insurance :</Td><Td>{viewData.insurance}</Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td textAlign="right">Hear about us from :</Td><Td>{viewData.howDidYouHereAboutUs}</Td>
                                                        </Tr>
                                                    </>
                                                }
                                                <Tr>
                                                    <Td textAlign="right">Primary Location :</Td><Td>{viewData.primaryLocation ? viewData.primaryLocation : 'N/A'}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td textAlign="right">Secondary Location :</Td><Td>{viewData.secondaryLocation ? viewData.secondaryLocation : 'N/A'}</Td>
                                                </Tr>
                                                {
                                                    viewData.message && <Tr>
                                                        <Td textAlign="right">Message :</Td><Td>{viewData.message}</Td>
                                                    </Tr>
                                                }

                                            </Tbody>
                                        </Table>
                                    </Box>
                                    <Box w={["100%", "100%", "100%", "50%"]} mb="30">
                                        <Center mb="5"><Text fontSize="2xl">Memo</Text></Center>
                                        <Box w="100%" borderRadius="0.4em" mb="5" height="300" p="5" mt="5" overflow="scroll" backgroundColor="gray.50">
                                            {
                                                viewData?.memo?.sort((a, b) => b.createdAt - a.createdAt).map((value, index) => {
                                                    return (
                                                        <Box fontSize="xs" key={index} mb="2">
                                                            {renderDate(value.createdAt)} : {value.message} by {value.createdBy}
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                        <form onSubmit={handleSubmit(submitMemo)}>
                                            <FormControl isInvalid={errors.memo}>
                                                <Textarea name="memo" rows="5" placeholder="Enter note ..." ref={register({ required: "Please input note to add." })} />
                                                <FormErrorMessage>{errors.memo && errors.memo.message}</FormErrorMessage>
                                            </FormControl>
                                            <Box mt="5" width="100%" textAlign="right">
                                                <Button type="submit" colorScheme="blue" w={["100%", "100%", "100%", "100px"]} leftIcon={<BiAddToQueue />}>Add</Button>
                                            </Box>
                                        </form>
                                    </Box>
                                </Stack>
                            </Box> :
                            <Center>
                                <Spinner
                                    mt="10"
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                    size=
                                    "xl"
                                />
                            </Center>
                        }

                    </>
                    :
                    <>
                        <Flex mt="5">
                            <Box pt="2" fontSize="18" fontWeight="bold">
                                Appointment
                            </Box>
                            <Spacer />
                            <Box>
                                <Select name="status" onChange={(e) => setStatus(e.target.value)} defaultValue={status}>
                                    <option>All</option>
                                    <option>Pending</option>
                                    <option>Acknowledged</option>
                                    <option>Archived</option>
                                </Select>
                            </Box>
                        </Flex>
                        <Table size="sm" mt="10">
                            <Thead>
                                <Tr>
                                    <Th>Date</Th>
                                    <Th>Patient's Name</Th>
                                    <Th>Category</Th>
                                    <Th>Status</Th>
                                    <Th textAlign="center">Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    isLoading ?
                                        <Tr>
                                            <Td colSpan="4" textAlign="center">
                                                <Spinner
                                                    thickness="4px"
                                                    speed="0.65s"
                                                    emptyColor="gray.200"
                                                    color="blue.500"
                                                    size=
                                                    "xl"
                                                />
                                            </Td>
                                        </Tr>
                                        :
                                        sechedule.map((patient, index) => {
                                            return (
                                                <Tr key={index}>
                                                    <Td>{renderDate(patient.createdAt)}</Td>
                                                    <Td>{patient.firstName} {patient.lastName}</Td>
                                                    <Td>{patient.patientType}</Td>
                                                    <Td>{patient.status}</Td>
                                                    <Td textAlign="center">
                                                        <Button size="xs" leftIcon={<BsViewStacked />} as={Link} to={`appointment/${patient.id}`}>View</Button>
                                                    </Td>
                                                </Tr>
                                            )
                                        })
                                }
                            </Tbody>
                        </Table>
                    </>
            }
        </Container>
    )
}

export default Appointment