import { Image, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

function PainCareSpecialists({ fillableData, styles }) {
  return (
    <View style={{ gap: '10px', width: '100%' }}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 8,
        }}
      >
        <Image
          style={{ width: '110px', objectFit: 'cover' }}
          src="/paincare-web-v2.png"
        />
        <View style={styles.boxtitle}>
          <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
        </View>
        <View style={styles.boxtitle}>
          <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
        </View>
      </View>
      <View style={{ width: '100%', textAlign: 'center', marginTop: '4px' }}>
        <Text style={{ fontWeight: 'bold' }}>
          PAIN CARE SPECIALISTS OF OREGON, LLC, HIPAA CONSENT
        </Text>
      </View>
      <View style={{ marginTop: '-2px' }}>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          By signing this Consent form, you give Pain Care Specialists of
          Oregon, LLC, (PCSO) permission to use and disclose protected health
          information about you for treatment, payment, and healthcare operation
          except for any restrictions specified in the 'Form to Request
          Restrictions'. Protected health information is individually
          identifiable information created or received by the practice,
          including demographic data, information relating to you physical or
          mental health, to provision of healthcare services to you, and to the
          collection of payment for providing healthcare services to you.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          If the information to be disclosed contains any of the type of records
          or information for drug/alcohol diagnosis, treatment, or referral
          information, mental health information, genetic testing information,
          and HIV/AIDS information, additional laws relating to the use and
          disclosure of the information may apply. As the patient, you have the
          right to request restrictions in the use of your protected health
          information and to request change in certain policies used within the
          office concerning your PHI. However, we are not obligated to alter
          internal policies to conform to your request.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          The PCSO Notice of Privacy Practices provides information about how
          PCSO may use and disclose protected health information about you. A
          copy of this notice of Privacy Practices is available to you for
          review prior to you signing the Consent Form. As referenced in the
          Notice, the terms of the Notice of Privacy Practice may be changed
          periodically. Copies of the most current Notice will be available at
          your request upon check-in for you appointment or by accessing the
          practice's website at www.paincareoregon.com. Should you have any
          questions or concerns about the handling of your protected health
          information, you may contact the PCSO Privacy Officer at (503)
          371-1010.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          You have the right to request restriction on the use or disclosure of
          you protected health information for treatment, payment, or healthcare
          operations. If you request for restriction is accepted, then the
          practice is bound to honor that agreement.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          With this consent, PCSO may call your home or alternative location and
          leave a message or voicemail or with an individual in reference to any
          items that assist the practice in fulfilling TOP, such as appointment
          reminders, insurance items, and any call pertaining to your clinical
          care, including test results. With this consent, PCSO may mail to your
          home or alternative location any items that assist the practice in
          fulfilling TPO, such as appointment reminder cards and patient
          statements.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          To the extent available, PCSO may attempt to electronically obtain
          your prescription medication history through your insurance provider,
          and or prescription benefits service. By signing this Consent form,
          you consent to any electronic download of said information which may
          be useful to your treatment.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          If you elect not to sign this Consent Form, PCSO has the right to
          refuse you treatment unless a licensed healthcare professional has
          determined that you require emergency treatment, or the physician is
          required by law to treat you. The practice is required to document any
          circumstances in which treatment is provided without your consent; a
          copy of this documentation would be available to you.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          You have the right to revoke this consent in writing unless disclosure
          have been made based upon your prior consent. To request your
          revocation, you may use the Authorization for Release of Information
          Form or you may submit a letter to the practice.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          I acknowledge that Pain Care Specialists of Oregon, LLC has offered a
          copy of the NOPP, Notice of Privacy Practices, which includes the
          updated HIPAA OMNIBUS rule.
        </Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '70px',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            paddingTop: '4px',
            alignItems: 'flex-end',
            paddingBottom: '2px',
          }}
        >
          {fillableData.signaturePatient.includes('data:image/') ? (
            <Image
              style={{
                width: '125px',
                objectFit: 'cover',
              }}
              src={fillableData.signaturePatient}
            />
          ) : (
            <Text style={{ width: '125px', textAlign: 'center' }}>
              {fillableData.signaturePatient}
            </Text>
          )}
          <Text style={{ width: '150px', textAlign: 'center' }}>
            {fillableData?.createdAt
              ? moment(fillableData?.createdAt).format('MM/DD/YYYY')
              : ''}
          </Text>
        </View>
        <View
          style={{
            borderTop: '1px dotted #000',
            width: '100%',
          }}
        ></View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            paddingTop: '6px',
          }}
        >
          <Text>Signature of Patient or Legel Representative</Text>
          <Text style={{ width: '150px', textAlign: 'center' }}>Date</Text>
        </View>
      </View>
    </View>
  )
}

export default PainCareSpecialists
