import React from 'react'
import { Box, Button, ButtonGroup, HStack, Heading } from '@chakra-ui/react'
import {
  PDFViewer,
  StyleSheet,
  Document,
  Page,
  View,
  Text,
  Svg,
  Circle,
  Image,
} from '@react-pdf/renderer'
import moment from 'moment'
import { oswestryQuestions } from '../form/formODQ'

const styles = StyleSheet.create({
  pdfView: {
    width: '100%',
    height: window.innerHeight,
    backgroundColor: '#fff',
    fontFamily: 'Open Sans',
  },
  page: {
    width: '100%',
    flexDirection: 'column',
    backgroundColor: '#fff',
    alignItems: 'center',
    padding: '20px 40px',
  },
  sectionImg: {
    width: '100%',
    padding: 0,
    alignItems: 'center',
  },
  img: {
    width: '150px',
    height: 'auto',
    objectFit: 'cover',
  },
  boxHeader: {
    width: '100%',
    alignItems: 'center',
    margin: '20px 0px',
  },
  textHeader: {
    textAlign: 'center',
  },
  sectionInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  boxDate: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '10px',
    marginBottom: '10px',
  },
  boxInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '10px',
  },
  textNameAndDate: {
    fontSize: '10px',
    borderBottom: 1,
    textAlign: 'center',
    width: '150px',
  },
  textHelper: {
    fontSize: '10px',
  },
  sectionTable: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  boxTopLeft: {
    width: '50%',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    padding: '1px 16px 12px 6px',
  },
  boxBottomLeft: {
    width: '50%',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    padding: '1px 16px 12px 6px',
  },
  boxTopRight: {
    width: '50%',
    borderTop: 1,
    borderRight: 1,
    padding: '1px 16px 1px 6px',
  },
  boxBottomRight: {
    width: '50%',
    borderTop: 1,
    borderRight: 1,
    borderBottom: 1,
    padding: '1px 16px 1px 6px',
  },
  svg: {
    width: '15px',
    height: '10px',
  },
  boxAnswer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  },
  textQuestion: {
    fontSize: '10px',
    marginBottom: '10px',
  },
  textAnswer: {
    fontSize: '9px',
    marginBottom: '2px',
  },
  boxScore: {
    width: '100%',
    paddingLeft: '20px',
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'row',
  },
  textTitleScore: {
    fontSize: '12px',
    textAlign: 'start',
  },
  textScore: {
    fontSize: '12px',
    borderBottom: 1,
    textAlign: 'center',
    width: '100px',
  },
  boxCheck: {
    position: 'absolute',
    top: -3.5,
    left: 4,
  },
  imgcheck: {
    width: '10px',
    height: '10px',
  },
})

export default function RenderODQPDF({ formData, changeStatus }) {
  const checked = (key, value) => {
    if (key.toString() !== value) return <></>
    return <Image style={styles.imgcheck} src="/check.png" />
  }

  return (
    <Box mt="5">
      <Box textAlign="right" mt="5">
        <HStack justifyContent="space-between" mb={8}>
          <Box>
            <Heading as="h4" size="md" textAlign="start">
              Date : {moment(formData?.date).format('MM/DD/YYYY')}
            </Heading>
            <Heading as="h4" size="md" textAlign="start">
              Name : {formData.name || ''}
            </Heading>
          </Box>
          <HStack spacing={'2rem'}>
            <ButtonGroup size="sm" isAttached>
              <Button
                colorScheme="green"
                variant={formData?.status === 'Archived' ? 'solid' : 'outline'}
                mr="-px"
                onClick={() => changeStatus(formData?.id, 'Archived')}
              >
                Archived
              </Button>
            </ButtonGroup>
          </HStack>
        </HStack>
      </Box>
      <PDFViewer style={styles.pdfView}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.sectionImg}>
              <Image style={styles.img} src="/paincare-web.png" />
            </View>
            <View style={styles.boxHeader}>
              <Text style={styles.textHeader}>
                Oswestry Disability Questionnaire
              </Text>
            </View>
            <View style={styles.boxDate}>
              <Text>Date :</Text>
              <Text style={styles.textNameAndDate}>
                {moment(formData?.date).format('MM/DD/YYYY')}
              </Text>
            </View>
            <View style={styles.sectionInfo}>
              <View style={styles.boxInfo}>
                <Text>Name :</Text>
                <Text style={styles.textNameAndDate}>
                  {formData?.name || '-'}
                </Text>
              </View>
              <View style={styles.boxInfo}>
                <Text>Date of birth :</Text>
                <Text style={styles.textNameAndDate}>
                  {formData?.dateOfBirth
                    ? moment(formData?.dateOfBirth).format('MM/DD/YYYY')
                    : '='}
                </Text>
              </View>
            </View>
            <View style={styles.sectionInfo}>
              <Text style={styles.textHelper}>
                This questionnaire has been designed to give us information as
                to how your pain is affecting your ability to manage in everyday
                life. Please answer by checking one box in each section for the
                statement which applies to you. Thank you.
              </Text>
            </View>
            <View style={styles.sectionTable}>
              <View style={styles.boxTopLeft}>
                <Text style={styles.textQuestion}>
                  {oswestryQuestions[0].question}
                </Text>
                {oswestryQuestions[0].answers.map((item, index) => (
                  <View key={index} style={styles.boxAnswer}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <Text style={styles.textAnswer}>{item}</Text>
                    <View style={styles.boxCheck}>
                      {checked(index, formData.answers[0])}
                    </View>
                  </View>
                ))}
              </View>
              <View style={styles.boxTopRight}>
                <Text style={styles.textQuestion}>
                  {oswestryQuestions[5].question}
                </Text>
                {oswestryQuestions[5].answers.map((item, index) => (
                  <View key={index} style={styles.boxAnswer}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <Text style={styles.textAnswer}>{item}</Text>
                    <View style={styles.boxCheck}>
                      {checked(index, formData.answers[5])}
                    </View>
                  </View>
                ))}
              </View>
            </View>
            <View style={styles.sectionTable}>
              <View style={styles.boxTopLeft}>
                <Text style={styles.textQuestion}>
                  {oswestryQuestions[1].question}
                </Text>
                {oswestryQuestions[1].answers.map((item, index) => (
                  <View key={index} style={styles.boxAnswer}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <Text style={styles.textAnswer}>{item}</Text>
                    <View style={styles.boxCheck}>
                      {checked(index, formData.answers[1])}
                    </View>
                  </View>
                ))}
              </View>
              <View style={styles.boxTopRight}>
                <Text style={styles.textQuestion}>
                  {oswestryQuestions[6].question}
                </Text>
                {oswestryQuestions[6].answers.map((item, index) => (
                  <View key={index} style={styles.boxAnswer}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <Text style={styles.textAnswer}>{item}</Text>
                    <View style={styles.boxCheck}>
                      {checked(index, formData.answers[6])}
                    </View>
                  </View>
                ))}
              </View>
            </View>
            <View style={styles.sectionTable}>
              <View style={styles.boxTopLeft}>
                <Text style={styles.textQuestion}>
                  {oswestryQuestions[2].question}
                </Text>
                {oswestryQuestions[2].answers.map((item, index) => (
                  <View key={index} style={styles.boxAnswer}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <Text style={styles.textAnswer}>{item}</Text>
                    <View style={styles.boxCheck}>
                      {checked(index, formData.answers[2])}
                    </View>
                  </View>
                ))}
              </View>
              <View style={styles.boxTopRight}>
                <Text style={styles.textQuestion}>
                  {oswestryQuestions[7].question}
                </Text>
                {oswestryQuestions[7].answers.map((item, index) => (
                  <View key={index} style={styles.boxAnswer}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <Text style={styles.textAnswer}>{item}</Text>
                    <View style={styles.boxCheck}>
                      {checked(index, formData.answers[7])}
                    </View>
                  </View>
                ))}
              </View>
            </View>
            <View style={styles.sectionTable}>
              <View style={styles.boxTopLeft}>
                <Text style={styles.textQuestion}>
                  {oswestryQuestions[3].question}
                </Text>
                {oswestryQuestions[3].answers.map((item, index) => (
                  <View key={index} style={styles.boxAnswer}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <Text style={styles.textAnswer}>{item}</Text>
                    <View style={styles.boxCheck}>
                      {checked(index, formData.answers[3])}
                    </View>
                  </View>
                ))}
              </View>
              <View style={styles.boxTopRight}>
                <Text style={styles.textQuestion}>
                  {oswestryQuestions[8].question}
                </Text>
                {oswestryQuestions[8].answers.map((item, index) => (
                  <View key={index} style={styles.boxAnswer}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <Text style={styles.textAnswer}>{item}</Text>
                    <View style={styles.boxCheck}>
                      {checked(index, formData.answers[8])}
                    </View>
                  </View>
                ))}
              </View>
            </View>
            <View style={styles.sectionTable}>
              <View style={styles.boxBottomLeft}>
                <Text style={styles.textQuestion}>
                  {oswestryQuestions[4].question}
                </Text>
                {oswestryQuestions[4].answers.map((item, index) => (
                  <View key={index} style={styles.boxAnswer}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <Text style={styles.textAnswer}>{item}</Text>
                    <View style={styles.boxCheck}>
                      {checked(index, formData.answers[4])}
                    </View>
                  </View>
                ))}
              </View>
              <View style={styles.boxBottomRight}>
                <Text style={styles.textQuestion}>
                  {oswestryQuestions[9].question}
                </Text>
                {oswestryQuestions[9].answers.map((item, index) => (
                  <View key={index} style={styles.boxAnswer}>
                    <Svg style={styles.svg}>
                      <Circle cx="6" cy="5" r="2" fill="white" stroke="gray" />
                    </Svg>
                    <Text style={styles.textAnswer}>{item}</Text>
                    <View style={styles.boxCheck}>
                      {checked(index, formData.answers[9])}
                    </View>
                  </View>
                ))}
              </View>
            </View>
            <View style={styles.boxScore}>
              <Text style={styles.textTitleScore}>Score</Text>
              <Text style={styles.textScore}>{formData.total}</Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </Box>
  )
}
