import { Image, Text, View } from '@react-pdf/renderer'
import React from 'react'

function NoticePrivacyPracticesNonWeb({ styles }) {
  return (
    <View style={{ ...styles.gapBox }}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 8,
        }}
      >
        <Image
          style={{ width: '110px', objectFit: 'cover' }}
          src="/paincare-web-v2.png"
        />
        <View style={styles.boxtitle}>
          <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
        </View>
        <View style={styles.boxtitle}>
          <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
        </View>
        <View style={{ paddingTop: 24, fontSize: 12 }}>
          <Text>NOTICE OF PRIVACY PRACTICES</Text>
        </View>
      </View>
      <View
        style={{
          width: '100%',
          gap: '12px',
          marginTop: '-8px',
        }}
      >
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          This notice describes how you can get access to your medical
          information and how medical information about you may be used and
          disclosed.
        </Text>
        <View>
          <Text style={{ fontWeight: 'bold' }}>
            OUR COMMITMENT TO YOUR PRIVACY
          </Text>
          <Text
            style={{ lineHeight: 1.25, paddingTop: 2, textAlign: 'justify' }}
          >
            Our practice is dedicated to maintaining the privacy of your
            protected health information (PHI). In conducting our business, we
            will create records regarding you, treatment, and services we
            provide to you. We are required by law to maintain the
            confidentiality of your PHI. We also are required by law to provide
            you with this notice of our legal duties .and the privacy practices
            that we maintain in our practice concerning PHI. By federal and
            state law, we must follow the terms of the notice of privacy
            practices that we have in effect at the time. We realize that these
            laws are complicated, but we must provide you with the following
            important information:
          </Text>
        </View>
        <View>
          <Text style={{ fontWeight: 'bold' }}>
            HOW WE MAY USE AND DISCLOSE YOUR PHI
          </Text>
          <Text
            style={{ lineHeight: 1.25, paddingTop: 2, textAlign: 'justify' }}
          >
            The terms of this notice apply to all records containing your PHI
            that are created or retained by our practice. We reserve the right
            to revise or amend this Notice of Privacy Practices. Any revision or
            amendment to this notice will be effective for all of your records
            that our practice maintained or created in the past and for any of
            your records that we may create or maintain in the future. Our
            practice will post a copy of our current notice in our office in a
            visible location at all times, and you may request a copy of our
            most current notice at any time.
          </Text>
        </View>
        <View>
          <Text style={{ fontWeight: 'bold' }}>
            IF YOU HAVE QUESTIONS ABOUT THIS NOTICE, PLEASE CONTACT:
          </Text>
          <Text style={{ paddingTop: 2 }}>
            Practice Manager of Pain Care Specialists of Oregon, LLC. (503)
            371-1010.
          </Text>
        </View>
        <View>
          <Text style={{ fontWeight: 'bold' }}>
            WE MAY USE AND DISCLOSE YOUR PROTECTED HEALTH INFORMATION (PHI) IN
            THE FOLLOWING WAYS:
          </Text>
          <Text
            style={{ lineHeight: 1.25, paddingTop: 2, textAlign: 'justify' }}
          >
            <Text style={{ fontWeight: 'bold' }}>Treatment.</Text>
            <Text>
              {' '}
              Our practice may use your PHI to treat you. For example, we may
              ask you to have laboratory tests (such as blood or urine tests),
              and we may ask you to use the results to help us reach a
              diagnosis. We may use your PHI in order to write a prescription
              for you, or we may disclose your PHI to a Pharmacy when we order a
              prescription for you. Many of the people who work for our practice
              including, but not limited to, our doctors and nurses-may use or
              disclose your PHI to others who may assist in your case, such as
              your friends or family members involved in your care.
            </Text>
          </Text>
          <Text
            style={{ marginTop: 6, lineHeight: 1.25, textAlign: 'justify' }}
          >
            <Text style={{ fontWeight: 'bold' }}>Payment.</Text>
            <Text>
              {` `}Our practices may use and disclose your PHI In order to bill
              and collect payment for the services and items you may receive
              from us. For example, we may contact your health insurer to
              certify that you are eligible for benefits. We may provide your
              insurer with details regarding your treatment to determine if your
              Insurer will cover or pay for your treatment. We may use your PHI
              to obtain payment from third parties that may be responsible for
              such costs. We may use your PHI to bill you directly for services
              and items.
            </Text>
          </Text>
          <Text
            style={{ marginTop: 6, lineHeight: 1.25, textAlign: 'justify' }}
          >
            <Text style={{ fontWeight: 'bold' }}>Heath Care Operations.</Text>
            <Text>
              {` `}Our practice may use and disclose your PHI to operate our
              business. As an example of the ways in which we may use and
              disclose your information for our operations, our practice may use
              your PHI to evaluate the quality of care you received from us to
              conduct cost-management and business planning activities, or to
              train new health ,care workers.
            </Text>
          </Text>
        </View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text style={{ fontWeight: 'bold' }}>Treatment Options.</Text>
          <Text>
            {` `}Our practice may use and disclose your PHI to inform you of
            potential treatment options or alternatives.
          </Text>
        </Text>
      </View>
    </View>
  )
}

export default NoticePrivacyPracticesNonWeb
