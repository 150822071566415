import { Text, View } from '@react-pdf/renderer'
import React from 'react'

function TreatmentOptions({ styles }) {
  return (
    <View style={styles.gapBox}>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text style={{ fontWeight: 'bold' }}>Treatment Options.</Text>
          {` `}
          <Text>
            Our practice may use and disclose your PHI to inform you of
            potential treatment options or alternatives.
          </Text>
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text style={{ fontWeight: 'bold' }}>
            Release of Information to Family Friends.
          </Text>
          {` `}
          <Text>
            Our practice may release your PHI to a friend or family member who
            is involved in your care or who assists in taking care of you. For
            example, a guardian may ask that neighbor take their parent or child
            to the physician's office for treatment. This neighbor may have
            access to this patient's medical information. We may also release
            information to friends or family members involved in your payment
            for health services we provide.
          </Text>
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text style={{ fontWeight: 'bold' }}>
            Disclosures Required by Law.
          </Text>
          {` `}
          <Text>
            Our practice will use and disclose your PHI when we are required to
            do so by federal, state or local law.
          </Text>
        </Text>
      </View>
      <View style={{ paddingTop: 4 }}>
        <Text style={{ fontWeight: 'bold', textAlign: 'justify' }}>
          USE AND DISCLOSURE OF YOUR PHI IN CERTAIN SPECIAL CIRCUMSTANCES
          WITHOUT APPROVAL
        </Text>
        <Text style={{ lineHeight: 1.25, paddingTop: 2, textAlign: 'justify' }}>
          The following describe unique scenarios in which we may use or
          disclose your PHI without consent or approval.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text style={{ fontWeight: 'bold' }}>Public Health Risks.</Text>
          {` `}
          <Text>
            Our practices may disclose your PHI to public health authorities
            that are authorized by law to collect information for the purpose
            of:
          </Text>
        </Text>
      </View>
      <View style={{ gap: '2px', paddingLeft: 20 }}>
        <View style={styles.gapText}>
          <Text style={styles.fontDot}>•</Text>
          <Text>Maintaining vital records, such as births and deaths</Text>
        </View>
        <View style={styles.gapText}>
          <Text style={styles.fontDot}>•</Text>
          <Text>Reporting child abuse or neglect</Text>
        </View>
        <View style={styles.gapText}>
          <Text style={styles.fontDot}>•</Text>
          <Text>Preventing or controlling disease, injury or disability</Text>
        </View>
        <View style={styles.gapText}>
          <Text style={styles.fontDot}>•</Text>
          <Text>
            Notifying a person regarding potential exposure to a communicable
            disease
          </Text>
        </View>
        <View style={styles.gapText}>
          <Text style={styles.fontDot}>•</Text>
          <Text>
            Reporting reactions to drugs or problems with products or devices
          </Text>
        </View>
        <View style={styles.gapText}>
          <Text style={styles.fontDot}>•</Text>
          <Text>
            Notifying individuals if a product or device may be using has been
            recalled
          </Text>
        </View>
        <View style={styles.gapText}>
          <Text style={styles.fontDot}>•</Text>
          <Text style={{ lineHeight: 1.25 }}>
            Notifying appropriate government agency (ies) and authority (ies)
            regarding the potential abuse or neglect of an adult patient
            (including domestic violence); however, we will only disclose
            thisinformation if the patient agrees or we are required by law to
            disclose this information.
          </Text>
        </View>
        <View style={styles.gapText}>
          <Text style={styles.fontDot}>•</Text>
          <Text style={{ lineHeight: 1.25 }}>
            Notifying your employer under limited circumstances related
            primarily to workplace injury or illnessor medical surveillance.
          </Text>
        </View>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text style={{ fontWeight: 'bold' }}>
            Health Oversight activities.
          </Text>
          {` `}
          <Text>
            Our practice may disclose your PHI to a health oversight agency for
            activities authorized by law. Oversight activities can include for
            example, investigations, inspections, audits, surveys, licensure and
            disciplinary actions; civil, administrative and criminal procedures
            or actions; or other activities necessary for the government to
            monitor government programs, compliance with civil rights laws and
            health care system in general. We may use your information to report
            diseases to the health department.
          </Text>
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.25, textAlign: 'justify' }}>
          <Text style={{ fontWeight: 'bold' }}>
            Lawsuits and Similar Proceedings.
          </Text>
          {` `}
          <Text>
            Our practice may disclose your PHI in response to a court or
            administrative order if you are involved in a lawsuit or similar
            proceeding. We also may disclose your PHI in response to a discovery
            request, subpoena, or other lawful process by another party involved
            in the dispute, but not only if we have made an effort to inform you
            of the request or to obtain an order protecting the infor mation the
            party has requested.
          </Text>
        </Text>
      </View>
    </View>
  )
}

export default TreatmentOptions
