import { Text, View } from '@react-pdf/renderer'
import React from 'react'

function Webnoticethirtee() {
  return (
    <View
      style={{
        width: '100%',
        gap: '10px',
      }}
    >
      <View>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          Pain Care Specialists of Oregon, LLC, reserves the right to charge a
          $50 fee if the patient fails to give at least 24-hour cancellation
          notice or “no show” to their appointment. This fee will be paid by the
          patient/guarantor regardless of insurance. We reserve the right to
          charge a $200 fee if the patient fails to check in for a procedure or
          cancel the procedure without providing at least 24 hours’ advance
          notice. This fee is not billable to insurance.
        </Text>
      </View>
      <View>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          I have read this policy and understand that delinquent accounts may be
          assigned to a credit reporting and collection service.
        </Text>
      </View>
      <View>
        <Text style={{ textAlign: 'center' }}>
          CANCELLATION AND NO SHOW POLICY FOR CLINIC APPOINTMENTS
        </Text>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify', paddingTop: 8 }}>
          Your appointment is reserved especially for you, and when you do not
          call to cancel an appointment, you may be preventing another patient
          from getting much needed treatment. We understand that there are times
          when you must miss an appointment due to emergencies or obligations
          for work or family. We also understand that delays can happen.
          However, in fairness to other patients, if you do not arrive 30
          minutes prior to your scheduled new patient visit we may need to
          reschedule your appointment to another day. If you arrive more than 5
          minutes past your actual appointment time you will be rescheduled. No
          controlled substances will be prescribed without an appointment. We
          kindly request that you notify us at least 24 hours in advance should
          you need to cancel or reschedule your appointment. Please contact our
          office hours are 8:00AM-4:00PM Monday through Thursday, and
          8:00AM-3:00PM Friday at (503) 371-1010. Each time you cancel or “no
          show” your appointment with less than 24 hours’ notice, you will be
          subjected to a $50.00 fee, and this fee is not billable to insurance.
          You may be dismissed from the clinic if you cancel or “no show” your
          appointment less than 24 hours of your scheduled time more than three
          times per year.
        </Text>
      </View>
      <View>
        <Text style={{ textAlign: 'center' }}>
          CANCELLATION AND NO SHOW POLICY FOR PROCEDURES
        </Text>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify', paddingTop: 8 }}>
          <Text>
            Please carefully consider your procedure date before scheduling.
            When rescheduling has to occur, the scheduling process adds to the
            administrative expenses for the clinic.{' '}
          </Text>
          <Text style={{ fontWeight: 'bold' }}>
            If you fail to check in for your procedure, or if you cancel the
            procedure with less than 24 hours’ notice, you will be subjected to
            a $200.00 fee, and this fee is not billable to insurance. You may be
            dismissed from the clinic if you cancel or “no show” your
            appointment less than 24 hours of your scheduled time more than
            twice per year.
          </Text>
        </Text>
      </View>
      <View>
        <Text style={{ textAlign: 'center' }}>
          PAIN CARE SPECIALISTS OF OREGON URINE DRUG TESTING POLICY
        </Text>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify', paddingTop: 8 }}>
          Opioid pain medications (also known as opiate or narcotic pain
          medications) are a potentially valuable source of relief for those
          suﬀering from chronic pain. They are also dangerous substances that
          carry the risk of bowel obstruction, liver failure, kidney failure,
          cardiac arrhythmia, respiratory depression, and death. These
          medications are some of the most potentially addictive substances. Use
          of these medications carries risk of dependence, tolerance,
          withdrawals, increased pain with chronic use, depression and anxiety.
          The use of these substances should not be taken lightly. Pain Care
          Specialists of Oregon (PCSO) providers will use a combination of
          factors to determine if a patient is a good candidate for opioid
          therapy. These factors include the patient’s medical, surgical, and
          family history. The patient will be given assessments such as the
          Opioid Risk Tool and the Oswestry Disability Index to make sure that
          the opioid therapy is appropriately treating the patient’s pain.
          Mixing opioid pain medications with other controlled substances can be
          extremely dangerous. Substances such as benzodiazepines, medical
          marijuana, alcohol and Soma/carisoprodol can increase the risk of
          complications or death in an opioid patient by up to 100%. PCSO may
          limit or discontinue opioid therapy in patient’s taking
          benzodiazepines or Soma/carisoprodol. Patients who are treated with
          opioid pain medications at PCSO will sign an opioid agreement. PCSO
          will provide no more than a 28-day supply of any opioid medication,
          although exceptions may be made at the discretion of the prescribing
          provider given very specific circumstances after discussion with
          colleagues. All patients are required to be physically present at the
          clinic to obtain a script. All new patients taking an opioid or
          benzodiazepine will be required to provide a urine drug test sample.
          This sample will undergo confirmation testing. If patients are unable
          to provide a urine
        </Text>
      </View>
    </View>
  )
}

export default Webnoticethirtee
