import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import React from 'react'
import AssignmentBenefitsNonWeb from '../PagePDFform/NonWebEnable/AssignmentBenefitsNonWeb'
import MedicalRecordsReleaseForm from '../PagePDFform/NonWebEnable/MedicalRecordsReleaseForm'
import NewPatientAdmission from '../PagePDFform/NonWebEnable/NewPatientAdmission'
import NoticePrivacyPracticesNonWeb from '../PagePDFform/NonWebEnable/NoticePrivacyPracticesNonWeb'
import PainCareSpecialists from '../PagePDFform/NonWebEnable/PainCareSpecialists'
import PatientDemographicsNonWeb from '../PagePDFform/NonWebEnable/PatientDemographicsNonWeb'
import SecondAcceptingPatients from '../PagePDFform/NonWebEnable/SecondAcceptingPatients'
import SecondAssignmentBenefits from '../PagePDFform/NonWebEnable/SecondAssignmentBenefits'
import SecondNoticePrivacyPractices from '../PagePDFform/NonWebEnable/SecondNoticePrivacyPractices'
import SecondSignatureCancellationNonWeb from '../PagePDFform/NonWebEnable/SecondSignatureCancellationNonWeb'
import SeriousThreatsHealthSafety from '../PagePDFform/NonWebEnable/SeriousThreatsHealthSafety'
import SignatureCancellationNonWeb from '../PagePDFform/NonWebEnable/SignatureCancellationNonWeb'
// import SignaturePermissionNonWeb from '../PagePDFform/NonWebEnable/SignaturePermissionNonWeb'
import SoapNonWeb from '../PagePDFform/NonWebEnable/SoapNonWeb'
import SoapNonWebTwo from '../PagePDFform/NonWebEnable/SoapNonWebTwo'
import System from '../PagePDFform/NonWebEnable/System'
import ThirAcceptingPatients from '../PagePDFform/NonWebEnable/ThirAcceptingPatients'
import Webnoticethirtee from '../PagePDFform/NonWebEnable/Webnoticethirtee'

function FormNonWebEnableForm({ fillableData }) {
  const styles = StyleSheet.create({
    pdfView: {
      width: window.innerWidth,
      height: window.innerHeight,
      backgroundColor: '#fff',
    },
    page: {
      width: '100%',
      flexDirection: 'column',
      backgroundColor: '#fff',
      alignItems: 'center',
      fontSize: 10,
      paddingLeft: 60,
      paddingRight: 60,
      paddingTop: 50,
      paddingBottom: 30,
    },
    imgLogo: {
      width: '140px',
      objectFit: 'cover',
    },
    gapBox: {
      gap: '14px',
    },
    gapText: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
    },
    fontDot: {
      fontSize: 12,
    },
    paddingLeftFormTitle: {
      width: '100%',
      height: '15px',
      display: 'flex',
      alignItems: 'start',
      backgroundColor: '#d3d3d3',
      justifyContent: 'center',
      fontWeight: 'bold',
    },
    paddingLeftForm: {
      paddingLeft: 10,
    },
    paddingLeftFormData: {
      width: '100%',
      borderBottom: '1px dotted #000',
      paddingLeft: 4,
    },
    boxRowData: {
      display: 'flex',
      flexDirection: 'row',
      gap: '4px',
    },
    trueFalseChoiceRow: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '14px',
      fontSize: 10,
    },
    trueFalseChoicewidth: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '6px',
      fontSize: 10,
    },
    trueFalseChoiceContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '6px',
      fontSize: 10,
    },
    stylesCheckImage: {
      border: 1,
      width: '14px',
      height: '13px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imgcheck: {
      width: '10px',
      height: '10px',
    },
    sectionthree: {
      width: '100%',
      marginTop: 20,
      marginBottom: 20,
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'start',
    },
    table: {
      display: 'table',
      borderStyle: 'solid',
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
  })

  const stylesSoap = StyleSheet.create({
    boxBorderTitle: {
      width: '100%',
      border: 1,
      borderBottom: 0,
      padding: 4,
    },
    subBoxTitle: {
      fontWeight: 'bold',
      transform: 'rotate(-90deg)',
      position: 'absolute',
      marginTop: '25px',
      width: '55px',
    },
    boxBorder: {
      width: '45px',
      border: 1,
      alignItems: 'center',
      alignContent: 'center',
      borderLeft: 0,
      borderBottom: 0,
      padding: 2,
    },
  })

  return (
    <PDFViewer style={{ ...styles.pdfView }}>
      <Document>
        {/*<------------------------- page cover ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingBottom: 0 }}>
          <View
            style={{
              height: '100%',
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '22px',
              flexDirection: 'column',
              marginTop: '-50px',
            }}
          >
            <Text>PATIENT WELCOME PACKET</Text>
            <View style={{ fontSize: 15, textAlign: 'center', paddingTop: 28 }}>
              <Text>Pain Care Specialists of Oregon’s Mission:</Text>
            </View>
            <View
              style={{
                width: '285px',
                fontSize: 10,
                paddingTop: 14,
                textAlign: 'justify',
                lineHeight: 1.5,
              }}
            >
              <Text>
                Our mission is to provide the best possible pain management in a
                caring environment. We offer the most advanced interventional
                pain management treatments to ideally help those in chronic pain
                avoid narcotics. We are committed to excellence through
                continuing education, researched techniques, and state-of-the-
                art equipment. We will value each individual and family. Our
                goal is to be recognized as the premier interventional pain
                management clinic by consistently providing excellent medical
                service based upon timely, knowledgeable, compassionate care
                from the moment you come through the door until you leave. We
                strive to have patients choose Pain Care Specialists of Oregon
                for optimal medical attention for themselves, their family and
                their community.
              </Text>
            </View>
          </View>
          <View
            style={{
              marginTop: '-120px',
              height: '75px',
              width: '100%',
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'end',
              left: '280px',
            }}
          >
            <Image style={styles.imgLogo} src="/paincare-web-v2.png" />
          </View>
        </Page>

        {/*<------------------------- page 1 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 40 }}>
          <PatientDemographicsNonWeb
            fillableData={fillableData}
            styles={styles}
          />
        </Page>

        {/*<------------------------- page 2 ------------------------->*/}
        <Page
          size="A4"
          style={{ ...styles.page, paddingTop: 25, paddingBottom: 10 }}
        >
          <NewPatientAdmission fillableData={fillableData} styles={styles} />
        </Page>

        {/*<------------------------- page 3 ------------------------->*/}
        <Page
          size="A4"
          style={{ ...styles.page, paddingTop: 25, paddingBottom: 20 }}
        >
          <SecondAcceptingPatients
            fillableData={fillableData}
            styles={styles}
          />
        </Page>

        {/*<------------------------- page 4 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 25 }}>
          <ThirAcceptingPatients fillableData={fillableData} styles={styles} />
        </Page>

        {/*<------------------------- page 5 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 25 }}>
          <SoapNonWeb
            fillableData={fillableData}
            styles={styles}
            stylesSoap={stylesSoap}
          />
        </Page>

        {/*<------------------------- page 6 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 25 }}>
          <SoapNonWebTwo
            fillableData={fillableData}
            styles={styles}
            stylesSoap={stylesSoap}
          />
        </Page>

        {/*<------------------------- page 7 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 30 }}>
          <NoticePrivacyPracticesNonWeb styles={styles} />
        </Page>

        {/*<------------------------- page 8 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 30 }}>
          <SecondNoticePrivacyPractices styles={styles} />
        </Page>

        {/*<------------------------- page 9 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 30 }}>
          <SeriousThreatsHealthSafety styles={styles} />
        </Page>

        {/*<------------------------- page 10 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page }}>
          <System styles={styles} />
        </Page>

        {/*<------------------------- page 11 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 30 }}>
          <PainCareSpecialists fillableData={fillableData} styles={styles} />
        </Page>

        {/*<------------------------- page 12 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 30 }}>
          <AssignmentBenefitsNonWeb styles={styles} />
        </Page>

        {/*<------------------------- page 13 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 30 }}>
          <SecondAssignmentBenefits styles={styles} />
        </Page>

        {/*<------------------------- page 14 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page }}>
          <Webnoticethirtee />
        </Page>

        {/*<------------------------- page 15 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page }}>
          <SignatureCancellationNonWeb fillableData={fillableData} />
        </Page>

        {/*<------------------------- page 16 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 40 }}>
          <SecondSignatureCancellationNonWeb
            fillableData={fillableData}
            styles={styles}
          />
        </Page>

        {/*<------------------------- page 17 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 40 }}>
          <MedicalRecordsReleaseForm
            fillableData={fillableData}
            styles={styles}
          />
        </Page>

        {/*<------------------------- page 18 ------------------------->*/}
        {/* <Page size="A4" style={{ ...styles.page, paddingTop: 40 }}>
          <SignaturePermissionNonWeb
            fillableData={fillableData}
            styles={styles}
          />
        </Page> */}
      </Document>
    </PDFViewer>
  )
}

export default FormNonWebEnableForm
