import { Image, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

function RecruitPatientsThirdForm({fillableData,styles}) {
  return (
    <View style={{ ...styles.gapBox }}>
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: 8,
          }}
        >
          <Image
            style={{ width: '110px', objectFit: 'cover' }}
            src="/paincare-web-v2.png"
          />
          <View style={styles.boxtitle}>
            <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
          </View>
          <View style={styles.boxtitle}>
            <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
          </View>
        </View>
        <View
          style={{
            width: '450px',
            paddingTop: '8px',
            gap: '4px',
          }}
        >
          <View style={{ width: '100%' }}>
            <View style={styles.boxRowData}>
              <View
                style={{
                  ...styles.boxRowData,
                  width: '100%',
                  gap: '0px',
                }}
              >
                <Text style={{ width: '42px' }}>Name :</Text>
                <Text style={{ ...styles.paddingLeftFormData }}>
                  {fillableData.namePatient ? fillableData.namePatient : ''}
                </Text>
              </View>
              <View
                style={{
                  ...styles.boxRowData,
                  width: '100%',
                  gap: '0px',
                }}
              >
                <Text style={{ width: '35px' }}>DOB :</Text>
                <Text style={{ ...styles.paddingLeftFormData }}>
                  {fillableData?.dobPatient
                    ? moment(fillableData?.dobPatient).format('MM/DD/YYYY')
                    : ''}
                </Text>
              </View>
            </View>
          </View>
          <Text style={{ fontWeight: 'bold' }}>
            New Patient Intake Form, Page 3 of 3
          </Text>
          <View
            style={{
              backgroundColor: '#b0acac',
              width: '100%',
              height: '4px',
            }}
          ></View>
          <View style={{ width: '100%', paddingTop: 12, gap: 2 }}>
            <Text
              style={{
                width: '100%',
                textAlign: 'center',
                fontSize: 12,
                fontWeight: 'bold',
              }}
            >
              Please check Yes or No for each question
            </Text>
            <View
              style={{
                backgroundColor: '#000',
                width: '100%',
                height: '1px',
              }}
            ></View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
                paddingBottom: 8,
              }}
            >
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 4,
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Bowel incontinence:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.bowelIncontinence === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.bowelIncontinence === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Muscle pain and spasm:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.painSpasm === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.painSpasm === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Bladder incontinence:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.bladderIncontinence === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.bladderIncontinence === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Weakness:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.weakness === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.weakness === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Nausea:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.nausea === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.nausea === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Headache:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.headache === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.headache === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Vomiting:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.vomiting === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.vomiting === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Tingling/numbness:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.tingling === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.tingling === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Fevers:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.fevers === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.fevers === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Constipation:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.constipation === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.constipation === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Chills:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.chills === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.chills === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Change in vision:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.changeVision === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.changeVision === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Sinus congestion:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.sinusCongestion === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.sinusCongestion === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Shortness of breath:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.shortnessBreath === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.shortnessBreath === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Chest pain:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.chestPain === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.chestPain === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Depression:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.depression === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.depression === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Weight loss:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.weightLoss === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.weightLoss === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Suicidal thoughts:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.suicidalThoughts === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.suicidalThoughts === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Rash:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.rash === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.rash === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Suicide attempts:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.suicideAttempts === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.suicideAttempts === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Diabetes:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.diabetes === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.diabetes === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Attention deficit disorder:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.attentionDeficit === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.attentionDeficit === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Hearing loss:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.hearingLoss === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.hearingLoss === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Obsessive compulsive disorder:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.obsessiveCompulsive === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.obsessiveCompulsive === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Chance of being pregnant:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.chancePregnant === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.chancePregnant === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Bipolar:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.bipolar === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.bipolar === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Trouble swallowing:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.troubleSwallowing === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.troubleSwallowing === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Schizophrenia:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.schizophrenia === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.schizophrenia === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Abnormal bruising or bleeding:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.abnormalBruising === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.abnormalBruising === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Mental health changes:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.mentalHealthChanges === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.mentalHealthChanges === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Hepatitis C positive:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.hepatitis === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.hepatitis === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Undergoing counseling:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.undergoingCounseling === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.undergoingCounseling === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>HIV positive:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.hiv === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.hiv === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>High stress level:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.highStress === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.highStress === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Change in sexual function:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.changeSexual === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.changeSexual === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text>Psychiatric hospitalization:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.psychiatricHospitalization ===
                            'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.psychiatricHospitalization ===
                            'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 12,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>Homicidal ideation:</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                      <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.homicidalIdeation === 'yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                        <Text>Yes</Text>
                      </View>
                      <Text>/</Text>
                      <View
                        style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                      >
                        <Text>No</Text>
                        <View style={styles.stylesCheckImage}>
                          {fillableData?.homicidalIdeation === 'no' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                ></View>
              </View>
            </View>
            <View
              style={{
                borderTop: '1px solid #000',
                width: '100%',
              }}
            ></View>
          </View>
        </View>
      </View>
  )
}

export default RecruitPatientsThirdForm