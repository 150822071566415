import React, { useEffect, useState, useRef } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Flex,
  Box,
  Container,
  Spacer,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  FormLabel,
  FormControl,
  FormHelperText,
  Input,
  useToast,
  Spinner,
  Text,
  HStack,
  Stack,
  Checkbox,
  CheckboxGroup,
  Center,
  Avatar,
  Switch,
} from '@chakra-ui/react'

import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  BsFillPersonPlusFill,
  BsFillPersonLinesFill,
  BsFillCameraFill,
} from 'react-icons/bs'
import { httpsCallable } from 'firebase/functions'
import { doc, updateDoc } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import { onSnapshot, collection, query, orderBy } from 'firebase/firestore'
import { functions, db, storage } from '../../config/firebase'

const addStaff = httpsCallable(functions, 'addStaff')
const getStaff = httpsCallable(functions, 'getStaff')
const rolePromote = httpsCallable(functions, 'rolePromote')
const resetPasswordByAdmin = httpsCallable(functions, 'resetPasswordByAdmin')
const suspendAccount = httpsCallable(functions, 'suspendAccount')

function Staff() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { handleSubmit, register, reset, errors, control } = useForm()
  const {
    handleSubmit: handleSubmitPass,
    register: registerPass,
    reset: resetPassFrom,
    errors: errorsPass,
  } = useForm()
  const [isDisabled, setIsDisabled] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [resetPassLoading, setResetPassLoading] = useState(false)
  const [password, setPassword] = useState(null)
  const [confirmPassword, setConfirmPassword] = useState(null)

  const [staffList, setStaffList] = useState([])
  const [filterdStaff, setFilteredStaff] = useState([])
  const [staffProfile, setStaffProfile] = useState(null)

  const [uploadProgress, setUploadProgress] = useState(false)
  const hiddenFileInput = useRef()

  const toast = useToast()
  const history = useHistory()
  const params = useParams()

  function callStaffProfile(id) {
    getStaff({ uid: id }).then(({ data }) => {
      setStaffProfile({ ...data, id: data.uid })
    })
  }

  useEffect(() => {
    if (params.id) {
      callStaffProfile(params.id)
    } else {
      const queryStaff = query(
        collection(db, 'profiles'),
        orderBy('fullName', 'asc')
      )
      onSnapshot(queryStaff, snapShot => {
        let data = []
        snapShot.forEach(doc => {
          data.push({ ...doc.data(), uid: doc.id })
        })
        setStaffList(data)
        setFilteredStaff(data)
        setLoading(false)
      })
    }
    return () => {
      console.log('unmount')
      setStaffProfile(null)
    }
  }, [params])

  console.log(staffProfile)

  function onSubmit(value) {
    setIsDisabled(true)
    addStaff({
      email: value.email,
      password: value.confirmPassword,
      fullName: value.fullName,
      roles: value.roles,
      jobTitle: value.jobTitle,
    })
      .then(() => {
        toast({
          title: 'Staff created.',
          description: 'Staff account has created.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setIsDisabled(false)
        setPassword(null)
        setConfirmPassword(null)
        reset()
        onClose()
      })
      .catch(e => {
        toast({
          title: 'Create fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setIsDisabled(false)
        setPassword(null)
        setConfirmPassword(null)
        reset()
      })
  }

  function updateProfile(value) {
    setIsDisabled(true)
    updateDoc(doc(db, 'profiles', params.id), { ...value })
      .then(() => {
        rolePromote({ uid: params.id, roles: value.roles })
        toast({
          title: 'Profile updated.',
          description: 'Staff profile has updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setIsDisabled(false)
      })
      .catch(() => {
        toast({
          title: 'Update fail.',
          description: 'Cannot update staff profile.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setIsDisabled(false)
      })
  }

  function handleUploadClick() {
    hiddenFileInput.current.click()
  }

  async function handleUploadChange(e) {
    setUploadProgress(true)
    const fileUploaded = e.target.files[0]
    if (staffProfile) {
      if (fileUploaded) {
        if (
          fileUploaded.type === 'image/jpeg' ||
          fileUploaded.type === 'image/jpg'
        ) {
          const scheduleRef = ref(
            storage,
            `Profile/${staffProfile.uid}/avatar.jpg`
          )
          uploadBytes(scheduleRef, fileUploaded).then(async () => {
            const downloadURL = await getDownloadURL(scheduleRef)
            updateDoc(doc(db, 'profiles', params.id), {
              avatarUrl: downloadURL,
            })
              .then(() => {
                toast({
                  title: 'Avatar updated.',
                  description: 'Staff profile has updated.',
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                })
                callStaffProfile(params.id)
                setUploadProgress(false)
              })
              .catch(() => {
                toast({
                  title: 'Update fail.',
                  description: 'Cannot update staff profile.',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                })
                setUploadProgress(false)
              })
          })
        } else {
          toast({
            title: 'Update fail.',
            description: 'Please use file .JPG or .JPEG only. ',
            status: 'error',
            duration: 4000,
            isClosable: true,
          })
          setUploadProgress(false)
        }
      } else {
        setUploadProgress(false)
      }
    }
  }

  function resetPassword(value) {
    setResetPassLoading(true)
    resetPasswordByAdmin({ uid: staffProfile.id, newPassword: value.resetPass })
      .then(() => {
        toast({
          title: 'Reset Password.',
          description: 'Reset Successfully.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        setResetPassLoading(false)
        resetPassFrom()
      })
      .catch(e => {
        toast({
          title: 'Reset Password fail.',
          description: e.message,
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
        setResetPassLoading(false)
        resetPassFrom()
      })
  }

  return (
    <>
      <Container maxW="100%" mt="5">
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
          bg="gray.100"
          p="1.5"
          borderRadius="0.3em"
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/dashboard">
              Dashbaord
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/staff">
              Staff Management
            </BreadcrumbLink>
          </BreadcrumbItem>
          {params.id && staffProfile ? (
            <BreadcrumbItem>
              <BreadcrumbLink>{staffProfile.fullName}</BreadcrumbLink>
            </BreadcrumbItem>
          ) : (
            params.id && (
              <BreadcrumbItem>
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xs"
                />
              </BreadcrumbItem>
            )
          )}
        </Breadcrumb>
        {params.id && staffProfile ? (
          <Box mt="5" mb="10">
            <Box mt="3" minHeight="sm" p="5">
              <Stack
                spacing="25px"
                direction={['column', 'column', 'row', 'row']}
              >
                <Box
                  p="15"
                  width="100%"
                  borderRadius="lg"
                  boxShadow="md"
                  borderWidth="1px"
                >
                  <Text fontSize="2xl" mb="10">
                    Account
                  </Text>
                  <Center>
                    {uploadProgress ? (
                      <Spinner
                        mb="5"
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                      />
                    ) : (
                      <Avatar
                        icon={<BsFillCameraFill />}
                        size="xl"
                        mb="5"
                        onClick={handleUploadClick}
                        cursor="pointer"
                        src={staffProfile.avatarUrl}
                      />
                    )}
                    <Input
                      type="file"
                      display="none"
                      ref={hiddenFileInput}
                      onChange={handleUploadChange}
                    />
                  </Center>
                  <Center>
                    <Text fontSize="xl" mb="5" fontWeight="bold">
                      {staffProfile.fullName}
                    </Text>
                  </Center>
                  <form id="profile" onSubmit={handleSubmit(updateProfile)}>
                    <Stack>
                      <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input
                          name="Email"
                          disabled
                          defaultValue={staffProfile.email}
                        />
                      </FormControl>
                      <FormControl id="fullName" isInvalid={errors.fullName}>
                        <FormLabel>Staff Name</FormLabel>
                        <Input
                          type="text"
                          name="fullName"
                          ref={register({ required: 'Full name required.' })}
                          placeholder="Staff Name"
                          defaultValue={staffProfile.fullName}
                        />
                        <FormErrorMessage>
                          {errors.fullName && errors.fullName.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl id="role">
                        <FormLabel>Roles</FormLabel>
                        <Controller
                          render={props => {
                            return (
                              <CheckboxGroup
                                defaultValue={props.value}
                                name={props.name}
                                onChange={props.onChange}
                              >
                                <Stack>
                                  <HStack>
                                    <Checkbox value="Admin">Admin</Checkbox>
                                    <Checkbox value="Scheduler">
                                      Scheduler
                                    </Checkbox>
                                    <Checkbox value="Patient Coordinator">
                                      Patient Coordinator
                                    </Checkbox>
                                  </HStack>
                                  <HStack>
                                    <Checkbox value="IT Staff">
                                      IT Staff
                                    </Checkbox>
                                    <Checkbox value="Staff">Staff</Checkbox>
                                    <Checkbox value="Event">Event</Checkbox>
                                  </HStack>
                                </Stack>
                              </CheckboxGroup>
                            )
                          }}
                          name="roles"
                          defaultValue={staffProfile.roles}
                          control={control}
                        />
                      </FormControl>
                    </Stack>
                    <Center mt="10" mb="10">
                      <Button type="submit" width="50%" isDisabled={isDisabled}>
                        Update
                      </Button>
                    </Center>
                  </form>
                </Box>
                <Box
                  w="100%"
                  p="15"
                  boxShadow="md"
                  borderWidth="1px"
                  borderRadius="lg"
                >
                  <Text fontSize="2xl" mb="10">
                    Security
                  </Text>
                  <form
                    id="resetPassword"
                    onSubmit={handleSubmitPass(resetPassword)}
                  >
                    <FormControl
                      mb="5"
                      id="resetPass"
                      isInvalid={errorsPass.resetPass}
                    >
                      <FormLabel>Reset Password</FormLabel>
                      <HStack>
                        <Input
                          name="resetPass"
                          placeholder="Input new password"
                          type="password"
                          w="50%"
                          ref={registerPass({
                            required: 'Password required.',
                            minLength: {
                              value: 6,
                              message: 'A password must 6 character long',
                            },
                          })}
                        />
                        <Button
                          type="submit"
                          colorScheme="orange"
                          isLoading={resetPassLoading}
                        >
                          RESET
                        </Button>
                      </HStack>
                      <FormErrorMessage>
                        {errorsPass.resetPass && errorsPass.resetPass.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl id="suspendUser">
                      <FormLabel>Inactive</FormLabel>
                      <Switch
                        defaultChecked={staffProfile.disabled}
                        size="lg"
                        id="suspendUser"
                        onChange={e => {
                          let checked = e.currentTarget.checked
                          suspendAccount({ uid: staffProfile.id, checked })
                            .then(() => {
                              toast({
                                title: 'Inactive Account.',
                                description: 'Account Inactivated.',
                                status: 'success',
                                duration: 4000,
                                isClosable: true,
                              })
                              setResetPassLoading(false)
                              resetPassFrom()
                            })
                            .catch(e => {
                              toast({
                                title: 'Inactive Account.',
                                description: e.message,
                                status: 'error',
                                duration: 4000,
                                isClosable: true,
                              })
                              setResetPassLoading(false)
                              resetPassFrom()
                            })
                        }}
                      />
                    </FormControl>
                  </form>
                </Box>
              </Stack>
            </Box>
          </Box>
        ) : (
          <Box mt="5">
            <Flex mt="5">
              <Box pt="2" fontSize="18" fontWeight="bold">
                Staff Management
              </Box>
              <Spacer />
              <Button
                leftIcon={<BsFillPersonPlusFill />}
                size="sm"
                onClick={onOpen}
              >
                Add Staff
              </Button>
            </Flex>
            <Box textAlign="right" mt="5">
              <Input
                type="text"
                width={['100%', '100%', '100%', '30%']}
                placeholder="Search"
                onChange={e => {
                  let regEx = new RegExp(`${e.target.value.toLowerCase()}`, 'g')
                  let filteredList = staffList.filter(staff => {
                    let fullName = staff.fullName.toLowerCase()
                    return fullName.match(regEx)
                  })
                  setFilteredStaff(filteredList)
                }}
              />
            </Box>
            <Box overflow="scroll">
              <Table size="sm" mt="10">
                <Thead>
                  <Tr>
                    <Th>User / Email</Th>
                    <Th>Full name</Th>
                    <Th>Roles</Th>
                    <Th textAlign="right">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoading ? (
                    <Tr>
                      <Td colSpan="4" textAlign="center">
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="xl"
                        />
                      </Td>
                    </Tr>
                  ) : (
                    filterdStaff.map((data, index) => {
                      return (
                        <Tr key={index}>
                          <Td textAlign="left">
                            <HStack>
                              <Avatar src={data.avatarUrl} size="md" />{' '}
                              <Text>{data.email}</Text>
                            </HStack>
                          </Td>
                          <Td>{data.fullName}</Td>
                          <Td>
                            {data?.roles?.map((role, index) => (
                              <span key={index}>
                                {role}
                                {
                                  data?.roles?.length !== (index += 1) ? (
                                    <>,&nbsp;</>
                                  ) : (
                                    ''
                                  ) // Count last array
                                }
                              </span>
                            ))}
                          </Td>
                          <Td textAlign="right">
                            <Button
                              onClick={() => history.push(`/staff/${data.uid}`)}
                              size="xs"
                              leftIcon={<BsFillPersonLinesFill />}
                              isLoading={params.id === data.uid ? true : false}
                            >
                              View
                            </Button>
                          </Td>
                        </Tr>
                      )
                    })
                  )}
                </Tbody>
              </Table>
            </Box>
          </Box>
        )}
      </Container>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Staff</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)} id="add-staff">
              <FormControl mt="3" id="fullName" isInvalid={errors.fullName}>
                <FormLabel>Full name</FormLabel>
                <Input
                  name="fullName"
                  type="text"
                  ref={register({ required: 'Full name required.' })}
                />
                <FormErrorMessage>
                  {errors.fullName && errors.fullName.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt="3" id="email" isInvalid={errors.email}>
                <FormLabel>Email address</FormLabel>
                <Input
                  name="email"
                  type="email"
                  ref={register({ required: 'Email required.' })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt="3" id="password" isInvalid={errors.password}>
                <FormLabel>Password</FormLabel>
                <Input
                  name="password"
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  ref={register({ required: 'Password required.' })}
                />
                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt="3" mb="5" isInvalid={errors.confirmPassword}>
                <FormLabel>Confirm Password</FormLabel>
                <Input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                  ref={register({ required: 'Please confirm password.' })}
                />
                <FormErrorMessage>
                  {errors.confirmPassword && errors.confirmPassword.message}
                </FormErrorMessage>
                {!!confirmPassword && confirmPassword !== password && (
                  <FormHelperText id="confirm-password">
                    Passwords do not match
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                mt="3"
                mb="5"
                id="jobTitle"
                isInvalid={errors.jobTitle}
              >
                <FormLabel>Job Title</FormLabel>
                <Input
                  name="jobTitle"
                  type="text"
                  ref={register({ required: 'Job Title required.' })}
                />
                <FormErrorMessage>
                  {errors.jobTitle && errors.jobTitle.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl id="roles" isInvalid={errors.roles}>
                <FormLabel>Roles</FormLabel>
                <Controller
                  render={props => (
                    <CheckboxGroup name={props.name} onChange={props.onChange}>
                      <Stack>
                        <Checkbox value="Admin">Admin</Checkbox>
                        <Checkbox value="Scheduler">Scheduler</Checkbox>
                        <Checkbox value="Patient Coordinator">
                          Patient Coordinator
                        </Checkbox>
                        <Checkbox value="IT Staff">IT Staff</Checkbox>
                        <Checkbox value="Staff">Staff</Checkbox>
                        <Checkbox value="Event">Event</Checkbox>
                      </Stack>
                    </CheckboxGroup>
                  )}
                  name="roles"
                  defaultValue={null}
                  control={control}
                  rules={{ required: 'Roles is required.' }}
                />
                <FormErrorMessage>
                  {errors.roles && errors.roles.message}
                </FormErrorMessage>
              </FormControl>
              <Button
                type="submit"
                colorScheme="blue"
                mt="5"
                w="100%"
                size="lg"
                isLoading={isDisabled}
                disabled={
                  isDisabled || (confirmPassword !== password ? true : false)
                }
              >
                Add
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Staff
