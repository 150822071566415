import { Center, Text } from '@chakra-ui/react'
import React from 'react'
import { CardContent } from '../../../../../../Components/Card/card'

function Amendment({ formView }) {
  return (
    <CardContent>
      <Text>
        <span style={{ fontWeight: 'bold' }}>{`Amendment.`}</span>
        {` You may ask to amend your health information if you believe it is incorrect or incomplete, and you may request an amendment for as long as the information is kept by or for our practice. To request and amendment, your request must be made in writing and submitted to our privacy officer. You must provide it with a reason that supports your request for amendment. Our practice will deny your request if you fail to submit in writing your request and the reason supporting your request. Also, we may deny your request if you ask us to amend information that is in our opinion: (a) accurate and complete; (b) not part of the PHI which you would be permitted to inspect and copy; or (d) not created by our practice, unless the individual or entity that created the information is not available to amend the information. `}
      </Text>
      <Text pt="15px">
        <span
          style={{ fontWeight: 'bold' }}
        >{`Accounting of Disclosures.`}</span>
        {` All of our patients have the right to request an "accounting of disclosures." An "accounting of disclosures" is a list of certain disclosures required to list your PHI as part of the routine patient care, payment, or health operations in our practice for paper records. Example of routine patient care, payment or health operations excluded from an accounting from paper charts include: the doctor sharing information with the nurse, the billing department using your information to file your insurance claim, and discussion of your PHI for purposes of improving our health care delivery system. In order to obtain an accounting you must submit your request in writing to the Privacy Officer/Office Manager or designee`}
        <span
          style={{ color: 'red' }}
        >{` 2480 Liberty St NE, Ste 180, Salem, OR 97301 (503) 371-1010.`}</span>
        {` All requests for "accounting of disclosures" must state a time period, which may be longer than 6 years with paper charts or 3 years for listings to include dates before April 14, 2003. The first list you request within a 12-month period. Our practice will notify you of the costs involved with additional requests, and you may withdraw your request before you incur any costs.`}
      </Text>
      <Text pt="15px">
        <span
          style={{ fontWeight: 'bold' }}
        >{`Right to Paper Copy of This Notice.`}</span>
        {` You are entitled to receive a paper copy of our Notice of Privacy Practices. You will be offered a copy on your first visit to the practice. You may ask us to give you a copy of this notice at any time. To obtain a paper copy of this notice, contact our Privacy Officer/Office Manager of designee at`}
        <span style={{ color: 'red' }}>{` (503) 371-1010.`}</span>
      </Text>
      <Text pt="15px">
        <span
          style={{ fontWeight: 'bold' }}
        >{`Right to File a Complaint.`}</span>
        {` If you believe your privacy rights have been violated, you may file a complaint with our practice, or with our Secretary of the Department of Health and Human Service; Office of Civil Rights,`}
        <span
          style={{ color: 'red' }}
        >{` 500 Summer St NE, Salem, OR 97301 (503) 945-5944.`}</span>
        {` All complaints must be submitted in writing. You will not be penalized for filing a complaint.`}
      </Text>
      <Text pt="15px">
        <span
          style={{ fontWeight: 'bold' }}
        >{`Right to Provide and Authorization for Other Uses and Disclosures.`}</span>
        {` Our practice will obtain your written authorization for uses and disclosures that are not covered by this notice or permitted by applicable law, such as for research or marketing. Any authorization you provide to us regarding the use and disclosure of your PHI may be revoked at any time in writing. After you revoke your PHI for the reasons described in the authorization. Please note, we are required to retain records for your care.`}
      </Text>
      <Text pt="15px">{`Again, if you have any questions regarding this notice or our health information privacy policies, please contact our Privacy officer at (503) 371-1010.`}</Text>
      <Center pt="2rem">{`4/16`}</Center>
    </CardContent>
  )
}

export { Amendment }
