import { onAuthStateChanged } from 'firebase/auth'
import { doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'
import { auth, db } from './config/firebase'

import { isEmpty } from 'underscore'

import { Center, Spinner } from '@chakra-ui/react'
import 'antd/dist/antd.css'
import AppMenu from './Components/AppMenu'
import Appointment from './Pages/Appointment'
import Credentialing from './Pages/Credentialing'
import CredentialProfile from './Pages/Credentialing/profiles'
import CredentialSetting from './Pages/Credentialing/setting'
import Dashboard from './Pages/Dashboard'
import EmployeeForm from './Pages/EmployeeForm'
import EventList from './Pages/Event'
import VerifyEvent from './Pages/Event/verify'
import ViewRegister from './Pages/Event/view'
import Fillable from './Pages/Fillable'
import FillableView from './Pages/Fillable/View'
import AbsenteeReportForm from './Pages/Forms/AbsenteeReportForm'
import AnnualClinicalCompetencies from './Pages/Forms/AnnualClinicalCompetencies'
import CMERequestForm from './Pages/Forms/CMERequestForm'
import CollectorsCompetencyQuiz from './Pages/Forms/CollectorsCompetencyQuiz'
import CovidExprosureScreening from './Pages/Forms/CovidExprosureScreening'
import EmployeeQuiz from './Pages/Forms/EmployeeQuiz'
import InfectionsAndEmergencyVisitForm from './Pages/Forms/InfectionsAndEmergencyVisitForm'
import PaidTimeOffRequestForm from './Pages/Forms/PaidTimeOffRequestForm'
import RadiationSafetyTest from './Pages/Forms/RadiationSafetyTest'
import RequestForOFLALeaveForm from './Pages/Forms/RequestForOFLALeaveForm'
import TimeAwayRequestForm from './Pages/Forms/TimeAwayRequestForm'
import TimeClockAdjustmentForm from './Pages/Forms/TimeClockAdjustmentForm'
import Pdf from './Pages/Forms/pdf'
import HelpDesk from './Pages/HelpDesk'
import InEvisitForm from './Pages/InEvisitForm'
import Login from './Pages/Login'
import NonWebEnabledForm from './Pages/NonWebEnabledForm'
import NonWebEnabledFinish from './Pages/NonWebEnabledForm/finish'
import NonAuthorizationLetter from './Pages/NonWebEnabledForm/non-authorization-letter'
import NonWebPatientIntakeF from './Pages/NonWebEnabledForm/patientintake-1'
import NonWebPatientIntakeS from './Pages/NonWebEnabledForm/patientintake-2'
import NonWebPatientIntakeT from './Pages/NonWebEnabledForm/patientintake-3'
import RequestAppointment from './Pages/RequestAppointment'
import Schedule from './Pages/Schedule'
import Staff from './Pages/Staff'
import Tickets from './Pages/Tickets'
import WebEnableForm from './Pages/WebEnableForm'
import AuthorizationLetter from './Pages/WebEnableForm/authorization-letter'
import WebEnabledFinish from './Pages/WebEnableForm/finish'
import KioskCheckIn from './Pages/WebEnableForm/kiosk-checkin'
import './antd-custom.css'
import roleFilters from './config/roleFilters'
import EmployeePDFForm from './Pages/EmployeeForm/EmployeePDFForm'
import FillablePDFForm from './Pages/Fillable/FillablePDFForm'
import PatientFormView from './Pages/PatientForm/view'
import OswestryForm from './Pages/PatientForm/form/formODQ'
import PatienList from './Pages/PatientForm'
import PatientForm from './Pages/PatientForm/form/formPHQGAD'
import TBHighRiskForm from './Pages/Forms/TBHighRiskForm'
import SOAPPRForm from './Pages/PatientForm/form/formSOAPPR'
import SubmitSuccess from './Pages/PatientForm/success'
import DeclinationOfInfluenzaVaccinationForm from './Pages/Forms/DeclinationOfInfluenzaVaccinationForm'
import PeerReview from './Pages/PeerReview'
import PeerReviewForm from './Pages/PeerReview/form'
import ViewPeerReviewPDF from './Pages/PeerReview/pdf'

const PrivateRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isEmpty(user) ? (
        <>
          <AppMenu user={user} />
          <Component user={user} {...props} />
        </>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
)

function App() {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async authUser => {
      if (authUser) {
        let idToken = await authUser.getIdTokenResult()
        let uid = authUser.uid
        setUser({ ...authUser })
        onSnapshot(doc(db, 'profiles', uid), doc => {
          setUser({ ...authUser, ...doc.data(), roles: idToken?.claims?.roles })
          setLoading(false)
        })
      } else {
        setLoading(false)
        setUser(null)
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Router>
      {loading ? (
        <Center mt="50">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      ) : (
        <Switch>
          <PrivateRoute
            path="/declination-of-influenza-vaccination"
            component={DeclinationOfInfluenzaVaccinationForm}
            user={user}
          />
          <PrivateRoute
            path="/tb-high-risk"
            component={TBHighRiskForm}
            user={user}
          />
          <PrivateRoute
            path="/radiation-safety-test"
            component={RadiationSafetyTest}
            user={user}
          />
          <PrivateRoute
            path="/employee-quiz"
            component={EmployeeQuiz}
            user={user}
          />
          <PrivateRoute
            path="/collectors-competency-quiz"
            component={CollectorsCompetencyQuiz}
            user={user}
          />
          <PrivateRoute
            path="/annual-clinical-competencies"
            component={AnnualClinicalCompetencies}
            user={user}
          />
          <PrivateRoute
            path="/covid-exprosure-screening"
            component={CovidExprosureScreening}
            user={user}
          />
          <PrivateRoute
            path="/request-for-ofla-leave-form"
            component={RequestForOFLALeaveForm}
            user={user}
          />
          <PrivateRoute
            path="/paid-timeoff-request-form"
            component={PaidTimeOffRequestForm}
            user={user}
          />
          <PrivateRoute
            path="/cme-request-form"
            component={CMERequestForm}
            user={user}
          />
          <PrivateRoute
            path="/absentee-report-form"
            component={AbsenteeReportForm}
            user={user}
          />
          <PrivateRoute
            path="/time-clock-adjustment-form"
            component={TimeClockAdjustmentForm}
            user={user}
          />
          <PrivateRoute
            path="/time-away-request-form"
            component={TimeAwayRequestForm}
            user={user}
          />
          <PrivateRoute path="/schedule" component={Schedule} user={user} />
          <PrivateRoute
            path="/help-desk/:id"
            component={HelpDesk}
            user={user}
          />
          <PrivateRoute path="/help-desk" component={HelpDesk} user={user} />
          <PrivateRoute
            path="/credentialing/settings"
            component={CredentialSetting}
            user={user}
          />
          <PrivateRoute
            path="/credentialing/:id"
            component={CredentialProfile}
            user={user}
          />
          <PrivateRoute
            path="/credentialing"
            component={Credentialing}
            user={user}
          />
          <PrivateRoute
            path="/fillable/view/:id/pdf/:formType"
            component={FillablePDFForm}
            user={user}
          />
          <PrivateRoute
            path="/fillable/view/:id"
            component={FillableView}
            user={user}
          />
          <PrivateRoute path="/fillable" component={Fillable} user={user} />

          {user && roleFilters(user.roles, 'Patient Coordinator') && (
            <PrivateRoute
              path="/appointment/:id"
              component={Appointment}
              user={user}
            />
          )}
          {user && roleFilters(user.roles, 'Patient Coordinator') && (
            <PrivateRoute
              path="/appointment"
              component={Appointment}
              user={user}
            />
          )}

          {user && roleFilters(user.roles, 'Scheduler') && (
            <PrivateRoute
              path="/employee-form/:id/pdf/:formType"
              component={EmployeePDFForm}
              user={user}
            />
          )}

          {user && roleFilters(user.roles, 'Scheduler') && (
            <PrivateRoute
              path="/employee-form/:id"
              component={EmployeeForm}
              user={user}
            />
          )}

          {user && roleFilters(user.roles, 'Scheduler') && (
            <PrivateRoute
              path="/employee-form"
              component={EmployeeForm}
              user={user}
            />
          )}

          {user && roleFilters(user.roles, 'IT Staff') && (
            <PrivateRoute path="/tickets/:id" component={Tickets} user={user} />
          )}
          {user && roleFilters(user.roles, 'IT Staff') && (
            <PrivateRoute path="/tickets" component={Tickets} user={user} />
          )}
          {user && roleFilters(user.roles, 'Admin') && (
            <PrivateRoute
              exact
              path="/staff/:id"
              component={Staff}
              user={user}
            />
          )}
          {user && roleFilters(user.roles, 'Admin') && (
            <PrivateRoute exact path="/staff" component={Staff} user={user} />
          )}

          {user && roleFilters(user.roles, 'Event') && (
            <PrivateRoute
              path="/event/register/:id"
              component={ViewRegister}
              user={user}
            />
          )}

          {user && roleFilters(user.roles, 'Event') && (
            <PrivateRoute path="/event" component={EventList} user={user} />
          )}
          <Route path="/verify-event/:id" component={VerifyEvent} />

          <PrivateRoute path="/dashboard" component={Dashboard} user={user} />

          {user && (
            <PrivateRoute
              path="/infections-and-emergency-visit-form/pdf/:docId"
              component={Pdf}
              user={user}
            />
          )}

          {user && (
            <PrivateRoute
              path="/infections-and-emergency-visit-form/:id"
              component={InfectionsAndEmergencyVisitForm}
              user={user}
            />
          )}
          {user && (
            <PrivateRoute
              path="/infections-and-emergency-visit-form"
              component={InfectionsAndEmergencyVisitForm}
              user={user}
            />
          )}

          {user && (
            <PrivateRoute
              path="/infections-and-emergency-visit"
              component={InEvisitForm}
              user={user}
            />
          )}

          {user && (
            <PrivateRoute
              path="/peer-review/pdf/:id"
              component={ViewPeerReviewPDF}
              user={user}
            />
          )}

          {user && (
            <PrivateRoute
              path="/peer-review/form"
              component={PeerReviewForm}
              user={user}
            />
          )}

          {user && (
            <PrivateRoute
              path="/peer-review/:id"
              component={PeerReviewForm}
              user={user}
            />
          )}

          {user && (
            <PrivateRoute
              path="/peer-review"
              component={PeerReview}
              user={user}
            />
          )}

          <Route exact path="/patient/success" component={SubmitSuccess} />

          <Route exact path="/patient/form-soapp-r" component={SOAPPRForm} />

          <Route exact path="/patient/form-odq" component={OswestryForm} />

          <Route
            exact
            path="/patient/form-PHQ-9-GAD-7"
            component={PatientForm}
          />

          <PrivateRoute
            path="/patient/:id"
            component={PatientFormView}
            user={user}
          />

          <PrivateRoute path="/patient" component={PatienList} user={user} />

          <Route path="/request-appointment" component={RequestAppointment} />

          <Route
            exact
            path="/web-enable-form/kios-checkin"
            component={KioskCheckIn}
          />
          <Route
            exact
            path="/web-enable-form/poa"
            component={AuthorizationLetter}
          />
          <Route
            exact
            path="/web-enable-form/finish"
            component={WebEnabledFinish}
          />
          <Route path="/web-enable-form" component={WebEnableForm} />
          <Route
            exact
            path="/non-web-enable-form"
            component={NonWebEnabledForm}
          />
          <Route
            exact
            path="/non-web-enable-form/patient-intake-1"
            component={NonWebPatientIntakeF}
          />
          <Route
            exact
            path="/non-web-enable-form/patient-intake-2"
            component={NonWebPatientIntakeS}
          />
          <Route
            exact
            path="/non-web-enable-form/patient-intake-3"
            component={NonWebPatientIntakeT}
          />
          <Route
            exact
            path="/non-web-enable-form/poa"
            component={NonAuthorizationLetter}
          />
          <Route
            exact
            path="/non-web-enable-form/finish"
            component={NonWebEnabledFinish}
          />
          <Route path="/login" component={Login} />
          <Route path="/" component={Login} />
        </Switch>
      )}
    </Router>
  )
}

export default App
