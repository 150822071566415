import {
  Box,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CardContent } from '../../../../../../Components/Card/card'
import { SwitchInputComponent } from '../../../switchInput'
import { totalNonWebEnabledPage } from '../..'
// const _ = require('underscore')

function MedicalRecordsReleaseForm({ formView }) {
  const {
    // handleSubmit,
    // reset,
    // register,
    // errors,
    control,
    // setValue,
    // watch,
    // clearErrors,
  } = useForm()

  return (
    <CardContent>
      <Box>
        <Text textAlign="center" fontWeight="bold">
          MEDICAL RECORDS RELEASE FORM
        </Text>
        <Text pl="10px" pt="40px">
          This authorization must be completed, dated, and signed by the patient
          or by a person authorized by law to give authorization.
        </Text>
        <Flex
          pt="40px"
          direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
        >
          <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
            <Controller
              name="namePatient"
              control={control}
              defaultValue={formView.namePatient}
              render={props => (
                <FormControl>
                  <FormLabel>I (Name of Patient)</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    isReadOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
          <Box
            w="100%"
            pt={{ base: '10px', md: '0px' }}
            pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
          >
            <Controller
              name="DOB"
              control={control}
              defaultValue={moment(new Date(formView.DOB)).format('MM/DD/YYYY')}
              render={props => (
                <FormControl>
                  <FormLabel>DOB (mm/dd/yy)</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    bg="#F9F9F9"
                    border="1px solid black"
                    isReadOnly
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Flex>
        <Box w="100%" pt="15px">
          <Controller
            name="authorizePage17"
            control={control}
            defaultValue={formView.authorizePage17}
            render={props => (
              <FormControl>
                <FormLabel>authorize(Provider/Facility)</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  bg="#F9F9F9"
                  border="1px solid black"
                  isReadOnly
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </Box>
        <Text pt="15px">
          that has provided me medical treatment to release copies of my medical
          records (except for HIV/AIDS related records, genetic testing
          information, or Drug and Alcohol diagnosis and/or treatment
          information) to:
        </Text>
        <Text pt="15px">PAIN CARE SPECIALISTS OF OREGON</Text>
        <Text>2480 LIBERTY ST NE, STE 180</Text>
        <Text>SALEM, OR 97301</Text>

        <Box pt="15px">
          <Text fontWeight={'bold'}>For records within the data range of</Text>
          <Text my="10px">
            {formView.startOneDate || '--------'} -{' '}
            {formView.endOneDate || '--------'}
          </Text>
          <Text>And/Or:</Text>
        </Box>
        <Flex
          pt="15px"
          direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
        >
          <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
            <Controller
              name="nameRecipient"
              control={control}
              defaultValue={formView.nameRecipient}
              render={props => (
                <FormControl>
                  <FormLabel>Name of Recipient</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    bg="#F9F9F9"
                    border="1px solid black"
                    isReadOnly
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
          <Box
            w="100%"
            pt={{ base: '10px', md: '0px' }}
            pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
          >
            <Controller
              name="maillingAddress"
              control={control}
              defaultValue={formView.maillingAddress}
              render={props => (
                <FormControl>
                  <FormLabel>Mailing Address</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    bg="#F9F9F9"
                    border="1px solid black"
                    isReadOnly
                    type="number"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Flex>
        <Flex
          pt="15px"
          direction={{
            base: 'column',
            sm: 'column',
            md: 'column',
            lg: 'column',
            xl: 'row',
          }}
        >
          <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
            <Controller
              name="faxPage17"
              control={control}
              defaultValue={formView.faxPage17}
              render={props => (
                <FormControl>
                  <FormLabel pt={{ base: '0px', md: '20px', lg: '0px' }}>
                    Fax Number
                  </FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    bg="#F9F9F9"
                    border="1px solid black"
                    isReadOnly
                    type="number"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
          <Box
            // border="1px solid red"
            w="100%"
            // pt={{ base: '10px', md: '30px', xl: '40px' }}
            pl={{ base: '0px', sm: '0px', md: '0px', xl: '10px' }}
          >
            <Text fontWeight={'bold'}>
              For records within the data range of
            </Text>
            <Text pb="10px">
              {formView.startDateRecords || '--------'} -{' '}
              {formView.endDateRecords || '--------'}
            </Text>
          </Box>
        </Flex>
        <Text pt={'20px'}>
          The information will be used on my behalf for continuity of care.
          Please send the entire medical record (all information) to the above
          named recipient. The recipient understands this record may be
          voluminous and agrees to pay all reasonable charges associated with
          providing this record. This authorization may be revoked at any time.
          This release will be valid for 1 year from the date signed.
        </Text>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          alignItems="start"
          gap={0}
          h="max-content"
        >
          <Controller
            name="signaturePage17"
            control={control}
            defaultValue={formView.signaturePage17}
            render={props => (
              <FormControl>
                <GridItem
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Signature of patient
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="0px">
                          <SwitchInputComponent
                            formView={formView.signaturePatient || ''}
                          />
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>
                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                </GridItem>
              </FormControl>
            )}
          />

          <GridItem w="100%">
            <VStack spacing="0px">
              <Center h={{ base: '80px' }}>
                <Text fontWeight="bold" fontSize="15">
                  Date
                </Text>
              </Center>
              <Center h="40px">
                <Text>
                  {moment(new Date(formView.createdAt.toDate())).format(
                    'MM/DD/YYYY'
                  )}
                </Text>
              </Center>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>
        </Grid>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          alignItems="start"
          gap={0}
          h="max-content"
        >
          <Controller
            name="signaturePersonPage17"
            control={control}
            defaultValue={formView.signaturePersonPage17}
            render={props => (
              <FormControl>
                <GridItem
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Signature of person authorized by law
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="0px">
                          <SwitchInputComponent
                            formView={formView.signaturePatientLaw || ''}
                          />
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>
                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                </GridItem>
              </FormControl>
            )}
          />
          <GridItem w="100%">
            <VStack spacing="0px">
              <Center h={{ base: '80px' }}>
                <Text fontWeight="bold" fontSize="15">
                  Date
                </Text>
              </Center>

              <Center h="40px">
                <Text>
                  {moment(new Date(formView.createdAt.toDate())).format(
                    'MM/DD/YYYY'
                  )}
                </Text>
              </Center>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>
        </Grid>
      </Box>
      <Center pt="2rem">{`17/${totalNonWebEnabledPage}`}</Center>
    </CardContent>
  )
}

export { MedicalRecordsReleaseForm }
