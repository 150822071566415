import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
    Box,
    Container,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Input,
    InputGroup,
    InputLeftElement,
    HStack,
    Select,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Spinner,
    Heading,
    Center,
    Tag,
    Text,
    Textarea,
    FormControl,
    FormErrorMessage,
    Icon,
    Img,
    ButtonGroup,
    useToast,
    Stack
} from '@chakra-ui/react'
import { ChevronRightIcon, SearchIcon } from '@chakra-ui/icons'
import { BiPaperclip } from 'react-icons/bi'
import { FaUser, FaUserShield } from 'react-icons/fa'
import { doc, onSnapshot, getDoc, where, orderBy, query, collection, updateDoc } from 'firebase/firestore'
import { db } from '../../config/firebase'
import renderDate from '../../config/renderDate'


function Tickets({ user }) {
    const [tickets, setTickets] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [viewData, setViewData] = useState(null)
    const [filterTickets, setFilterTickets] = useState([])
    const [isDisabled, setIsDisabled] = useState(false)
    const [filterStatus, setFilterStatus] = useState("All")
    const [filterPriority, setfilterPriority] = useState("All")
    const { handleSubmit, register, reset, errors } = useForm()
    const toast = useToast()
    const params = useParams()

    useEffect(() => {
        let unsubscribe
        if (params.id) {
            unsubscribe = onSnapshot(doc(db, "Tickets", params.id), async (snapSnot) => {
                let ticket = snapSnot.data()
                let owner = await getDoc(doc(db, "profiles", ticket.createdBy))
                setViewData({ ...ticket, id: snapSnot.id, ownedBy: owner })
            })
        } else {
            if (filterStatus !== "All" && filterPriority !== "All") {
                const queryTickets = query(collection(db, "Tickets"), where("status", "==", filterStatus), where("priority", "==", filterPriority), orderBy("createdAt", "desc"))
                unsubscribe = onSnapshot(queryTickets, (querySnapShot) => {
                    let data = []
                    querySnapShot.forEach((doc) => {
                        let queryData = doc.data()
                        data.push({ id: doc.id, ...queryData })
                    })
                    setFilterTickets(data)
                    setLoading(false)
                })
            } else if (filterStatus !== "All") {
                const queryTickets = query(collection(db, "Tickets"), where("status", "==", filterStatus), orderBy("createdAt", "desc"))
                unsubscribe = onSnapshot(queryTickets, (querySnapShot) => {
                    let data = []
                    querySnapShot.forEach((doc) => {
                        let queryData = doc.data()
                        data.push({ id: doc.id, ...queryData })
                    })
                    setFilterTickets(data)
                    setLoading(false)
                })
            } else if (filterPriority !== "All") {
                const queryTickets = query(collection(db, "Tickets"), where("priority", "==", filterStatus), orderBy("createdAt", "desc"))
                unsubscribe = onSnapshot(queryTickets, (querySnapShot) => {
                    let data = []
                    querySnapShot.forEach((doc) => {
                        let queryData = doc.data()
                        data.push({ id: doc.id, ...queryData })
                    })
                    setFilterTickets(data)
                    setLoading(false)
                })
            } else {
                const queryTickets = query(collection(db, "Tickets"), orderBy("createdAt", "desc"))
                unsubscribe = onSnapshot(queryTickets, (querySnapShot) => {
                    let data = []
                    querySnapShot.forEach((doc) => {
                        let queryData = doc.data()
                        data.push({ id: doc.id, ...queryData })
                    })
                    setTickets(data)
                    setFilterTickets(data)
                    setLoading(false)
                })
            }
        }
        return () => {
            unsubscribe()
            console.log('unmount')
            setViewData(null)
        }
    }, [params, filterPriority, filterStatus])

    function submitComment(value) {
        setIsDisabled(true)
        reset()
        let ticket = viewData
        let comments = ticket.comments || []
        updateDoc(doc(db, "Tickets", params.id), {
            comments: [...comments, {
                message: value.comment,
                createdAt: new Date(),
                isAdmin: true,
                notification: true,
                createdBy: `${user.fullName}(Support)`
            }]
        }).then(() => {
            setIsDisabled(false)

        }).catch((e) => {
            setIsDisabled(false)

            console.log(e)
        })
    }

    function changeStatus(ticketId, status) {
        updateDoc(doc(db, "Tickets", ticketId), { status: status, notification: false }).then(() => {

        }).catch((e) => {
            toast({
                title: "Change status error",
                description: e.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        })
    }

    function ticketStatus(status) {
        if (status === "Open") {
            return "green"
        } else if (status === "On process") {
            return "blue"
        } else if (status === "Closed") {
            return "red"
        }
    }

    return (
        <Container maxW="100%" mt="5">
            <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="gray.500" />}
                bg="gray.100" p="1.5"
                borderRadius="0.3em"
            >
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/dashboard">Dashbaord</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/tickets">Tickets</BreadcrumbLink>
                </BreadcrumbItem>
                {
                    params.id && viewData ?
                        <BreadcrumbItem>
                            <BreadcrumbLink>{viewData.subject}</BreadcrumbLink>
                        </BreadcrumbItem>
                        :
                        params.id &&
                        <BreadcrumbItem>
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xs"
                            />
                        </BreadcrumbItem>
                }
            </Breadcrumb>
            {
                params.id && viewData ?
                    <>
                        <Box mt="30" mb="100">
                            <Box textAlign="right" mt="5">
                                <ButtonGroup size="sm" isAttached>
                                    <Button colorScheme="green" variant={viewData.status === "Open" ? 'solid' : 'outline'} mr="-px" onClick={() => changeStatus(viewData.id, "Open")}>Open</Button>
                                    <Button colorScheme="blue" variant={viewData.status === "On process" ? 'solid' : 'outline'} mr="-px" onClick={() => changeStatus(viewData.id, "On process")}>On process</Button>
                                    <Button colorScheme="red" variant={viewData.status === "Closed" ? 'solid' : 'outline'} mr="-px" onClick={() => changeStatus(viewData.id, "Closed")}>Closed</Button>
                                </ButtonGroup>
                            </Box>
                            <Heading mt="5" as="h1" size="xl" textAlign="left">{viewData.subject} {viewData.attechment && <Icon as={BiPaperclip} fontSize="xx-large" />}</Heading>
                            <Stack mt="5" direction={["column", "row"]}>
                                <Tag size="md" colorScheme={ticketStatus(viewData.status)}>{viewData.status}</Tag> &nbsp; <Tag size="md">{viewData.priority}</Tag>&nbsp;
                                <Center alignContent="right">
                                    <Text >{viewData.ownedBy.fullName}</Text>&nbsp;&nbsp;<Text fontSize="xs">opened this issue at {renderDate(viewData.createdAt)} </Text>
                                </Center>
                            </Stack>
                            <Box
                                mt="5"
                                borderWidth="1px"
                                borderRadius="lg"
                                minHeight="150"
                                p="15"
                            >
                                {viewData.detail}
                                {viewData.attechment && <Img mt="10" src={viewData.attechment} alt={viewData.subject} />}
                            </Box>
                            {viewData.comments && viewData.comments.map((comment, index) => (
                                <Box
                                    key={index}
                                    mt="5"
                                    borderWidth="1px"
                                    borderRadius="lg"
                                    minHeight="150"
                                    p="15"
                                    bg={comment.isAdmin ? "green.50" : "blue.50"}
                                >
                                    <Text mb="4" fontSize="xs" textAlign="right">{renderDate(comment.createdAt)} By {comment.createdBy} &nbsp;<Icon as={comment.isAdmin ? FaUserShield : FaUser} fontSize={comment.isAdmin ? "lg" : "sm"} /></Text>
                                    {comment.message}

                                </Box>
                            ))}
                            <Box
                                mt="5"
                                borderWidth="1px"
                                borderRadius="lg"
                                minHeight="150"
                                p="15"
                                bg="gray.100"
                            >
                                <form onSubmit={handleSubmit(submitComment)}>
                                    <FormControl mt="3" id="comment" isInvalid={errors.comment}>
                                        <Textarea bg="white" placeholder="Leave a comment" rows="6" name="comment" type="text" ref={register({ required: 'Comment is required.' })} />
                                        <FormErrorMessage>{errors.comment && errors.comment.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl textAlign="right">
                                        <Button colorScheme="blue" type="submit" mt="3" isLoading={isDisabled} disabled={isDisabled}>Comment</Button>
                                    </FormControl>
                                </form>
                            </Box>
                        </Box>
                    </>
                    :
                    <>
                        <HStack spacing="5" mt="5">
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<SearchIcon color="gray.300" />}
                                />
                                <Input onChange={(e) => {
                                    let regEx = new RegExp(`${e.target.value.toLowerCase()}`, 'g')
                                    let filteredList = tickets.filter((ticket) => {
                                        let subject = ticket.subject.toLowerCase()
                                        return subject.match(regEx)
                                    })
                                    setFilterTickets(filteredList)
                                }} type="tel" placeholder="Search e.g. Ticket Ref, Issue" />
                            </InputGroup>
                            <Select w="200px" onChange={(e) => setfilterPriority(e.target.value)}>
                                <option value="All">All</option>
                                <option value="Urgent">Urgent</option>
                                <option value="High">High</option>
                                <option value="Medium">Medium</option>
                            </Select>
                            <Select w="200px" onChange={(e) => setFilterStatus(e.target.value)}>
                                <option value="All">All</option>
                                <option value="Open">Open</option>
                                <option value="On process">On process</option>
                                <option value="Closed">Closed</option>
                            </Select>
                        </HStack>
                        <Box overflow="scroll">
                            <Table mt="5">
                                <Thead>
                                    <Tr>
                                        <Th width="20%">Date</Th>
                                        <Th>Subject</Th>
                                        <Th width="10%">Category</Th>
                                        <Th textAlign="center" width="10%">Status</Th>
                                        <Th textAlign="center" width="10%">Priority</Th>
                                        <Th width="10%" textAlign="center">View</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        isLoading ?
                                            <Tr>
                                                <Td colSpan="6" textAlign="center">
                                                    <Spinner
                                                        thickness="4px"
                                                        speed="0.65s"
                                                        emptyColor="gray.200"
                                                        color="blue.500"
                                                        size=
                                                        "xl"
                                                    />
                                                </Td>
                                            </Tr>
                                            :
                                            filterTickets.map((ticket, index) => (
                                                <Tr key={index}>
                                                    <Td>{renderDate(ticket.createdAt)}</Td>
                                                    <Td>{ticket.subject}</Td>
                                                    <Td>{ticket.category}</Td>
                                                    <Td textAlign="center">{ticket.status}</Td>
                                                    <Td textAlign="center">{ticket.priority}</Td>
                                                    <Td textAlign="center"><Button as={Link} to={`tickets/${ticket.id}`} size="sm">View</Button></Td>
                                                </Tr>
                                            ))
                                    }
                                </Tbody>
                            </Table>
                        </Box>
                    </>
            }

        </Container>
    )
}

export default Tickets