import {
  Box,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Textarea,
  Grid,
  GridItem,
  VStack,
  CheckboxGroup,
  Checkbox,
  Center,
} from '@chakra-ui/react'
import React from 'react'
import moment from 'moment'
import { CardContent } from '../../../../../../Components/Card/card'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { SwitchInputComponent } from '../../../switchInput'
const _ = require('underscore')

function SignatureAuthorization({ formView }) {
  const {
    // handleSubmit,
    control,
    // formState: { errors },
    // trigger,
    // watch,
    setValue,
    clearErrors,
  } = useForm()
  useEffect(() => {
    if (formView) {
      const data = formView
      _.keys(data).map(e => {
        return setValue(e, data[e])
      })
    }
  }, [formView, setValue])
  return (
    <CardContent>
      <Box>
        <Text>
          This authorization must be filled-out, dated and signed by the patient
          or by a person authorized by law to give authorization.
        </Text>
        <Box pt="20px">
          <Controller
            name="authorize"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <FormLabel>I authorize</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  readOnly
                  bg="#F9F9F9"
                  border="1px solid black"
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </Box>
        <Text pt="10px" pl="5px">
          to release a copy of the medical information for
        </Text>
        <HStack
          pt="10px"
          flexDirection={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
          spacing={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
        >
          <Controller
            name="namePatient"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <FormLabel>Name of Patient</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  readOnly
                  bg="#F9F9F9"
                  border="1px solid black"
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
          <Controller
            name="authorizationDOB"
            control={control}
            defaultValue={moment(new Date()).format('yyyy-MM-DD')}
            render={props => (
              <FormControl pt={{ base: '10px', sm: '10px', md: '0px' }}>
                <FormLabel>DOB (mm/dd/yy)</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  readOnly
                  bg="#F9F9F9"
                  border="1px solid black"
                  type="date"
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </HStack>
        <HStack
          w="100%"
          pt={{ base: '10px', md: '10px' }}
          flexDirection={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
          spacing={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
        >
          <Controller
            name="nameAuthorization"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <FormLabel>to (Name of Recipient)</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  readOnly
                  bg="#F9F9F9"
                  border="1px solid black"
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
          <Controller
            name="addressAuthorization"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl pt={{ base: '10px', sm: '10px', md: '0px' }}>
                <FormLabel>Mailling Address</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  readOnly
                  bg="#F9F9F9"
                  border="1px solid black"
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </HStack>
        <HStack
          pt="10px"
          flexDirection={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
          spacing={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
        >
          <Controller
            name="faxAuthorization"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <FormLabel>or Fax No.</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  readOnly
                  bg="#F9F9F9"
                  border="1px solid black"
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
          <Box w="100%"></Box>
        </HStack>
        <Box pt="10px">
          <Controller
            name="informationAuthorization"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <FormLabel>
                  The information will be used on my behalf for the following
                  purpose(s)
                </FormLabel>
                <Textarea
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  readOnly
                  bg="#F9F9F9"
                  border="1px solid black"
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </Box>
        <Text pt="20px">
          By initialing the spaces below, I specifically authorize the release
          of the following records, if such records exist:
        </Text>
        <Flex pt="20px">
          <Controller
            name="racordsAuthorization"
            control={control}
            render={props => (
              <FormControl>
                <CheckboxGroup
                  onChange={e => {
                    setValue('racordsAuthorization', e)
                    clearErrors('racordsAuthorization')
                  }}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(1,1fr)',
                      md: 'repeat(1,1fr)',
                      lg: 'repeat(1, 1fr)',
                    }}
                    gap={4}
                  >
                    <GridItem w="100%">
                      <VStack alignItems="flex-start">
                        <Checkbox isDisabled value="hospitalrecord">
                          All hospital records (including nursing records and
                          progress notes)
                        </Checkbox>

                        <Checkbox isDisabled value="transcribreports">
                          Transcribed hospital reports
                        </Checkbox>

                        <Checkbox isDisabled value="medicalrecord">
                          Medical records needed for continuity of care
                        </Checkbox>

                        <Checkbox isDisabled value="yearhistory">
                          Most recent five year history
                        </Checkbox>

                        <Checkbox isDisabled value="laboratory">
                          Laboratory reports
                        </Checkbox>

                        <Checkbox isDisabled value="pathology">
                          Pathology reports
                        </Checkbox>

                        <Checkbox isDisabled value="diagnostic">
                          Diagnostic imaging reports
                        </Checkbox>

                        <Checkbox isDisabled value="clinician">
                          Clinician office chart notes
                        </Checkbox>

                        <Checkbox isDisabled value="dental">
                          Dental records
                        </Checkbox>

                        <Checkbox isDisabled value="therapy">
                          Physical Therapy records
                        </Checkbox>

                        <Checkbox isDisabled value="care">
                          Emergency and urgency care records
                        </Checkbox>

                        <Checkbox isDisabled value="statement">
                          Billing Statements
                        </Checkbox>

                        <HStack>
                          <Checkbox isDisabled value="Other" />
                          <Controller
                            name="other"
                            control={control}
                            defaultValue=""
                            render={props => (
                              <FormControl>
                                <Input
                                  _readOnly={{
                                    border: '1px solid #ccc',
                                  }}
                                  variant="no-effects"
                                  readOnly
                                  bg="#F9F9F9"
                                  border="1px solid black"
                                  value={props.value}
                                  onChange={props.onChange}
                                  onBlur={props.onBlur}
                                />
                              </FormControl>
                            )}
                          />
                        </HStack>
                      </VStack>
                    </GridItem>
                  </Grid>
                </CheckboxGroup>
              </FormControl>
            )}
          />
        </Flex>
        <Text pt="20px">
          Please send the entire medical record (all information) to the above
          named recipient. The recipient understands this record may be
          voluminous and agrees to pay all reasonable charges associated with
          providing this record.
        </Text>
        <Flex pt="20px">
          <Controller
            name="---------------------------------------"
            control={control}
            render={props => (
              <CheckboxGroup
                onChange={props.onChange}
                value={props.value}
                w="100%"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(1, 1fr)',
                  }}
                  gap={4}
                >
                  <GridItem w="100%">
                    <VStack alignItems="flex-start">
                      <Checkbox isDisabled value="hiv">
                        *HIV/AIDS-related records
                      </Checkbox>

                      <Checkbox isDisabled value="genetic">
                        *Genetic testing information
                      </Checkbox>
                    </VStack>
                  </GridItem>
                </Grid>
              </CheckboxGroup>
            )}
          />
        </Flex>
        <Text pt="10px">
          *Must be specifically selected to be included in other documents.
        </Text>
        <Flex pt="10px">
          <Controller
            name="-------------------"
            control={control}
            render={props => (
              <CheckboxGroup
                onChange={props.onChange}
                value={props.value}
                w="100%"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(1, 1fr)',
                  }}
                  gap={4}
                >
                  <GridItem w="100%">
                    <VStack alignItems="flex-start">
                      <Checkbox isDisabled value="drug">
                        **Drug/Alcohol diagnosis, treatment or referral
                        information
                      </Checkbox>

                      <HStack pl="1.5rem" w="100%">
                        <Controller
                          name="otherDrug"
                          control={control}
                          defaultValue=""
                          render={props => (
                            <FormControl>
                              <Input
                                _readOnly={{
                                  border: '1px solid #ccc',
                                }}
                                variant="no-effects"
                                readOnly
                                bg="#F9F9F9"
                                w={{
                                  base: '100%',
                                  sm: '100%',
                                  md: 'max-content',
                                }}
                                border="1px solid black"
                                placeholder="Other"
                                value={props.value}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                              />
                            </FormControl>
                          )}
                        />
                      </HStack>
                    </VStack>
                  </GridItem>
                </Grid>
              </CheckboxGroup>
            )}
          />
        </Flex>
        <Text pt="10px">
          **Federal Regulation 42 CFR Part 2, requires a description of how much
          and what kind of information is to be disclosed.
        </Text>
        <Flex pt="10px">
          <Controller
            name="federal"
            control={control}
            render={props => (
              <CheckboxGroup
                onChange={props.onChange}
                value={props.value}
                isDisabled
                w="100%"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(1, 1fr)',
                  }}
                  gap={4}
                >
                  <GridItem w="100%">
                    <VStack alignItems="flex-start">
                      <Checkbox isDisabled value="treatment">
                        This authorization is limited to the following treatment
                      </Checkbox>

                      <HStack pl="1.5rem">
                        <Controller
                          name="otherFederal"
                          control={control}
                          defaultValue=""
                          render={props => (
                            <FormControl>
                              <Input
                                _readOnly={{
                                  border: '1px solid #ccc',
                                }}
                                variant="no-effects"
                                readOnly
                                bg="#F9F9F9"
                                border="1px solid black"
                                placeholder="other"
                                value={props.value}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                              />
                            </FormControl>
                          )}
                        />
                      </HStack>
                    </VStack>
                  </GridItem>
                </Grid>
              </CheckboxGroup>
            )}
          />
        </Flex>
        <Flex pt="10px">
          <Controller
            name="period"
            control={control}
            render={props => (
              <CheckboxGroup
                onChange={props.onChange}
                value={props.value}
                w="100%"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(1, 1fr)',
                  }}
                  gap={4}
                >
                  <GridItem w="100%">
                    <VStack alignItems="flex-start">
                      <Checkbox isDisabled value="limited">
                        This authorization is limited to the time period
                      </Checkbox>

                      <HStack pl="1.5rem">
                        <Controller
                          name="otherperiod"
                          control={control}
                          defaultValue=""
                          render={props => (
                            <FormControl>
                              <Input
                                _readOnly={{
                                  border: '1px solid #ccc',
                                }}
                                variant="no-effects"
                                readOnly
                                bg="#F9F9F9"
                                border="1px solid black"
                                placeholder="other"
                                value={props.value}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                              />
                            </FormControl>
                          )}
                        />
                      </HStack>
                    </VStack>
                  </GridItem>
                </Grid>
              </CheckboxGroup>
            )}
          />
        </Flex>
        <Flex pt="10px">
          <Controller
            name="injuries"
            control={control}
            render={props => (
              <CheckboxGroup
                onChange={props.onChange}
                value={props.value}
                w="100%"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(1,1fr)',
                    md: 'repeat(1,1fr)',
                    lg: 'repeat(1, 1fr)',
                  }}
                  gap={4}
                >
                  <GridItem w="100%">
                    <VStack alignItems="flex-start">
                      <Checkbox isDisabled value="worker">
                        This authorization is limited to a worker's compensation
                        claim for injuries of
                      </Checkbox>

                      <HStack pl="1.5rem">
                        <Controller
                          name="otherinjuries"
                          control={control}
                          defaultValue=""
                          render={props => (
                            <FormControl>
                              <Input
                                _readOnly={{
                                  border: '1px solid #ccc',
                                }}
                                variant="no-effects"
                                readOnly
                                bg="#F9F9F9"
                                border="1px solid black"
                                placeholder="other"
                                value={props.value}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                              />
                            </FormControl>
                          )}
                        />
                      </HStack>
                    </VStack>
                  </GridItem>
                </Grid>
              </CheckboxGroup>
            )}
          />
        </Flex>
        <Text pt="20px">
          This authorization may be revoked at any time. The only exception is
          when action has been taken in reliance on the authorization, unless
          revoked earlier, this consent will expire 180 days from the date of
          signing or shall remain in effect for the period reasonably needed to
          complete the request.
        </Text>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          alignItems="end"
          gap={0}
          h="max-content"
        >
          <Controller
            name="signaturePatient"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <GridItem
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Signature of patient
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="0px">
                          <SwitchInputComponent
                            formView={formView.signaturePatient || ''}
                          />
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>
                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                </GridItem>
              </FormControl>
            )}
          />
          <GridItem w="100%">
            <VStack spacing="0px">
              <Center h={{ base: '80px' }}>
                <Text fontWeight="bold" fontSize="15">
                  Date
                </Text>
              </Center>

              <Center h="40px">
                <Text>
                  {new Date().toLocaleDateString('en-US', { hour12: false })}
                </Text>
              </Center>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>
        </Grid>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          alignItems="end"
          gap={0}
          h="max-content"
        >
          <Controller
            name="signaturePatientLaw"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <GridItem
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Signature of person authorized by law
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="0px">
                          <SwitchInputComponent
                            formView={formView.signaturePatientLaw || ''}
                          />
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>

                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                </GridItem>
              </FormControl>
            )}
          />
          <GridItem w="100%">
            <VStack spacing="0px">
              <Center h={{ base: '80px' }}>
                <Text fontWeight="bold" fontSize="15">
                  Date
                </Text>
              </Center>

              <Center h="40px">
                <Text>
                  {new Date().toLocaleDateString('en-US', { hour12: false })}
                </Text>
              </Center>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>
        </Grid>
      </Box>
      <Center pt="2rem">{`15/16`}</Center>
    </CardContent>
  )
}
export { SignatureAuthorization }
