import { Image, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

function RecruitPatientsSecondForm({ fillableData, styles }) {
  return (
    <View style={{ ...styles.gapBox }}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 8,
        }}
      >
        <Image
          style={{ width: '110px', objectFit: 'cover' }}
          src="/paincare-web-v2.png"
        />
        <View style={styles.boxtitle}>
          <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
        </View>
        <View style={styles.boxtitle}>
          <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
        </View>
      </View>
      <View
        style={{
          width: '450px',
          paddingTop: '8px',
          gap: '4px',
        }}
      >
        <View style={{ width: '100%' }}>
          <View style={styles.boxRowData}>
            <View
              style={{
                ...styles.boxRowData,
                width: '100%',
                gap: '0px',
              }}
            >
              <Text style={{ width: '42px' }}>Name :</Text>
              <Text style={{ ...styles.paddingLeftFormData }}>
                {fillableData.namePatient ? fillableData.namePatient : ''}
              </Text>
            </View>
            <View
              style={{
                ...styles.boxRowData,
                width: '100%',
                gap: '0px',
              }}
            >
              <Text style={{ width: '35px' }}>DOB :</Text>
              <Text style={{ ...styles.paddingLeftFormData }}>
                {fillableData?.dobPatient
                  ? moment(fillableData?.dobPatient).format('MM/DD/YYYY')
                  : ''}
              </Text>
            </View>
          </View>
        </View>
        <Text style={{ fontWeight: 'bold' }}>
          New Patient Intake Form, Page 2 of 3
        </Text>
        <View
          style={{
            backgroundColor: '#b0acac',
            width: '100%',
            height: '4px',
          }}
        ></View>
        <View
          style={{ paddingTop: 4, display: 'flex', flexDirection: 'column' }}
        >
          <Text style={{ fontWeight: 'bold' }}>Current Medications:</Text>
        </View>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            paddingTop: 2,
            paddingBottom: 1,
          }}
        >
          {fillableData?.medications?.map((data, index) => {
            return (
              <View style={styles.boxRowData} key={index}>
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                  }}
                >
                  <Text style={{ width: '42px' }}>Name:</Text>
                  <Text
                    style={{ ...styles.paddingLeftFormData, paddingLeft: 0 }}
                  >
                    {data.name ? data.name : ''}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                  }}
                >
                  <Text style={{ width: '35px' }}>Dose:</Text>
                  <Text
                    style={{ ...styles.paddingLeftFormData, paddingLeft: 0 }}
                  >
                    {data.dose ? data.dose : ''}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                  }}
                >
                  <Text style={{ width: '80px' }}>Frequency:</Text>
                  <Text
                    style={{
                      ...styles.paddingLeftFormData,
                      paddingLeft: 0,
                    }}
                  >
                    {data.frequency ? data.frequency : ''}
                  </Text>
                </View>
              </View>
            )
          })}
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View>
          <Text style={{ fontWeight: 'bold' }}>Current Medical Problems :</Text>
          <Text style={{ borderBottom: '1px dotted #000', paddingTop: 4 }}>
            {fillableData.currentMedicalProblems
              ? fillableData.currentMedicalProblems
              : ''}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>
            <Text style={{ fontWeight: 'bold' }}>Allergies:</Text>
            {` `}
            <Text>(circle all that apply)</Text>
          </Text>
          <View
            style={{
              paddingTop: 6,
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '6px',
            }}
          >
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsIntake.includes('noneIntake') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>None</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsIntake.includes('tapeIntake') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Tape</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsIntake.includes('iodineIntake') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Iodine</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsIntake.includes('latexIntake') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Latex</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsIntake.includes('contrastIntake') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Omnipaque</Text>
            </View>

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.medicationsIntake.includes('otherIntake') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text style={{ marginTop: '2px' }}>Other</Text>
              {` `}
              <Text
                style={{
                  borderBottom: '1px dotted #000',
                  width: '120px',
                }}
              >
                {fillableData.inputOtherIntake
                  ? fillableData.inputOtherIntake
                  : ''}
              </Text>
            </View>
          </View>
          <View>
            <Text style={{ fontWeight: 'bold', paddingTop: 4 }}>
              Medication Allergies (list) :
            </Text>
            <Text style={{ borderBottom: '1px dotted #000', paddingTop: 4 }}>
              {fillableData.medicationAllergies
                ? fillableData.medicationAllergies
                : ''}
            </Text>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>
            <Text style={{ fontWeight: 'bold' }}>Surgical History</Text>
            {` `}
            <Text>(Check all that apply and circle for Left/Right)</Text>
          </Text>
          <View
            style={{
              paddingTop: 6,
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '6px',
            }}
          >
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.surgicalHistory.includes('cardiacSurgical') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Cardiac/Heart</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.surgicalHistory.includes('cervicalSurgical') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Cervical/Neck</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.surgicalHistory.includes('lumbarSurgical') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Lumbar/Back</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.surgicalHistory.includes('hipSurgical') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Hip Left/Right</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.surgicalHistory.includes('kneeSurgical') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Knee Left/Right</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.surgicalHistory.includes('shoulderSurgical') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>Shoulder Left/Right</Text>
            </View>
            <View
              style={{
                ...styles.trueFalseChoicewidth,
                gap: 4,
                marginTop: '-3px',
              }}
            >
              <View style={styles.stylesCheckImage}>
                {fillableData?.surgicalHistory.includes(
                  'noSurgicalHistory'
                ) && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
              <Text>No Surgical History</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text style={{ fontWeight: 'bold' }}>Past Hospitalizations:</Text>
        </View>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '4px',
            paddingTop: 2,
            paddingBottom: 1,
          }}
        >
          {fillableData?.hospitalizations?.map((data, index) => {
            return (
              <View
                style={{
                  ...styles.boxRowData,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
                key={index}
              >
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                  }}
                >
                  <Text style={{ width: '35px' }}>Date :</Text>
                  <Text style={{ ...styles.paddingLeftFormData }}>
                    {data.date ? data.date : ''}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.boxRowData,
                    width: '100%',
                    gap: '0px',
                  }}
                >
                  <Text style={{ width: '50px' }}>Reason :</Text>
                  <Text style={{ ...styles.paddingLeftFormData }}>
                    {data.reason ? data.reason : ''}
                  </Text>
                </View>
              </View>
            )
          })}
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>
            <Text style={{ fontWeight: 'bold' }}>Family History:</Text>
            {` `}
            <Text>(Please check where applicable)</Text>
          </Text>
        </View>
        <View style={{ gap: '6px' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={{ width: '120px', textAlign: 'start' }}></Text>
            <Text style={{ width: '60px', textAlign: 'center' }}>Father</Text>
            <Text style={{ width: '60px', textAlign: 'center' }}>Mother</Text>
            <Text
              style={{
                width: '60px',
                textAlign: 'center',
              }}
            >
              Grandparent
            </Text>
            <Text style={{ width: '60px', textAlign: 'center' }}>Sibling</Text>
            <Text style={{ width: '60px', textAlign: 'center' }}>None</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={{ width: '120px', textAlign: 'start' }}>
              Heart Disease
            </Text>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.heartDisease.includes('father') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.heartDisease.includes('mother') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.heartDisease.includes('grandparent') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.heartDisease.includes('sibling') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.heartDisease?.includes('none') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={{ width: '120px', textAlign: 'start' }}>
              Lung Disease
            </Text>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.lungDisease.includes('father') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.lungDisease.includes('mother') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.lungDisease.includes('grandparent') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.lungDisease.includes('sibling') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.lungDisease?.includes('none') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={{ width: '120px', textAlign: 'start' }}>
              Arthritis
            </Text>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.arthritis.includes('father') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.arthritis.includes('mother') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.arthritis.includes('grandparent') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.arthritis.includes('sibling') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.arthritis?.includes('none') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={{ width: '120px', textAlign: 'start' }}>Cancer</Text>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.cancer.includes('father') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.cancer.includes('mother') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.cancer.includes('grandparent') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.cancer.includes('sibling') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.cancer?.includes('none') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={{ width: '120px', textAlign: 'start' }}>Stroke</Text>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.stroke.includes('father') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.stroke.includes('mother') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.stroke.includes('grandparent') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.stroke.includes('sibling') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                width: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View style={{ ...styles.stylesCheckImage }}>
                {fillableData?.stroke?.includes('none') && (
                  <Image
                    style={{
                      ...styles.imgcheck,
                    }}
                    src="/check.png"
                  />
                )}
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '1px',
          }}
        ></View>
        <Text style={{ fontWeight: 'bold' }}>
          Social History: (Please check Yes or No for each question)
        </Text>

        <View style={{ ...styles.socialHistory }}>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>Smoking:</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
              <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.smoking === 'yesSmoking' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Yes</Text>
              </View>
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.smoking === 'noSmoking' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>No</Text>
              </View>
            </View>
            <View
              style={{
                width: '40%',
                marginLeft: '10px',
              }}
            >
              {fillableData.otherSmoking && (
                <Text
                  style={{
                    borderBottom: '1px dotted #000',
                  }}
                >
                  {fillableData.otherSmoking}
                </Text>
              )}
            </View>
          </View>
        </View>

        <View style={{ ...styles.socialHistory }}>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>Alcohol:</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
              <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.alcohol === 'yesAlcohol' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Yes</Text>
              </View>
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.alcohol === 'noAlcohol' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>No</Text>
              </View>
            </View>
            <View
              style={{
                width: '40%',
                marginLeft: '10px',
              }}
            >
              {fillableData.otherAlcohol && (
                <Text
                  style={{
                    borderBottom: '1px dotted #000',
                  }}
                >
                  {fillableData.otherAlcohol}
                </Text>
              )}
            </View>
          </View>
        </View>

        <View style={{ ...styles.socialHistory }}>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>Illegal Drugs:</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
              <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.illegalDrugs === 'yesIllegalDrugs' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Yes</Text>
              </View>
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.illegalDrugs === 'noIllegalDrugs' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>No</Text>
              </View>
            </View>
            <View
              style={{
                width: '40%',
                marginLeft: '10px',
              }}
            >
              {fillableData.otherIllegalDrugs && (
                <Text
                  style={{
                    borderBottom: '1px dotted #000',
                  }}
                >
                  {fillableData.otherIllegalDrugs}
                </Text>
              )}
            </View>
          </View>
        </View>

        <View style={{ ...styles.socialHistory }}>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>Addiction/Dependency:</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
              <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.addictionDependency ===
                    'yesAddictionDependency' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Yes</Text>
              </View>
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.addictionDependency ===
                    'noAddictionDependency' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>No</Text>
              </View>
            </View>
            <View
              style={{
                width: '40%',
                marginLeft: '10px',
              }}
            />
          </View>
        </View>

        <View style={{ ...styles.socialHistory }}>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>Marijuana:</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
              <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.addictionMarijuana === 'yesMarijuana' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Yes</Text>
              </View>
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.addictionMarijuana === 'noMarijuana' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>No</Text>
              </View>
            </View>
            <View
              style={{
                width: '40%',
                marginLeft: '10px',
              }}
            />
          </View>
        </View>

        <View style={{ ...styles.socialHistory }}>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>Married:</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
              <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.married === 'yesMarried' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Yes</Text>
              </View>
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.married === 'noMarried' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>No</Text>
              </View>
            </View>
            <View
              style={{
                width: '40%',
                marginLeft: '10px',
              }}
            >
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.married === 'divorcedMarried' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Divorced</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ ...styles.socialHistory }}>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>Children:</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
              <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.children === 'yesChildren' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Yes</Text>
              </View>
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.children === 'noChildren' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>No</Text>
              </View>
            </View>
            <View
              style={{
                width: '40%',
                marginLeft: '10px',
              }}
            >
              {fillableData.otherChildren && (
                <Text
                  style={{
                    borderBottom: '1px dotted #000',
                  }}
                >
                  {fillableData.otherChildren}
                </Text>
              )}
            </View>
          </View>
        </View>

        <View style={{ ...styles.socialHistory }}>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>On Disability:</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
              <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.onDisability === 'yesOnDisability' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Yes</Text>
              </View>
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.onDisability === 'noOnDisability' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>No</Text>
              </View>
            </View>
            <View
              style={{
                width: '40%',
                marginLeft: '10px',
              }}
            />
          </View>
        </View>

        <View style={{ ...styles.socialHistory }}>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>Currently Working:</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
              <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.currentLyWorking === 'yesCurrentLyWorking' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>Yes</Text>
              </View>
              <View style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}>
                <View style={styles.stylesCheckImage}>
                  {fillableData?.currentLyWorking === 'noCurrentLyWorking' && (
                    <Image
                      style={{
                        ...styles.imgcheck,
                      }}
                      src="/check.png"
                    />
                  )}
                </View>
                <Text>No</Text>
              </View>
            </View>
            <View
              style={{
                width: '40%',
                marginLeft: '10px',
              }}
            />
          </View>
        </View>
      </View>
    </View>
  )
}

export default RecruitPatientsSecondForm
