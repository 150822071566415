import { AddIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Flex,
  HStack,
  Select,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  onSnapshot,
  query,
  orderBy,
  collection,
  where,
} from 'firebase/firestore'
import { Link } from 'react-router-dom'
import { BsViewStacked } from 'react-icons/bs'
import React, { useEffect, useState } from 'react'

import { db } from '../../config/firebase'
import renderDate from '../../config/renderDate'

const optionsData = {
  OSSC: [
    { label: 'CRNA-ANS', value: 'CRNAANS' },
    { label: 'MD-ANS', value: 'MDANS' },
    { label: 'MD', value: 'MD' },
    { label: 'Nurses', value: 'Nurses' },
  ],
  PCSO: [{ label: 'CRNA-ANS', value: 'CRNAANS' }],
}

function PeerReview() {
  const [status, setStatus] = useState('All')
  const [facility, setFacility] = useState('All')
  const [form, setForm] = useState('All')
  const [formList, setFormList] = useState([])

  useEffect(() => {
    let querys = []
    if (status !== 'All') {
      querys.push(where('status', '==', status))
    }
    if (facility !== 'All') {
      querys.push(where('facility', '==', facility))
    }
    if (form !== 'All') {
      querys.push(where('type', '==', form))
    }

    const peerReview = query(
      collection(db, 'PeerReviewForm'),
      ...querys,
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(peerReview, snapShot => {
      let data = []
      snapShot.forEach(doc => {
        return data.push({
          ...doc.data(),
          id: doc.id,
        })
      })
      setFormList(data)
    })
    return () => {
      unsubscribe()
    }
  }, [status, facility, form])

  return (
    <Container maxW="100%" mt="5" mb="10">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/peer-review">
            Peer Review
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex mt="5">
        <Box
          pt="2"
          fontSize="18"
          fontWeight="bold"
          display="flex"
          alignItems="center"
        >
          Peer Review
        </Box>
        <Spacer />
        <Flex gap={4}>
          <Box>
            <Text mb="4px">Facility</Text>
            <Select
              name="facility"
              onChange={e => {
                setFacility(e.target.value)
                setForm('All')
              }}
              defaultValue={facility}
            >
              <option>All</option>
              <option>PCSO</option>
              <option>OSSC</option>
            </Select>
          </Box>
          <Box>
            <Text mb="4px">Form</Text>
            <Select
              name="type"
              onChange={e => setForm(e.target.value)}
              defaultValue={status}
              placeholder="All"
            >
              {facility !== 'All' &&
                optionsData[facility].map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
            </Select>
          </Box>
          <Box>
            <Text mb="4px">Status</Text>
            <Select
              name="status"
              onChange={e => setStatus(e.target.value)}
              defaultValue={status}
            >
              <option>All</option>
              <option>Draft</option>
              <option>Success</option>
            </Select>
          </Box>
        </Flex>
      </Flex>
      <HStack py="1.75em" justifyContent="end">
        <Button
          as={Link}
          to={'/peer-review/form'}
          size="xs"
          leftIcon={<AddIcon />}
        >
          create
        </Button>
      </HStack>
      <Table size="sm" mt="10">
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Anesthesia</Th>
            <Th>Facility</Th>
            <Th>Form</Th>
            <Th>Status</Th>
            <Th textAlign="center">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {formList.map((form, index) => {
            return (
              <Tr key={index}>
                <Td>{renderDate(form.createdAt)}</Td>
                <Td>{form.anesthesia}</Td>
                <Td>{form.facility}</Td>
                <Td>{form.type}</Td>
                <Td>{form.status}</Td>
                <Td textAlign="center">
                  <Button
                    as={Link}
                    to={`/peer-review/${form.id}`}
                    size="xs"
                    leftIcon={<BsViewStacked />}
                  >
                    View
                  </Button>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Container>
  )
}

export default PeerReview
